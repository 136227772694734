import React from "react";
import ModelViewer from '../components/modelViewer';
import BackgroundColor from '../components/backgroundColor';
import {NextContext} from '../components/Context';
import {motion} from "framer-motion"

const models = {
  zirafa: {
    path: '/zirafa.gltf',
    position: {x: 0, y: -0.1, z: -0},
    zoom: 0.5
  },
  vilkas: {
    path: '/vilkas.gltf',
    position: {x: 0, y: -0.1, z: -0},
    zoom: 0.3
  },
  siksnosparnis: {
    path: '/siks.gltf',
    position: {x: 0, y: -0.1, z: -0},
    zoom: 0.5
  },
  kobra: {
    path: '/kobra.gltf',
    position: {x: 0, y: -0.1, z: -0},
    zoom: 0.5
  },
  kate: {
    path: '/kate texture.gltf',
    position: {x: 0, y: -0.1, z: -0},
    zoom: 0.5
  }
}

export default ({onNext, setHeight, sounds, ...props}) => {
  const [selectedModel, setSelectedModel] = React.useState('zirafa');
  const [showEnd, setShowEnd] = React.useState(false);

  const modelRef = React.useRef(null);
  const modelRef2 = React.useRef(null);
  const [modelSize, setModelSize] = React.useState({
    width: 0,
    height: 0
  });

  const updateModelSize = () => {
    if (modelRef.current) {
      let {width, height, x, y} = modelRef.current.getBoundingClientRect();
      const point = modelRef2.current.createSVGPoint();

      point.x = 284
      point.y = 1234
      const offset = point.matrixTransform(modelRef2.current.getScreenCTM());

      const wRatio = 1920 / modelRef2.current.getBoundingClientRect().width;

      setModelSize({
        width: width * wRatio,
        height: height * wRatio,
        x: x - offset.x,
        y: y - offset.y
      })
    }
  }

  React.useEffect(() => {
    if (modelRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        updateModelSize();
      });

      resizeObserver.observe(modelRef.current);
    }
  }, [modelRef.current, props.style.height]) // eslint-disable-line

  React.useEffect(() => {
    updateModelSize();
  }, [modelRef.current, props.style.height]); // eslint-disable-line

  React.useEffect(() => {
    const resize = () => updateModelSize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  React.useEffect(
    () => {
      if (selectedModel !== 'zirafa' && !showEnd) {
        setShowEnd(true);
      }
    },
    [selectedModel, showEnd]
  )

  const [size, setSize] = React.useState(2160);

  React.useEffect(
    () => {
      if (showEnd) {
        setSize(3243)
      }
    },
    [showEnd]
  )

  const nextContext = React.useContext(NextContext);
  const endRef = React.useRef();
  React.useEffect(
    () => {
      if (endRef.current && showEnd) {
        return nextContext.onRegister(endRef.current);
      }
    },
    [endRef.current, showEnd] // eslint-disable-line
  )

  const [mouseover, setMouseOver] = React.useState(false);
  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);
  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.padarykime_zmogu.on('end', () => {
          setAudioDone(true);
        });
        sounds.padarykime_zmogu.play();
      }
    },
    [audioDone, playAudio, sounds.padarykime_zmogu]
  )

  if (props.style.height !== 'auto') {
    return null;
  }

  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 1920 ${size}`}
        ref={modelRef2}
        {...props}
      >
      <BackgroundColor color="#fcf2e2" />
        <defs>
          {selectedModel === 'vilkas' && (
            <React.Fragment>
              <pattern id="pattern" width="1" height="1" patternTransform="matrix(-1, 0, 0, 1, 872, 0)" viewBox="0 0 436 409">
                <use preserveAspectRatio="none" xlinkHref="#image"/>
              </pattern>
              <image id="image" preserveAspectRatio="none" width="436" height="409" xlinkHref="Web%201920%20-%2039-image.png"/>
              <pattern id="pattern-2" width="1" height="1" patternTransform="matrix(-1, 0, 0, 1, 868, 0)" viewBox="0 0 434 408">
                <use xlinkHref="#image"/>
              </pattern>
              <pattern id="pattern-3" width="1" height="1" patternTransform="matrix(-1, 0, 0, 1, 870, 0)" viewBox="0 0 435 408">
                <use xlinkHref="#image"/>
              </pattern>
              <pattern id="pattern-4" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 512 482">
                <image width="512" height="482" xlinkHref="Web%201920%20-%2039-image.png"/>
              </pattern>
              <pattern id="pattern-7" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1280 720">
                <image width="1280" height="720" xlinkHref="Web%201920%20-%2039-image2.png"/>
              </pattern>
            </React.Fragment>
          )}
          {selectedModel === 'zirafa' && (
            <React.Fragment>
              <pattern
                id="pattern"
                preserveaspectratio="none"
                width="100%"
                height="100%"
                viewBox="0 0 1280 720"
              >
                <img width={1280} height={720} xlinkHref="/Web%201920%20-%2037-image2.png" />
              </pattern>
              <pattern id="pattern2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1280 720">
                <image width="1280" height="720" xlinkHref="Web%201920%20-%2037-image2.png"/>
              </pattern>

              <image id="image2" width="399.93" height="453.591" xlinkHref="Web%201920%20-%2037-image.png"/>

            </React.Fragment>
          )}
          {selectedModel === 'kate' && (
            <React.Fragment>
            <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1007 793">
              <image width="1007" height="793" xlinkHref="Web%201920%20-%2040-image.png"/>
            </pattern>
            <pattern id="pattern-2" width="1" height="1" patternTransform="matrix(-1, 0, 0, 1, 720, 0)" viewBox="0 0 360 283.496">
              <use preserveAspectRatio="none" xlinkHref="#image"/>
            </pattern>
            <image id="image" preserveAspectRatio="none" width="360" height="283.496" xlinkHref="Web%201920%20-%2040-image.png"/>
            <pattern id="pattern-3" width="1" height="1" patternTransform="matrix(1, 0, 0, -1, 0, 566.991)" viewBox="0 0 360 283.496">
              <use xlinkHref="#image"/>
            </pattern>
            <pattern id="pattern-4" width="1" height="1" patternTransform="translate(720 566.991) rotate(-180)" viewBox="0 0 360 283.496">
              <use xlinkHref="#image"/>
            </pattern>
            </React.Fragment>
          )}
          {selectedModel === 'kobra' && (
            <React.Fragment>
              <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 512 287">
                <image width="512" height="287" xlinkHref="Web%201920%20-%2041-image.png"/>
              </pattern>
              <pattern id="pattern-3" width="1" height="1" patternTransform="matrix(1, 0, 0, -1, 0, 413.186)" viewBox="0 0 368.556 206.593">
                <use preserveAspectRatio="none" xlinkHref="#image"/>
              </pattern>
              <image id="image" preserveAspectRatio="none" width="368.556" height="206.593" xlinkHref="Web%201920%20-%2041-image.png"/>
              <pattern id="pattern-4" width="1" height="1" patternTransform="matrix(1, 0, 0, -1, 0, 413.186)" viewBox="0 0 368.556 206.593">
                <use xlinkHref="#image"/>
              </pattern>
            </React.Fragment>
          )}
          {selectedModel === 'siksnosparnis' && (
            <React.Fragment>
            <pattern
              id="pattern"
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              viewBox="0 0 512 341"
            >
              <image
                width={512}
                height={341}
                xlinkHref="Web%201920%20-%2042-image.png"
              />
            </pattern>
            <pattern
              id="pattern-2"
              width={1}
              height={1}
              patternTransform="matrix(1, 0, 0, -1, 0, 514.7)"
              viewBox="0 0 386.402 257.35"
            >
              <use preserveAspectRatio="none" xlinkHref="#image" />
            </pattern>
            <image
              id="image"
              preserveAspectRatio="none"
              width="386.402"
              height="257.35"
              xlinkHref="Web%201920%20-%2042-image.png"
            />
            <pattern
              id="pattern-3"
              width={1}
              height={1}
              patternTransform="matrix(1, 0, 0, -1, 0, 514.699)"
              viewBox="0 0 386.402 257.35"
            >
              <use xlinkHref="#image" />
            </pattern>
            </React.Fragment>
          )}
          <clipPath id="clip-Web_1920_42">
            <rect width={1920} height={size} />
          </clipPath>
        </defs>
        <g
          id="Web_1920_42"
          data-name="Web 1920 – 42"
          clipPath="url(#clip-Web_1920_42)"
        >
          <rect width={1920} height={size} fill="#fcf2e2" />
          <g id="Group_73" data-name="Group 73" transform="translate(-105 817)">
            <g id="Group_53" data-name="Group 53" transform="translate(105 263)">
              <g id="Group_50" data-name="Group 50">
                <g id="Group_42" data-name="Group 42">
                  <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M0,0H1920V1080H0Z"
                    fill="#fcf2e2"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_60" data-name="Group 60" transform="translate(324 -126)">
            <g id="Group_53-2" data-name="Group 53">
              <g id="Group_50-2" data-name="Group 50" transform="translate(105 263)">
                <g id="Group_42-2" data-name="Group 42">
                  <g
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1063} height={783} stroke="none" />
                    <rect x={1} y={1} width={1061} height={781} fill="none" />
                  </g>
                </g>
                <g id="Group_43" data-name="Group 43">
                  <g
                    id="Rectangle_1-2"
                    data-name="Rectangle 1"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1063} height={45} stroke="none" />
                    <rect x={1} y={1} width={1061} height={43} fill="none" />
                  </g>
                </g>
                <g
                  id="Ellipse_3"
                  data-name="Ellipse 3"
                  transform="translate(22 14)"
                  fill="#ffd7e0"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_4"
                  data-name="Ellipse 4"
                  transform="translate(58 14)"
                  fill="#d1fff1"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_5"
                  data-name="Ellipse 5"
                  transform="translate(94 14)"
                  fill="#fcf2e2"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
              </g>
            </g>
            <text
              id="Algoritmas_padarė_visų_rūšių_laukinius_žemės_gyvulius_visų_rūšių_galvijus_ir_visų_rūšių_žemės_roplius."
              data-name="Algoritmas padarė visų rūšių laukinius žemės gyvulius, visų rūšių galvijus ir visų rūšių žemės roplius."
              transform="translate(149.5 362.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
            >
              <tspan x={16} y={102}>
                Algoritmas padarė visų{" "}
              </tspan>
              <tspan x="53.75" y={222}>
                rūšių laukinius žemės{" "}
              </tspan>
              <tspan x="102.65" y={342}>
                gyvulius, visų rūšių{" "}
              </tspan>
              <tspan x="90.95" y={462}>
                galvijus ir visų rūšių{" "}
              </tspan>
              <tspan x="189.45" y={582}>
                žemės roplius.
              </tspan>
            </text>
          </g>
          <g id="Group_60-2" data-name="Group 60" transform="translate(177 887.169)">
            <g
              id="Group_53-3"
              data-name="Group 53"
              transform="translate(105 262.831)"
            >
              <g id="Group_50-3" data-name="Group 50" transform="translate(0 0)">
                <g id="Group_42-3" data-name="Group 42">
                  <g
                    id="Rectangle_1-3"
                    data-name="Rectangle 1"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1388} height={880} stroke="none" />
                    <rect x={1} y={1} width={1386} height={878} fill="none" />
                  </g>
                </g>
                <g id="Group_43-2" data-name="Group 43">
                  <g
                    id="Rectangle_1-4"
                    data-name="Rectangle 1"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1388} height={45} stroke="none" />
                    <rect x={1} y={1} width={1386} height={43} fill="none" />
                  </g>
                </g>
                <g
                  id="Ellipse_3-2"
                  data-name="Ellipse 3"
                  transform="translate(22 14)"
                  fill="#ffd7e0"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_4-2"
                  data-name="Ellipse 4"
                  transform="translate(58 14)"
                  fill="#d1fff1"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_5-2"
                  data-name="Ellipse 5"
                  transform="translate(94 14)"
                  fill="#fcf2e2"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
              </g>
              <g
                id="Path_36"
                data-name="Path 36"
                transform="translate(278 45)"
                fill={selectedModel === 'vilkas' ? '#dfffd7' : '#fcf2e2'}
                style={{cursor: 'pointer'}}
                onClick={() => setSelectedModel('vilkas')}
              >
                <path d="M 279 36 L 1 36 L 1 1 L 279 1 L 279 36 Z" stroke="none" />
                <path
                  d="M 2 2 L 2 35 L 278 35 L 278 2 L 2 2 M 0 0 L 280 0 L 280 37 L 0 37 L 0 0 Z"
                  stroke="none"
                  fill="#392d2f"
                />
              </g>
              <g
                id="Rectangle_38"
                data-name="Rectangle 38"
                transform="translate(555 45)"
                fill={selectedModel === 'siksnosparnis' ? '#dfffd7' : '#fcf2e2'}
                style={{cursor: 'pointer'}}
                onClick={() => setSelectedModel('siksnosparnis')}
                stroke="#392d2f"
                strokeWidth={2}
              >
                <rect width={282} height={37} stroke="none" />
                <rect x={1} y={1} width={280} height={35} fill="none" />
              </g>
              <g
                id="Path_38"
                data-name="Path 38"
                transform="translate(833 45)"
                fill={selectedModel === 'kobra' ? '#dfffd7' : '#fcf2e2'}
                style={{cursor: 'pointer'}}
                onClick={() => setSelectedModel('kobra')}
              >
                <path d="M 280 36 L 1 36 L 1 1 L 280 1 L 280 36 Z" stroke="none" />
                <path
                  d="M 2 2 L 2 35 L 279 35 L 279 2 L 2 2 M 0 0 L 281 0 L 281 37 L 0 37 L 0 0 Z"
                  stroke="none"
                  fill="#392d2f"
                />
              </g>
              <g
                id="Path_37"
                data-name="Path 37"
                transform="translate(1110 45)"
                fill={selectedModel === 'kate' ? '#dfffd7' : '#fcf2e2'}
                style={{cursor: 'pointer'}}
                onClick={() => setSelectedModel('kate')}
              >
                <path d="M 277 36 L 1 36 L 1 1 L 277 1 L 277 36 Z" stroke="none" />
                <path
                  d="M 2 2 L 2 35 L 276 35 L 276 2 L 2 2 M 0 0 L 278 0 L 278 37 L 0 37 L 0 0 Z"
                  stroke="none"
                  fill="#392d2f"
                />
              </g>
            </g>
          </g>
          <g
            id="Component_71_4"
            data-name="Component 71 – 4"
            transform="translate(282 1195)"
          >
            <g
              id="Rectangle_36"
              data-name="Rectangle 36"
              fill={selectedModel === 'zirafa' ? '#dfffd7' : '#fcf2e2'}
              stroke="#392d2f"
              strokeWidth={2}
              style={{cursor: 'pointer'}}
              onClick={() => setSelectedModel('zirafa')}
            >
              <rect width={281} height={37} stroke="none" />
              <rect x={1} y={1} width={279} height={35} fill="none" />
            </g>
            <text
              id="Žirafa"
              transform="translate(142 25)"
              fill="#392d2f"
              fontSize={20}
              fontFamily="Helvetica"
              style={{cursor: 'pointer'}}
              onClick={() => setSelectedModel('zirafa')}
            >
              <tspan x="-25.562" y={0}>
                Žirafa
              </tspan>
            </text>
          </g>
          <text
            id="Vilkas"
            transform="translate(700 1220)"
            fill="#392d2f"
            fontSize={20}
            fontFamily="Helvetica"
            style={{cursor: 'pointer'}}
            onClick={() => setSelectedModel('vilkas')}
          >
            <tspan x="-26.499" y={0}>
              Vilkas
            </tspan>
          </text>
          <text
            id="Šikšnosparnis"
            transform="translate(976 1220)"
            fill="#392d2f"
            fontSize={20}
            fontFamily="Helvetica"
            style={{cursor: 'pointer'}}
            onClick={() => setSelectedModel('siksnosparnis')}
          >
            <tspan x="-62.251" y={0}>
              Šikšnosparnis
            </tspan>
          </text>
          <text
            id="Kobra"
            transform="translate(1265 1220)"
            fill="#392d2f"
            fontSize={20}
            fontFamily="Helvetica"
            style={{cursor: 'pointer'}}
            onClick={() => setSelectedModel('kobra')}
          >
            <tspan x="-26.685" y={0}>
              Kobra
            </tspan>
          </text>
          <text
            id="Katė"
            transform="translate(1522 1220)"
            fill="#392d2f"
            fontSize={20}
            fontFamily="Helvetica"
            style={{cursor: 'pointer'}}
            onClick={() => setSelectedModel('kate')}
          >
            <tspan x="-20.571" y={0}>
              Katė
            </tspan>
          </text>
          {selectedModel === 'siksnosparnis' && (
            <g
              id="Group_108"
              data-name="Group 108"
              transform="translate(23.614 39.595)"
            >
              <rect
                id="IMG_2168"
                width={385}
                height={257}
                transform="translate(743.386 1625.405)"
                opacity="0.75"
                fill="url(#pattern)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <use
                id="IMG_2168-2"
                data-name="IMG_2168"
                transform="translate(680.163 1418.661) rotate(25)"
                opacity="0.75"
                xlinkHref="#image"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2168-3"
                data-name="IMG_2168"
                width="386.402"
                height="257.35"
                transform="translate(1300.43 1651.9) rotate(155)"
                opacity="0.75"
                fill="url(#pattern-2)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2168-4"
                data-name="IMG_2168"
                width="386.402"
                height="257.35"
                transform="translate(743.843 1277.776)"
                opacity="0.75"
                fill="url(#pattern-3)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2168-5"
                data-name="IMG_2168"
                width="386.402"
                height="257.35"
                transform="translate(572.341 1508.729) rotate(-25)"
                opacity="0.75"
                fill="url(#pattern-3)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <use
                id="IMG_2168-6"
                data-name="IMG_2168"
                transform="translate(1192.608 1741.967) rotate(-155)"
                opacity="0.75"
                xlinkHref="#image"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
            </g>
          )}
          {selectedModel === 'zirafa' && (
            <g
              id="Component_68_3"
              data-name="Component 68 – 3"
              transform="translate(354.55 1006.262)"
            >
              <g
                id="Group_92"
                data-name="Group 92"
                transform="translate(0 639.6) rotate(-47)"
              >
                <g
                  id="Group_91"
                  data-name="Group 91"
                  transform="translate(794.962 258.915) rotate(90)"
                >
                  <use
                    id="IMG_2161"
                    transform="translate(27.812 28.478)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <use
                    id="IMG_2161-2"
                    data-name="IMG_2161"
                    transform="translate(320.737 0) rotate(45)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <use
                    id="IMG_2161-3"
                    data-name="IMG_2161"
                    transform="translate(496.056 220.971) rotate(90)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                </g>
                <g id="Group_90" data-name="Group 90" transform="translate(131.805 0)">
                  <use
                    id="IMG_2161-4"
                    data-name="IMG_2161"
                    transform="translate(167.02 96.151)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                    <use
                      id="IMG_2161-5"
                      data-name="IMG_2161"
                      transform="translate(459.945 67.673) rotate(45)"
                      opacity="0.739"
                      xlinkHref="#image2"
                      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                    />
                    <use
                      id="IMG_2161-6"
                      data-name="IMG_2161"
                      transform="translate(0 282.793) rotate(-45)"
                      opacity="0.739"
                      xlinkHref="#image2"
                      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                    />
                </g>
                <g
                  id="Group_93"
                  data-name="Group 93"
                  transform="translate(603.53 785.899) rotate(180)"
                >
                  <use
                    id="IMG_2161-7"
                    data-name="IMG_2161"
                    transform="translate(320.737 0) rotate(45)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <use
                    id="IMG_2161-8"
                    data-name="IMG_2161"
                    transform="translate(496.056 220.971) rotate(90)"
                    opacity="0.739"
                    xlinkHref="#image2"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  ></use>
                </g>
              </g>
            </g>
          )}
          {selectedModel === 'vilkas' && (<g
              id="Component_69_1"
              data-name="Component 69 – 1"
              transform="translate(552 1435)"
            >
              <g
                id="Group_89"
                data-name="Group 89"
                transform="translate(-548.077 -383.523)"
              >
                <rect
                  id="IMG_2162"
                  width={436}
                  height={409}
                  transform="translate(928.077 383.523)"
                  opacity="0.75"
                  fill="url(#pattern)"
                  style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                />
                  <rect
                    id="IMG_2162-2"
                    data-name="IMG_2162"
                    width={434}
                    height={408}
                    transform="translate(792.077 383.523)"
                    opacity="0.75"
                    fill="url(#pattern-2)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <rect
                    id="IMG_2162-3"
                    data-name="IMG_2162"
                    width={435}
                    height={408}
                    transform="translate(630.077 383.523)"
                    opacity="0.75"
                    fill="url(#pattern-3)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <rect
                    id="IMG_2162-4"
                    data-name="IMG_2162"
                    width={436}
                    height={409}
                    transform="translate(548.077 383.523)"
                    opacity="0.75"
                    fill="url(#pattern-4)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <rect
                    id="IMG_2162-5"
                    data-name="IMG_2162"
                    width={434}
                    height={408}
                    transform="translate(686.077 383.523)"
                    opacity="0.75"
                    fill="url(#pattern-4)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                  <rect
                    id="IMG_2162-6"
                    data-name="IMG_2162"
                    width={435}
                    height={408}
                    transform="translate(847.077 383.523)"
                    opacity="0.75"
                    fill="url(#pattern-4)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  ></rect>
              </g>
            </g>
          )}
          {selectedModel === 'siksnosparnis' && (null)}
          {selectedModel === 'kobra' && (
            <g id="Group_107" data-name="Group 107" transform="translate(-18 -60.753)">
              <text
                id="Pridėti"
                transform="translate(985 1621.753)"
                fill="#fcf2e2"
                fontSize={40}
                fontFamily="Helvetica"
              >
                <tspan x="-56.689" y={0}>
                  Pridėti
                </tspan>
              </text>
              <path
                id="Polygon_1"
                data-name="Polygon 1"
                d="M18.036,0,36.072,30.606H0Z"
                transform="translate(1100.221 1590.65) rotate(90)"
                fill="#fcf2e2"
              />
                <rect
                  id="kobra_foto"
                  data-name="kobra foto"
                  width={370}
                  height={207}
                  transform="translate(799 1400.753)"
                  opacity="0.75"
                  fill="url(#pattern)"
                  style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                />
                  <rect
                    id="kobra_foto-2"
                    data-name="kobra foto"
                    width={370}
                    height={207}
                    transform="translate(789 1627.753)"
                    opacity="0.75"
                    fill="url(#pattern)"
                    style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                  />
                    <rect
                      id="kobra_foto-3"
                      data-name="kobra foto"
                      width="368.556"
                      height="206.593"
                      transform="translate(789.238 1528.891)"
                      opacity="0.75"
                      fill="url(#pattern-3)"
                      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                    />
                      <rect
                        id="kobra_foto-4"
                        data-name="kobra foto"
                        width="368.556"
                        height="206.593"
                        transform="translate(801.238 1755.16)"
                        opacity="0.75"
                        fill="url(#pattern-4)"
                        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                      />
                        <text
                          id="Pridėti-2"
                          data-name="Pridėti"
                          transform="translate(1033.056 1694.839) rotate(90)"
                          fill="#fcf2e2"
                          fontSize={39}
                          fontFamily="Helvetica"
                        >
                          <tspan x="-55.272" y={0}>
                            Pridėti
                          </tspan>
                        </text>
                        <path
                          id="Polygon_3"
                          data-name="Polygon 3"
                          d="M18,0,36,30H0Z"
                          transform="translate(1064 1811.753) rotate(180)"
                          fill="#fcf2e2"
                        />
                          <use
                            id="kobra_foto-5"
                            data-name="kobra foto"
                            transform="translate(1263.253 1501.017) rotate(90)"
                            opacity="0.75"
                            xlinkHref="#image"
                            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                          />
                            <use
                              id="kobra_foto-6"
                              data-name="kobra foto"
                              transform="translate(1026.984 1501.017) rotate(90)"
                              opacity="0.75"
                              xlinkHref="#image"
                              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                            />
                              <rect
                                id="kobra_foto-7"
                                data-name="kobra foto"
                                width="368.556"
                                height="206.593"
                                transform="translate(1135.362 1501.017) rotate(90)"
                                opacity="0.75"
                                fill="url(#pattern-4)"
                                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                              />
                                <rect
                                  id="kobra_foto-8"
                                  data-name="kobra foto"
                                  width="368.556"
                                  height="206.593"
                                  transform="translate(899.093 1501.017) rotate(90)"
                                  opacity="0.75"
                                  fill="url(#pattern-4)"
                                  style={{ mixBlendMode: "multiply", isolation: "isolate" }}
                                ></rect>
            </g>
          )}
          {selectedModel === 'kate' && (
            <g id="Group_106" data-name="Group 106" transform="translate(0 68)">
            <rect
                id="IMG_2167"
                width={360}
                height={284}
                transform="translate(905 1455)"
                opacity="0.75"
                fill="url(#pattern)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2167-2"
                data-name="IMG_2167"
                width={360}
                height="283.496"
                transform="translate(590 1455)"
                opacity="0.75"
                fill="url(#pattern-2)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2167-3"
                data-name="IMG_2167"
                width={360}
                height="283.496"
                transform="translate(905 1340.505)"
                opacity="0.75"
                fill="url(#pattern-3)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <rect
                id="IMG_2167-4"
                data-name="IMG_2167"
                width={360}
                height="283.496"
                transform="translate(590 1340.505)"
                opacity="0.75"
                fill="url(#pattern-4)"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
            </g>
          )}
          <g transform="translate(284 1234)" ref={modelRef}>
            <foreignObject width="1384" height="798">
              {modelSize.width && (
                <ModelViewer
                  key={selectedModel}
                  model={models[selectedModel]}
                  width={modelSize.width}
                  height={modelSize.height}
                  enableZoom={false}
                  zoom={models[selectedModel].zoom}
                  style={{
                    position: 'fixed',
                    left: modelSize.x,
                    top: modelSize.y
                  }}
                />
              )}
            </foreignObject>
          </g>
          {showEnd && (
            <g>
              <text
                id="Tuomet_Algoritmas_tarė:"
                data-name="Tuomet Algoritmas tarė:"
                transform="translate(247 2499.5)"
                fill="#392d2f"
                fontSize={100}
                fontFamily="Voyage-Regular, Voyage"
                ref={endRef}
              >
                <tspan x="251.05" y={102}>
                  Tuomet Algoritmas tarė:
                </tspan>
              </text>
              <motion.g
                id="button_zmogu"
                data-name="button zmogu"
                transform="translate(357 2703.999)"
                onClick={() => audioDone && onNext()}
                style={{cursor: 'pointer'}}
                onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
                onHoverEnd={() => setMouseOver(false)}>

                <g id="Path_19" data-name="Path 19" fill="#392d2f">
                  {mouseover && (
                    <React.Fragment>
                      <path d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z" stroke="none"/>
                      <path d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z" stroke="none" fill="#392d2f"/>
                    </React.Fragment>
                  )}
                  {!mouseover && (
                    <React.Fragment>
                      <path
                        d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z"
                        stroke="none"
                      />
                      <path
                        d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z"
                        stroke="none"
                        fill="#392d2f"
                      />
                    </React.Fragment>
                  )}
                </g>
                <g
                  id="Rectangle_1-4"
                  data-name="Rectangle 1"
                  transform="translate(0 0)"
                  fill="#fcf2e2"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <rect width={1196} height={251} stroke="none" />
                  <rect x={1} y={1} width={1194} height={249} fill="none" />
                </g>
                <text
                  id="Padarykime_žmogų_pagal_mūsų_paveikslą_ir_panašumą_tevaldo_jie_ir_jūros_žuvis_ir_padangių_sparnuočius_ir_galvijus_ir_visus_laukinius_žemės_gyvulius_ir_visus_žemėje_šliaužiojančius_roplius_"
                  data-name="Padarykime žmogų pagal mūsų paveikslą ir panašumą; tevaldo jie ir jūros žuvis, ir padangių sparnuočius, ir galvijus, ir visus laukinius žemės gyvulius, ir visus žemėje šliaužiojančius roplius!"
                  transform="translate(599 66)"
                  fill="#392d2f"
                  fontSize={37}
                  fontFamily="Helvetica"
                >
                  <tspan x="-458.616" y={0}>
                    Padarykime žmogų pagal mūsų paveikslą ir panašumą;{" "}
                  </tspan>
                  <tspan x="-408.238" y={44}>
                    tevaldo jie ir jūros žuvis, ir padangių sparnuočius,{" "}
                  </tspan>
                  <tspan x="-363.957" y={88}>
                    ir galvijus, ir visus laukinius žemės gyvulius,{" "}
                  </tspan>
                  <tspan x="-311.537" y={132}>
                    ir visus žemėje šliaužiojančius roplius!
                  </tspan>
                </text>
              </motion.g>
            </g>
          )}
        </g>
      </svg>
    </React.Fragment>
  )
}
