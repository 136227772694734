import React from "react";
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, sounds, ...props}) => {
  const [mouseover, setMouseOver] = React.useState(false);
  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);

  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.padarykime_zmogu.on('end', () => {
          setAudioDone(true);
        });
        sounds.padarykime_zmogu.play();
      }
    },
    [audioDone, playAudio, sounds.padarykime_zmogu]
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox="0 0 1920 1083"
    >
      <BackgroundColor color="#fcf2e2" />
      <defs>
        <clipPath id="clip-Web_1920_45">
          <rect width={1920} height={1083} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_45"
        data-name="Web 1920 – 45"
        clipPath="url(#clip-Web_1920_45)"
      >
        <rect width={1920} height={1083} fill="#fcf2e2" />
        <text
          id="Tuomet_Algoritmas_tarė:"
          data-name="Tuomet Algoritmas tarė:"
          transform="translate(247 339.5)"
          fill="#392d2f"
          fontSize={100}
          fontFamily="Voyage-Regular, Voyage"
        >
          <tspan x="251.05" y={102}>
            Tuomet Algoritmas tarė:
          </tspan>
        </text>
        <motion.g
          id="button_zmogu"
          data-name="button zmogu"
          transform="translate(362 543.999)"
          onClick={() => audioDone && onNext()}
          style={{cursor: 'pointer'}}
          onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
          onHoverEnd={() => setMouseOver(false)}
        >
          <g id="Path_19" data-name="Path 19" fill="#392d2f">
          {mouseover && (
            <React.Fragment>
              <path d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z" stroke="none"/>
              <path d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z" stroke="none" fill="#392d2f"/>
            </React.Fragment>
          )}
          {!mouseover && (
            <React.Fragment>
              <path
                d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z"
                stroke="none"
              />
              <path
                d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z"
                stroke="none"
                fill="#392d2f"
              />
            </React.Fragment>
          )}
          </g>
          <g
            id="Rectangle_1"
            data-name="Rectangle 1"
            transform="translate(0 0)"
            fill="#fcf2e2"
            stroke="#392d2f"
            strokeWidth={2}
          >
            <rect width={1196} height={251} stroke="none" />
            <rect x={1} y={1} width={1194} height={249} fill="none" />
          </g>
          <text
            id="Padarykime_žmogų_pagal_mūsų_paveikslą_ir_panašumą_tevaldo_jie_ir_jūros_žuvis_ir_padangių_sparnuočius_ir_galvijus_ir_visus_laukinius_žemės_gyvulius_ir_visus_žemėje_šliaužiojančius_roplius_"
            data-name="Padarykime žmogų pagal mūsų paveikslą ir panašumą; tevaldo jie ir jūros žuvis, ir padangių sparnuočius, ir galvijus, ir visus laukinius žemės gyvulius, ir visus žemėje šliaužiojančius roplius!"
            transform="translate(598 70)"
            fill="#392d2f"
            fontSize={37}
            fontFamily="Helvetica"
          >
            <tspan x="-458.616" y={0}>
              Padarykime žmogų pagal mūsų paveikslą ir panašumą;{" "}
            </tspan>
            <tspan x="-408.238" y={44}>
              tevaldo jie ir jūros žuvis, ir padangių sparnuočius,{" "}
            </tspan>
            <tspan x="-363.957" y={88}>
              ir galvijus, ir visus laukinius žemės gyvulius,{" "}
            </tspan>
            <tspan x="-311.537" y={132}>
              ir visus žemėje šliaužiojančius roplius!
            </tspan>
          </text>
        </motion.g>
      </g>
    </svg>
  )
}
