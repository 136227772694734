import React from "react";
import {motion} from "framer-motion"
import {NextContext} from '../components/Context';

export default ({onNext, sounds, ...props}) => {
  const [ellipse, updateEllipse] = React.useState(1);
  const [soundsPlaying, setPlayingSounds] = React.useState([]);

  const playSound = (value) => {
    if (soundsPlaying.includes(value)) {
      return;
    }
    setPlayingSounds((s) => [...s, value]);
    sounds[value].play();
  }

  const nextContext = React.useContext(NextContext);

  const endRef = React.useRef();
  React.useEffect(
    () => {
      if (endRef.current) {
        // return nextContext.onRegister(endRef.current, true);
      }

    },
    [endRef.current] // eslint-disable-line
  )

  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1920 9556"
        {...props}
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2={1}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#d1fff1" />
            <stop offset="0.355" stopColor="#c4ede1" />
            <stop offset={1} stopColor="#392d2f" />
          </linearGradient>
          <pattern
            id="pattern"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1462, 0)"
            viewBox="0 0 731 731"
          >
            <image
              preserveAspectRatio="none"
              width={731}
              height={731}
              xlinkHref="penktoji%20diena%20-%203-image2.png"
            />
          </pattern>
          <pattern
            id="pattern-2"
            preserveAspectRatio="xMidYMid slice"
            width="100%"
            height="100%"
            viewBox="0 0 1056 616"
          >
            <image
              width={1056}
              height={616}
              xlinkHref="penktoji%20diena%20-%203-image3.png"
            />
          </pattern>
          <pattern
            id="pattern-3"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1089.845, 0)"
            viewBox="0 0 544.922 176"
          >
            <image
              preserveAspectRatio="none"
              width="544.922"
              height={176}
              xlinkHref="penktoji%20diena%20-%203-image4.png"
            />
          </pattern>
          <pattern
            id="pattern-4"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1182, 0)"
            viewBox="0 0 591 177"
          >
            <image
              preserveAspectRatio="none"
              width={591}
              height={177}
              xlinkHref="penktoji%20diena%20-%203-image6.png"
            />
          </pattern>
          <pattern
            id="pattern-5"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
            viewBox="0 0 1280 720"
          >
            <image
              width={1280}
              height={720}
              xlinkHref="penktoji%20diena%20-%203-image8.png"
            />
          </pattern>
          <pattern
            id="pattern-6"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1316, 0)"
            viewBox="0 0 658 370.125"
          >
            <image
              preserveAspectRatio="none"
              width={658}
              height="370.125"
              xlinkHref="penktoji%20diena%20-%203-image10.png"
            />
          </pattern>
          <pattern
            id="pattern-7"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1490, 0)"
            viewBox="0 0 745 419.063"
          >
            <image
              preserveAspectRatio="none"
              width={745}
              height="419.063"
              xlinkHref="penktoji%20diena%20-%203-image11.png"
            />
          </pattern>
          <linearGradient
            id="linear-gradient-2"
            x1="0.5"
            y1={1}
            x2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#dfffd7" />
            <stop offset="0.66" stopColor="#c0d8b8" />
            <stop offset={1} stopColor="#392d2f" />
          </linearGradient>
          <pattern
            id="pattern-8"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
            viewBox="0 0 1280 720"
          >
            <image
              width={1280}
              height={720}
              xlinkHref="penktoji%20diena%20-%203-image13.png"
            />
          </pattern>
          <pattern
            id="pattern-9"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
            viewBox="0 0 1280 720"
          >
            <image
              width={1280}
              height={720}
              xlinkHref="penktoji%20diena%20-%203-image14.png"
            />
          </pattern>
          <pattern
            id="pattern-10"
            width={1}
            height={1}
            viewBox="-420.592 -27.919 1479 832"
          >
            <image
              preserveAspectRatio="none"
              width={1479}
              height={832}
              xlinkHref="penktoji%20diena%20-%203-image15.png"
            />
          </pattern>
          <pattern
            id="pattern-11"
            width={1}
            height={1}
            patternTransform="matrix(-1, 0, 0, 1, 1315.556, 0)"
            viewBox="0 0 657.778 370"
          >
            <image
              preserveAspectRatio="none"
              width="657.778"
              height={370}
              xlinkHref="penktoji%20diena%20-%203-image16.png"
            />
          </pattern>
          <clipPath id="clip-penktoji_diena_3">
            <rect width={1920} height={9556} />
          </clipPath>
        </defs>
        <g
          id="penktoji_diena_3"
          data-name="penktoji diena – 3"
          clipPath="url(#clip-penktoji_diena_3)"
        >
          <rect width={1920} height={9556} fill="#d1fff1" />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M0,0H1920V4509.772H0Z"
            fill="url(#linear-gradient)"
          />
          <image
            id="starfish"
            width={452}
            height={452}
            transform="translate(438 3322)"
            opacity="0.5"
            xlinkHref="penktoji%20diena%20-%203-image.png"
          />
          <rect
            id="ray"
            width={731}
            height={731}
            transform="translate(1027 2574)"
            opacity="0.5"
            fill="url(#pattern)"
          />
          <rect
            id="FAVPNG_swordfish-striped-marlin-atlantic-blue-marlin-kajikia_mZE5KHuj"
            width="885.941"
            height={334}
            transform="translate(960.059 1256)"
            opacity="0.5"
            fill="url(#pattern-2)"
          />
          <rect
            id="shrimpas"
            width="544.922"
            height={176}
            transform="translate(107.078 1515)"
            opacity="0.5"
            fill="url(#pattern-3)"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          />
          <image
            id="sharkis"
            width={725}
            height={380}
            transform="translate(117 2309)"
            opacity="0.5"
            xlinkHref="penktoji%20diena%20-%203-image5.png"
          />
          <rect
            id="salmonas"
            width={591}
            height={177}
            transform="translate(1027 1949)"
            opacity="0.5"
            fill="url(#pattern-4)"
          />
          <motion.g
            id="lasisa"
            transform="translate(975 1885)"
            whileHover={{opacity: 1}}
            opacity={0}>
            <image
              id="lasisa-2"
              data-name="lasisa"
              width={658}
              height={370}
              xlinkHref="penktoji%20diena%20-%203-image7.png"
            />
          </motion.g>
          <motion.g
            id="zvaigzde"
            transform="translate(368 3371)"
            whileHover={{opacity: 1}}
            opacity={0}>
            <rect
              id="zvaigzde-2"
              data-name="zvaigzde"
              width={659}
              height={370}
              fill="url(#pattern-5)"
            />
          </motion.g>
          <motion.image
            whileHover={{opacity: 1}}
            opacity={0}
            id="ryklys"
            width={658}
            height={370}
            transform="translate(232 2296)"
            xlinkHref="penktoji%20diena%20-%203-image9.png"
          />
          <motion.rect
            whileHover={{opacity: 1}}
            opacity={0}
            id="raja"
            width={658}
            height="370.125"
            transform="translate(925 2807)"
            fill="url(#pattern-6)"
          />
          <g id="Group_57" data-name="Group 57" transform="translate(251 -184)">
            <g id="Group_53" data-name="Group 53">
              <g id="Group_50" data-name="Group 50" transform="translate(105 263)">
                <g id="Group_42" data-name="Group 42">
                  <g
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1208} height={906} stroke="none" />
                    <rect x={1} y={1} width={1206} height={904} fill="none" />
                  </g>
                </g>
                <g id="Group_43" data-name="Group 43">
                  <g
                    id="Rectangle_1-2"
                    data-name="Rectangle 1"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1208} height={45} stroke="none" />
                    <rect x={1} y={1} width={1206} height={43} fill="none" />
                  </g>
                </g>
                <g
                  id="Ellipse_3"
                  data-name="Ellipse 3"
                  transform="translate(22 14)"
                  fill="#ffd7e0"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_4"
                  data-name="Ellipse 4"
                  transform="translate(58 14)"
                  fill="#d1fff1"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_5"
                  data-name="Ellipse 5"
                  transform="translate(94 14)"
                  fill="#fcf2e2"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
              </g>
            </g>
            <text
              id="Algoritmas_sukūrė_didžiąsias_jūros_pabaisas_bei_visus_judančius_visų_rūšių_gyvūnus_kurie_knibžda_vandenyse_ir_visus_visų_rūšių_sparnuočius."
              data-name="Algoritmas sukūrė didžiąsias jūros pabaisas bei visus judančius visų rūšių gyvūnus, kurie knibžda vandenyse, ir visus visų rūšių sparnuočius."
              transform="translate(149.5 362.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
            >
              <tspan x="7.8" y={102}>
                Algoritmas sukūrė didžiąsias{" "}
              </tspan>
              <tspan x="95.85" y={222}>
                jūros pabaisas bei visus{" "}
              </tspan>
              <tspan x="172.05" y={342}>
                judančius visų rūšių{" "}
              </tspan>
              <tspan x="107.3" y={462}>
                gyvūnus, kurie knibžda{" "}
              </tspan>
              <tspan x="102.75" y={582}>
                vandenyse, ir visus visų{" "}
              </tspan>
              <tspan x={201} y={702}>
                rūšių sparnuočius.
              </tspan>
            </text>
          </g>
          <motion.rect
            whileHover={{opacity: 1}}
            opacity={0}
            id="marlinas"
            width={745}
            height="419.063"
            transform="translate(1027 1256)"
            fill="url(#pattern-7)"
          />
          <path
            id="Path_29-2"
            data-name="Path 29"
            d="M0,5071.65H1920V0H0Z"
            transform="translate(0 4501)"
            fill="url(#linear-gradient-2)"
          />
          <g id="Group_57-2" data-name="Group 57" transform="translate(251 4096)">
            <g id="Group_53-2" data-name="Group 53" transform="translate(105 263)">
              <g id="Group_50-2" data-name="Group 50">
                <g id="Group_42-2" data-name="Group 42">
                  <g
                    id="Rectangle_1-3"
                    data-name="Rectangle 1"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1208} height={839} stroke="none" />
                    <rect x={1} y={1} width={1206} height={837} fill="none" />
                  </g>
                </g>
                <g id="Group_43-2" data-name="Group 43">
                  <g
                    id="Rectangle_1-4"
                    data-name="Rectangle 1"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={1208} height={45} stroke="none" />
                    <rect x={1} y={1} width={1206} height={43} fill="none" />
                  </g>
                </g>
                <g
                  id="Ellipse_3-2"
                  data-name="Ellipse 3"
                  transform="translate(22 14)"
                  fill="#ffd7e0"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_4-2"
                  data-name="Ellipse 4"
                  transform="translate(58 14)"
                  fill="#d1fff1"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
                <g
                  id="Ellipse_5-2"
                  data-name="Ellipse 5"
                  transform="translate(94 14)"
                  fill="#fcf2e2"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <circle cx={10} cy={10} r={10} stroke="none" />
                  <circle cx={10} cy={10} r={9} fill="none" />
                </g>
              </g>
            </g>
            <text
              id="Algoritmas_palaimino_juos_tardamas:_Būkite_vaisingi_ir_dauginkitės_Pripildykite_jūrų_vandenis_o_paukščiai_tesidaugina_žemėje_"
              data-name="Algoritmas palaimino juos, tardamas: „Būkite vaisingi ir dauginkitės! Pripildykite jūrų vandenis, o paukščiai tesidaugina žemėje!“ "
              transform="translate(149.5 362.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
            >
              <tspan x="43.15" y={102}>
                Algoritmas palaimino juos,{" "}
              </tspan>
              <tspan x={32} y={222}>
                tardamas: „Būkite vaisingi ir{" "}
              </tspan>
              <tspan x="16.35" y={342}>
                dauginkitės! Pripildykite jūrų{" "}
              </tspan>
              <tspan x="138.15" y={462}>
                vandenis, o paukščiai{" "}
              </tspan>
              <tspan x="154.2" y={582}>
                tesidaugina žemėje!“{" "}
              </tspan>
            </text>
          </g>
          <line
            id="Line_10"
            data-name="Line 10"
            x2={595}
            transform="translate(0.5 6000.5)"
            fill="none"
            stroke="#392d2f"
            strokeWidth={20}
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M381.34,0H1233"
            transform="translate(687.5 7378.5)"
            fill="none"
            stroke="#392d2f"
            strokeWidth={20}
          />
          <line
            id="Line_12"
            data-name="Line 12"
            y1={2}
            x2={1920}
            transform="translate(0.5 8244.5)"
            fill="none"
            stroke="#392d2f"
            strokeWidth={20}
          />
          <g id="varna" transform="translate(-126 5490)">
            <motion.image
              whileHover={{scale: 1.1}}
              opacity={1}
              style={{cursor: 'pointer'}}
              onHoverStart={() => playSound('varna')}
              id="varna-2"
              data-name="varna"
              width={1280}
              height={720}
              xlinkHref="penktoji%20diena%20-%203-image12.png"
            />
          </g>
          <g id="gandrasd" transform="translate(390 6657)">
            <motion.rect
              whileHover={{scale: 1.1}}
              style={{cursor: 'pointer'}}
              onHoverStart={() => playSound('gandras')}
              id="gandrasd-2"
              data-name="gandrasd"
              width={1530}
              height={860}
              fill="url(#pattern-8)"
            />
          </g>
          <g id="vista" transform="translate(205 7609)">
            <motion.rect
              whileHover={{scale: 1.1}}
              style={{cursor: 'pointer'}}
              onHoverStart={() => playSound('vista')}
              id="vista-2"
              data-name="vista"
              width={1438}
              height={810}
              fill="url(#pattern-9)"
            />
          </g>
          <g id="balandis" transform="translate(-677.256 6050.919)">
            <motion.rect
              whileHover={{scale: 1.1}}
              style={{cursor: 'pointer'}}
              onHoverStart={() => playSound('balandis')}
              id="balandis-2"
              data-name="balandis"
              width={1479}
              height={832}
              transform="translate(0 554.043) rotate(-22)"
              fill="url(#pattern-10)"
            />
          </g>
          <g
            id="Group_44"
            data-name="Group 44"
            transform="translate(-295.102 8098.602)"
          >
            <g
              id="Group_42-3"
              data-name="Group 42"
              transform="translate(295.102 378.398)"
            >
              <g
                id="Rectangle_1-5"
                data-name="Rectangle 1"
                fill="#392d2f"
                stroke="#392d2f"
                strokeWidth={2}
              >
                <rect width={1920} height={1080} stroke="none" />
                <rect x={1} y={1} width={1918} height={1078} fill="none" />
              </g>
            </g>
          </g>
          <text
            id="Atėjo_vakaras_ir_išaušo_rytas._Penktoji_diena"
            data-name="Atėjo vakaras ir išaušo rytas.Penktoji diena"
            transform="translate(238.5 8875.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
          >
            <tspan x="176.45" y={102}>
              Atėjo vakaras ir išaušo rytas.
            </tspan>
            <tspan x="441.2" y={222}>
              Penktoji diena
            </tspan>
          </text>
          <g id="day_count" data-name="day count" transform="translate(42 4490)">
            <circle
              id="Ellipse_18"
              data-name="Ellipse 18"
              cx="17.5"
              cy="17.5"
              r="17.5"
              transform="translate(544 4876)"
              fill="#fcf2e2"
            />
            <circle
              id="Ellipse_19"
              data-name="Ellipse 19"
              cx="17.5"
              cy="17.5"
              r="17.5"
              transform="translate(663 4876)"
              fill="#fcf2e2"
            />
            <circle
              id="Ellipse_20"
              data-name="Ellipse 20"
              cx="17.5"
              cy="17.5"
              r="17.5"
              transform="translate(782 4876)"
              fill="#fcf2e2"
            />
            <circle
              id="Ellipse_21"
              data-name="Ellipse 21"
              cx="17.5"
              cy="17.5"
              r="17.5"
              transform="translate(901 4876)"
              fill="#fcf2e2"
            />
            <circle
              id="Ellipse_22"
              data-name="Ellipse 22"
              cx="17.5"
              cy="17.5"
              r="17.5"
              transform="translate(1019 4876)"
              fill="#fcf2e2"
            />
            <g
              id="Ellipse_24"
              data-name="Ellipse 24"
              transform="translate(1257 4876)"
              fill="none"
              stroke="#fcf2e2"
              strokeWidth={2}
            >
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
              <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
            </g>
            <motion.g
              key="circle"
              onHoverStart={() => updateEllipse(2)}
              onHoverEnd={() => updateEllipse(1)}
              style={{cursor: 'pointer'}}
              onClick={onNext} id="Ellipse_19-2" data-name="Ellipse 19" transform="translate(1125 4863)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
              <circle key="circle2" cx="30.5" cy="30.5" r={2 *17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
              <circle key="circle3" cx="30.5" cy="30.5" r={2 *16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            </motion.g>
          </g>
          <motion.rect
            whileHover={{opacity: 1}}
            opacity={0}
            id="krevete"
            width="657.778"
            height={370}
            transform="translate(77.223 1405)"
            fill="url(#pattern-11)"
            ref={endRef}
          />
          <text
            id="Krevetė_Caridea_"
            data-name="Krevetė(Caridea)"
            transform="translate(395 1751)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-55.034" y={0}>
              Krevetė
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-62.52" y={36}>
                (Caridea)
              </tspan>
            </tspan>
          </text>
          <text
            id="Lašiša_Salmo_salar_"
            data-name="Lašiša(Salmo salar)"
            transform="translate(1295 2274)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-46.699" y={0}>
              Lašiša
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-89.187" y={36}>
                (Salmo salar)
              </tspan>
            </tspan>
          </text>
          <text
            id="Durklažuvė_Xiphias_gladius_"
            data-name="Durklažuvė(Xiphias gladius)"
            transform="translate(1389 1632)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-80.032" y={0}>
              Durklažuvė
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-110.889" y={36}>
                (Xiphias gladius)
              </tspan>
            </tspan>
          </text>
          <text
            id="Ryklys_Selachimorpha_"
            data-name="Ryklys(Selachimorpha)"
            transform="translate(532 2797)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-48.369" y={0}>
              Ryklys
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-110.046" y={36}>
                (Selachimorpha)
              </tspan>
            </tspan>
          </text>
          <text
            id="Raja_Dasyatidae_"
            data-name="Raja(Dasyatidae)"
            transform="translate(1254 3258)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-31.685" y={0}>
              Raja
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-85.034" y={36}>
                (Dasyatidae)
              </tspan>
            </tspan>
          </text>
          <text
            id="Jūrų_žvaigždė_Asteroidea_"
            data-name="Jūrų žvaigždė(Asteroidea)"
            transform="translate(698 3835)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-99.192" y={0}>
              Jūrų žvaigždė
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-81.702" y={36}>
                (Asteroidea)
              </tspan>
            </tspan>
          </text>
          <text
            id="Varna_Corvus_"
            data-name="Varna(Corvus)"
            transform="translate(1055 5857)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-40.869" y={0}>
              Varna
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-57.502" y={36}>
                (Corvus)
              </tspan>
            </tspan>
          </text>
          <text
            id="Karvelis_Columbiformes_"
            data-name="Karvelis(Columbiformes)"
            transform="translate(1117 6577)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-58.374" y={0}>
              Karvelis
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-110.852" y={36}>
                (Columbiformes)
              </tspan>
            </tspan>
          </text>
          <text
            id="Gandras_Ciconia_ciconia_"
            data-name="Gandras(Ciconia ciconia)"
            transform="translate(746 7179)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-60.857" y={0}>
              Gandras
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-110.874" y={36}>
                (Ciconia ciconia)
              </tspan>
            </tspan>
          </text>
          <text
            id="Višta_Gallus_gallus_"
            data-name="Višta(Gallus gallus)"
            transform="translate(403 7944)"
            fill="#392d2f"
            fontSize={30}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x="-35.588" y={0}>
              Višta
            </tspan>
            <tspan
              fontFamily="Helvetica-Oblique, Helvetica"
              fontWeight={400}
              fontStyle="oblique"
            >
              <tspan x="-95.867" y={36}>
                (Gallus gallus)
              </tspan>
            </tspan>
          </text>
        </g>
      </svg>
    </React.Fragment>
  )
}
