import React from "react";
import Scene14 from './14';
import Scene16 from './16';
import BackgroundColor from '../components/backgroundColor';
import {motion} from "framer-motion"

export default ({onNext, sounds, ...props}) => {

  const [showWindows, setShowWindows] = React.useState(false);
  const [showSwitcher, setShowSwitcher] = React.useState(false);
  const [mouseover, setMouseOver] = React.useState(false);

  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);
  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.skliautas.on('end', () => {
          setAudioDone(true);
        });
        sounds.skliautas.play();
      }
    },
    [audioDone, playAudio, sounds.skliautas]
  )

  return (
    <React.Fragment>
      {showSwitcher && (
        <Scene16 onNext={onNext} {...props} />
      )}
      {showWindows && (
        <Scene14 onNext={onNext} {...props} />
      )}
      {!showWindows && !showSwitcher && (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 1920 1080">
          <BackgroundColor color="#fcf2e2" />
          <defs>
            <clipPath id="clip-Web_1920_10">
              <rect width={1920} height={1080} />
            </clipPath>
          </defs>
          <g id="Web_1920_10" data-name="Web 1920 – 10" clipPath="url(#clip-Web_1920_10)">
            <rect width={1920} height={1080} fill="#fcf2e2" />
            <motion.g
              id="button_zmogu"
              data-name="button zmogu"
              transform="translate(362 543.999)"
              onClick={() => audioDone && setShowWindows(true)}
              style={{cursor: 'pointer'}}
              onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
              onHoverEnd={() => setMouseOver(false)}>
              <g id="Path_19" data-name="Path 19" fill="#392d2f">
                {mouseover && (
                  <React.Fragment>
                    <path
                      d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z"
                      stroke="none"
                    />
                    <path
                      d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z"
                      stroke="none"
                      fill="#392d2f"
                    />
                  </React.Fragment>
                )}
                {!mouseover && (
                  <React.Fragment>
                    <path d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z" stroke="none" />
                    <path d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z" stroke="none" fill="#392d2f" />
                  </React.Fragment>
                )}
              </g>
              <g id="Path_28" data-name="Path 28" transform="translate(0 0)" fill="#fcf2e2">
                {mouseover && (
                  <React.Fragment>
                    <path
                      d="M 1195 250 L 1 250 L 1 1 L 1195 1 L 1195 250 Z"
                      stroke="none"
                    />
                    <path
                      d="M 2 2 L 2 249 L 1194 249 L 1194 2 L 2 2 M 0 0 L 1196 0 L 1196 251 L 0 251 L 0 0 Z"
                      stroke="none"
                      fill="#392d2f"
                    />
                  </React.Fragment>
                )}
                {!mouseover && (
                  <React.Fragment>
                    <path d="M 1195 250 L 1 250 L 1 1 L 1195 1 L 1195 250 Z" stroke="none" />
                    <path d="M 2 2 L 2 249 L 1194 249 L 1194 2 L 2 2 M 0 0 L 1196 0 L 1196 251 L 0 251 L 0 0 Z" stroke="none" fill="#392d2f" />
                  </React.Fragment>
                )}
              </g>
              <text id="Tebūna_skliautas_viduryje_vandenų_ir_teatskiria_vandenis_nuo_vandenų_" data-name="Tebūna skliautas viduryje vandenų ir teatskiria vandenis nuo vandenų!" transform="translate(598 116)" fill="#392d2f" fontSize={37} fontFamily="Helvetica"><tspan x="-282.821" y={0}>Tebūna skliautas viduryje vandenų</tspan><tspan x="-285.901" y={44}>ir teatskiria vandenis nuo vandenų!</tspan></text>
            </motion.g>
            <text
              id="Algoritmas_tarė:"
              data-name="Algoritmas tarė:"
              transform="translate(247 339.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
              stroke="#707070" stroke-width="1"
            >
              <tspan x="410.1" y={102}>
                Algoritmas tarė:
              </tspan>
            </text>
          </g>
        </svg>
      )}
    </React.Fragment>
  )
}
