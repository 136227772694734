import React from "react";
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, ...props}) => {
  const [ellipse, updateEllipse] = React.useState(1);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 1920 1080">
      <BackgroundColor color="#392d2f" />
      <defs>
        <clipPath id="clip-Web_1920_16">
          <rect width={1920} height={1080} />
        </clipPath>
      </defs>
      <g id="Web_1920_16" data-name="Web 1920 – 16" clipPath="url(#clip-Web_1920_16)">
        <rect width={1920} height={1080} fill="#392d2f" />
        <text
          id="Atėjo_vakaras_ir_išaušo_rytas._Antroji_diena"
          data-name="Atėjo vakaras ir išaušo rytas. Antroji diena"
          transform="translate(247.5 398.5)"
          fill="#fcf2e2"
          fontSize={100}
          fontFamily="Voyage-Regular, Voyage"
        >
          <tspan x="176.45" y={102}>
            Atėjo vakaras ir išaušo rytas.
          </tspan>
          <tspan x="465.65" y={222}>
            Antroji diena
          </tspan>
        </text>
        <g id="day_count" data-name="day count" transform="translate(42 -3987)">
          <circle id="Ellipse_18" data-name="Ellipse 18" cx="17.5" cy="17.5" r="17.5" transform="translate(544 4876)" fill="#fcf2e2" />
          <motion.g
            key="circle"
            onHoverStart={() => updateEllipse(2)}
            onHoverEnd={() => updateEllipse(1)}
            style={{cursor: 'pointer'}}
            onClick={onNext} id="Ellipse_19" data-name="Ellipse 19" transform="translate(785 4876)" fill="#392d2f" stroke="#fcf2e2" strokeWidth={2}>
            <circle key="circle2" cx="17.5" cy="17.5" r={2 *17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            <circle key="circle3" cx="17.5" cy="17.5" r={2 *16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
          </motion.g>
          <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(901 4876)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(1019 4876)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <g id="Ellipse_23" data-name="Ellipse 23" transform="translate(1138 4876)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <g id="Ellipse_24" data-name="Ellipse 24" transform="translate(1257 4876)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <circle id="Ellipse_25" data-name="Ellipse 25" cx="17.5" cy="17.5" r="17.5" transform="translate(663 4876)" fill="#fcf2e2" />
        </g>
      </g>
    </svg>
  )
}
