import React from "react";
import Scene33 from './33';
import Scene31 from './31';
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, sounds, ...props}) => {

  const [showWindows, setShowWindows] = React.useState(false);
  const [showCelestialBodies, setShowCelestialBodies] = React.useState(false);
  const [mouseover, setMouseOver] = React.useState(false);

  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);

  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.sviesuliai.on('end', () => {
          setAudioDone(true);
        });
        sounds.sviesuliai.play();
      }
    },
    [audioDone, playAudio, sounds.sviesuliai]
  )
  React.useEffect(
    () => {
      if (showCelestialBodies) {
        window.scrollTop = window.scrollY = 0;
      }
    },
    [showCelestialBodies]
  )
  return (
    <React.Fragment>
      {showCelestialBodies && (
        <Scene31 {...props} onNext={onNext} />
      )}
      {showWindows && (
        <Scene33 {...props} onNext={onNext} />
      )}
      {!showWindows && !showCelestialBodies && (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 1920 1080">
        <BackgroundColor color="#fcf2e2" />
        <defs>
           <clipPath id="clip-Web_1920_19">
             <rect width={1920} height={1080} />
           </clipPath>
          </defs>
          <g
           id="Web_1920_19"
           data-name="Web 1920 – 19"
           clipPath="url(#clip-Web_1920_19)"
          >
           <rect width={1920} height={1080} fill="#fcf2e2" />
           <motion.g
             id="button_zmogu"
             data-name="button zmogu"
             transform="translate(362 543.999)"
             onClick={() => audioDone && setShowWindows(true)}
             style={{cursor: 'pointer'}}
             onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
             onHoverEnd={() => setMouseOver(false)}
           >
             <g id="Path_19" data-name="Path 19" fill="#392d2f">
               {mouseover && (
                 <React.Fragment>
                   <path d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z" stroke="none"/>
                   <path d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z" stroke="none" fill="#392d2f"/>
                 </React.Fragment>
               )}
               {!mouseover && (
                 <React.Fragment>
                   <path
                     d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z"
                     stroke="none"
                   />
                   <path
                     d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z"
                     stroke="none"
                     fill="#392d2f"
                   />
                 </React.Fragment>
               )}
             </g>
             <g
               id="Rectangle_1"
               data-name="Rectangle 1"
               transform="translate(0 0)"
               fill="#fcf2e2"
               stroke="#392d2f"
               strokeWidth={2}
             >
               <rect width={1196} height={251} stroke="none" />
               <rect x={1} y={1} width={1194} height={249} fill="none" />
             </g>
             <text
                id="Tebūna_šviesuliai_dangaus_skliaute_dienai_nuo_nakties_atskirti_Teženklina_jie_šventes_dienas_ir_metus_tebūna_jie_šviesuliai_dangaus_skliaute_žemei_apšviesti_"
                data-name="Tebūna šviesuliai dangaus skliaute dienai nuo nakties atskirti! Teženklina jie šventes, dienas ir metus, tebūna jie šviesuliai dangaus skliaute žemei apšviesti!"
                transform="translate(598 93)"
                fill="#392d2f"
                fontSize={37}
                fontFamily="Helvetica"
              >
                <tspan x="-444.298" y={0}>
                  Tebūna šviesuliai dangaus skliaute dienai nuo nakties{" "}
                </tspan>
                <tspan x="-389.367" y={44}>
                  atskirti! Teženklina jie šventes, dienas ir metus,{" "}
                </tspan>
                <tspan x="-441.164" y={88}>
                  tebūna jie šviesuliai dangaus skliaute žemei apšviesti!
                </tspan>
              </text>
            </motion.g>
            <text
              id="Algoritmas_tarė:"
              data-name="Algoritmas tarė:"
              transform="translate(247 339.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
            >
              <tspan x="410.1" y={102}>
                Algoritmas tarė:
              </tspan>
            </text>
          </g>
        </svg>
      )}
    </React.Fragment>
  )
}
