import React from "react";
import {motion, transform} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, setHeight, ...props}) => {
  const [showEnd, setShowEnd] = React.useState(false);
  const [ellipse, updateEllipse] = React.useState(1);
  const path = React.useRef();
  const svg = React.useRef();
  const endRef = React.useRef();
  const [dragState, setDragState] = React.useState(false);
  const [sun, setSun] = React.useState({x: 0, y: 0})
  const [moon, setMoon] = React.useState({x: 0, y: 0})
  const [color, setColor] = React.useState(1)
  const [starsOpacity, setStarsOpacity] = React.useState(0.661);
  const [isNight, setIsNight] = React.useState(true);

  React.useEffect(
    () => {
      setSun(path.current.getPointAtLength(transform(33, [33, 1830], [3800, 6025])))
      setMoon(path.current.getPointAtLength(transform(33, [700, 1830], [5000, 7000])))

    },
    [path]
  )

  const handleMouseDown = (body) => () => setDragState(body);
  const handleMouseup = () => setDragState(false);
  const handleMouseMove = (body) => (event) => {
    if (!dragState) {
      return;
    }
    const p = svg.current.createSVGPoint();


    if (body === 'sun') {
      p.x = event.pageX;
      p.y = event.pageY;

      const currentScr = p.matrixTransform(svg.current.getScreenCTM().inverse()).x;

      setSun(path.current.getPointAtLength(transform(currentScr, [33, 1830], [3800, 6025])));
      setMoon(path.current.getPointAtLength(transform(currentScr, [33, 1830], [5000, 7000])));
      setColor(transform(currentScr, [33, 1830], [1, 0]));
      setStarsOpacity(transform(currentScr, [33, 1830 / 2], [0.661, 0]))
    } else {
      var p1 = svg.current.createSVGPoint()
      p1.x = 1000
      p1.y = 90
      const td = p1.matrixTransform(svg.current.getScreenCTM());
      p.x = event.pageX - td.x;
      p.y = event.pageY + td.y;

      const currentScr = p.matrixTransform(svg.current.getScreenCTM().inverse()).x;

      setSun(path.current.getPointAtLength(transform(currentScr, [33, 1830], [3800, 6025])));
      setMoon(path.current.getPointAtLength(transform(currentScr, [33, 1830], [5000, 7000])));
      setColor(transform(currentScr, [33, 1830], [1, 0]));
      setStarsOpacity(transform(currentScr, [33, 1830 / 2], [0.661, 0]))
    }
  }

  React.useEffect(
    () => {
      if (isNight && moon.x > 1600) {
        setIsNight(false);
      }
    },
    [isNight, moon]
  )

  React.useEffect(
    () => {
      if (!showEnd && sun.x > 1950) {
        setShowEnd(true);
        setHeight('100%')
      }
    },
    [setHeight, showEnd, sun]
  )
  //hsl(350, 12%, 20%)
  //hsl(37, 81%, 94%)
  // const color =;

  React.useEffect(
    () => {
      if (endRef.current) {
        setSun(path.current.getPointAtLength(transform(1830, [33, 1830], [3800, 6025 + 500])));
        setMoon(path.current.getPointAtLength(transform(1830, [33, 1830], [5000, 7000 + 500])));
        window.scrollTo(0, endRef.current.getBoundingClientRect().top)
      }
    },
    [endRef.current] // eslint-disable-line
  )

  return (
    <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: -1
    }} viewBox={`0 0 100 100`} preserveAspectRatio="none">
      <rect width={100} height={100} fill="#fcf2e2" />
      <g
        id="Rectangle_1"
        data-name="Rectangle 1"
        fill={'hsl(350, 12%, 20%)'}
        stroke="hsl(350, 12%, 20%)"
        strokeWidth={2}
        opacity={color}
      >
        <rect width={100} height={100} stroke="none" />
        <rect x={1} y={1} width={100} height={100} fill="none" />
      </g>
      <g
        id="Rectangle_1"
        data-name="Rectangle 1"
        fill={'hsl(37, 81%, 94%)'}
        stroke="hsl(37, 81%, 94%)"
        strokeWidth={2}
        opacity={1 - color}
      >
        <rect width={100} height={100} stroke="none" />
        <rect x={1} y={1} width={100} height={100} fill="none" />
      </g>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox={`0 0 1920 ${showEnd ? 2160 : 1080}`}
      ref={svg}
      onMouseUp={handleMouseup}
      style={{...props.style, height: 'auto', marginTop: showEnd ? 'default' : 'auto'}}
    >
      <defs>
        <pattern
          id="pattern"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox="0 0 1280 720"
        >
          <image
            width={1280}
            height={720}
            xlinkHref="Web%201920%20-%2031-image2.png"
          />
        </pattern>
        <clipPath id="clip-Web_1920_31">
          <rect width={1920} height={showEnd ? 2160 : 1080} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_31"
        data-name="Web 1920 – 31"
        clipPath="url(#clip-Web_1920_31)"
      >
        <rect width={1920} height={showEnd ? 2160 : 1080} fill="#fcf2e2" />
        <g
          id="Group_44"
          data-name="Group 44"
          transform="translate(-295.102 -378.398)"
        >
          <g
            id="Group_42"
            data-name="Group 42"
            transform="translate(295.102 378.398)"
          >
            <g
              id="Rectangle_1"
              data-name="Rectangle 1"
              fill={'hsl(350, 12%, 20%)'}
              stroke="hsl(350, 12%, 20%)"
              strokeWidth={2}
              opacity={color}
            >
              <rect width={1920} height={1080} stroke="none" />
              <rect x={1} y={1} width={1918} height={1078} fill="none" />
            </g>
            <g
              id="Rectangle_1"
              data-name="Rectangle 1"
              fill={'hsl(37, 81%, 94%)'}
              stroke="hsl(37, 81%, 94%)"
              strokeWidth={2}
              opacity={1 - color}
            >
              <rect width={1920} height={1080} stroke="none" />
              <rect x={1} y={1} width={1918} height={1078} fill="none" />
            </g>
          </g>
        </g>
        <g
          id="Ellipse_6"
          data-name="Ellipse 6"
          transform="translate(-83 357)"
          fill="none"
          stroke="#d1fff1"
          strokeWidth={6}
        >
          <circle cx={1043} cy={1043} r={1043} stroke="none"  ref={path} />
          <circle cx={1043} cy={1043} r={1040} fill="none" />
        </g>
        <g transform="translate(-700 90)">
          <image
            id="saule_mapped"
            data-name="saule mapped"
            width={1054}
            height={593}
            transform={`translate(${sun.x} ${sun.y})`}
            xlinkHref="Web%201920%20-%2031-image.png"
            onMouseDown={handleMouseDown('sun')}
            onMouseMove={handleMouseMove('sun')}
            style={{cursor: 'pointer'}}
          />
        </g>
        <g transform="translate(-350 180)">
          <image
            id="moon"
            width={527}
            height={296.5}
            transform={`translate(${moon.x} ${moon.y})`}
            xlinkHref="Web%201920%20-%2029-image.png"
            onMouseDown={handleMouseDown('moon')}
            onMouseMove={handleMouseMove('moon')}
            style={{cursor: 'pointer'}}
          />
        </g>
        <g
          id="Ellipse_7"
          data-name="Ellipse 7"
          transform="translate(-34 596)"
          fill="#dfffd7"
          stroke="#fcf2e2"
          strokeWidth={4}
        >
          <ellipse cx={994} cy={1043} rx={994} ry={1043} stroke="none" />
          <ellipse cx={994} cy={1043} rx={992} ry={1041} fill="none" />
        </g>
        {showEnd && (
          <g
            id="Group_44-2"
            data-name="Group 44"
            transform="translate(-295.102 701.602)"
          >
            <g
              id="Group_42-2"
              data-name="Group 42"
              transform="translate(295.102 378.398)"
            >
              <g
                id="Rectangle_1-2"
                data-name="Rectangle 1"
                fill="#392d2f"
                stroke="#392d2f"
                strokeWidth={2}
              >
                <rect width={1920} height={1080} stroke="none" />
                <rect x={1} y={1} width={1918} height={1078} fill="none" />
              </g>
            </g>
          </g>
        )}
        {showEnd && (
          <text
            id="Atėjo_vakaras_ir_išaušo_rytas._Ketvirtoji_diena"
            data-name="Atėjo vakaras ir išaušo rytas. Ketvirtoji diena"
            transform="translate(238.5 1478.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
            ref={endRef}
          >
            <tspan x="176.45" y={102}>
              Atėjo vakaras ir išaušo rytas.
            </tspan>
            <tspan x="424.55" y={222}>
              Ketvirtoji diena
            </tspan>
          </text>
        )}
        {(
          <g id="Group_64" data-name="Group 64" opacity={starsOpacity}>
            <rect
              id="saule_balta"
              data-name="saule balta"
              width={126}
              height={71}
              transform="translate(79 237)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-2"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(311 93)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-3"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(256 238)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-4"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(869 59)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-5"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(615 226)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-6"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(1450 113)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-7"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(1294 228)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-8"
              data-name="saule balta"
              width={63}
              height={35}
              transform="translate(1768 313)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-9"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(1655 423)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-10"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(1612 227)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-11"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(1797 67)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-12"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(130 471)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-13"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(200 134)"
              fill="url(#pattern)"
            />
            <rect
              id="saule_balta-14"
              data-name="saule balta"
              width={34}
              height={19}
              transform="translate(1173 125)"
              fill="url(#pattern)"
            />
          </g>
        )}
        <g id="day_count" data-name="day count" transform="translate(42 -2907)">
          <circle
            id="Ellipse_18"
            data-name="Ellipse 18"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(544 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(663 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_20"
            data-name="Ellipse 20"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(782 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_21"
            data-name="Ellipse 21"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(901 4876)"
            fill="#fcf2e2"
          />
          <g
            id="Ellipse_23"
            data-name="Ellipse 23"
            transform="translate(1138 4876)"
            fill="none"
            stroke="#fcf2e2"
            strokeWidth={2}
          >
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <g
            id="Ellipse_24"
            data-name="Ellipse 24"
            transform="translate(1257 4876)"
            fill="none"
            stroke="#fcf2e2"
            strokeWidth={2}
          >
            <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
            <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
          </g>
          <motion.g
            key="circle"
            onHoverStart={() => updateEllipse(2)}
            onHoverEnd={() => updateEllipse(1)}
            style={{cursor: 'pointer'}}
            onClick={onNext} id="Ellipse_19-2" data-name="Ellipse 19" transform="translate(1006 4863)" fill="#392d2f" stroke="#fcf2e2" strokeWidth={2}>
            <circle key="circle2" cx="30.5" cy="30.5" r={2 *17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            <circle key="circle3" cx="30.5" cy="30.5" r={2 *16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
          </motion.g>
        </g>
      </g>
    </svg>
    </React.Fragment>
  )
}
