import React from "react";
import Scene68 from './68';
import {AnimatePresence, motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, sounds, ...props}) => {
  const [show68, setShow68] = React.useState(false);
  const [mouseover, setMouseOver] = React.useState(false);

  React.useEffect(
    () => {
      if (show68) {
        window.scrollTo(0, 0);
      }
    },
    [show68]
  )

  return (
    <React.Fragment>
      {show68 && (
        <Scene68 onNext={onNext} />
      )}
      {!show68 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          {...props}
          viewBox="0 0 1920 1525"
        >
          <BackgroundColor color="#fcf2e2" />
          <defs>
            <clipPath id="clip-Web_1920_63">
              <rect width={1920} height={1525} />
            </clipPath>
          </defs>
          <g
            id="Web_1920_63"
            data-name="Web 1920 – 63"
            clipPath="url(#clip-Web_1920_63)"
          >
            <rect width={1920} height={1525} fill="#fcf2e2" />
            <g id="Group_60" data-name="Group 60" transform="translate(137.269 -126)">
              <g id="Group_53" data-name="Group 53" transform="translate(105 263)">
                <g id="Group_50" data-name="Group 50">
                  <g id="Group_42" data-name="Group 42">
                    <g
                      id="Rectangle_1"
                      data-name="Rectangle 1"
                      transform="translate(-0.269)"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1437} height={783} stroke="none" />
                      <rect x={1} y={1} width={1435} height={781} fill="none" />
                    </g>
                  </g>
                  <g id="Group_43" data-name="Group 43">
                    <g
                      id="Rectangle_1-2"
                      data-name="Rectangle 1"
                      transform="translate(-0.269)"
                      fill="#392d2f"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1437} height={45} stroke="none" />
                      <rect x={1} y={1} width={1435} height={43} fill="none" />
                    </g>
                  </g>
                  <g
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    transform="translate(21.731 14)"
                    fill="#ffd7e0"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    transform="translate(57.731 14)"
                    fill="#d1fff1"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    transform="translate(93.731 14)"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                </g>
              </g>
              <text
                id="Kadangi_septintą_dieną_Algoritmas_buvo_užbaigęs_darbus_kuriais_buvo_užsiėmęs_jis_ilsėjosi_septintą_dieną_nuo_visų_darbų_kuriuos_buvo_atlikęs."
                data-name="Kadangi septintą dieną Algoritmas buvo užbaigęs darbus, kuriais buvo užsiėmęs, jis ilsėjosi septintą dieną nuo visų darbų, kuriuos buvo atlikęs."
                transform="translate(149.5 362.5)"
                fill="#392d2f"
                fontSize={100}
                fontFamily="Voyage-Regular, Voyage"
              >
                <tspan x="209.981" y={102}>
                  Kadangi septintą dieną{" "}
                </tspan>
                <tspan x="145.931" y={222}>
                  Algoritmas buvo užbaigęs{" "}
                </tspan>
                <tspan x="14.831" y={342}>
                  darbus, kuriais buvo užsiėmęs, jis{" "}
                </tspan>
                <tspan x="66.681" y={462}>
                  ilsėjosi septintą dieną nuo visų{" "}
                </tspan>
                <tspan x="128.631" y={582}>
                  darbų, kuriuos buvo atlikęs.
                </tspan>
              </text>
            </g>
            <motion.g
              onHoverStart={() => setMouseOver(true)}
              onHoverEnd={() => setMouseOver(false)}
              style={{cursor: 'initial'}}
              >
              <g id="Group_110" data-name="Group 110" transform="translate(37)">
                <g
                  id="Rectangle_1-3"
                  data-name="Rectangle 1"
                  transform="translate(818 1259)"
                  fill="#392d2f"
                  stroke="#392d2f"
                  strokeWidth={2}
                >
                  <rect width={210} height={58} stroke="none" />
                  <rect x={1} y={1} width={208} height={56} fill="none" />
                </g>
                <text
                  id="System_"
                  data-name="System
            "
                  transform="translate(910 1300)"
                  fill="#fcf2e2"
                  fontSize={37}
                  fontFamily="Helvetica"
                >
                  <tspan x="-50.387" y="0">Meniu</tspan>
                  <tspan x={0} y={44} />
                </text>
                <g
                  id="Polygon_1"
                  data-name="Polygon 1"
                  transform="translate(1015.5 1271.5) rotate(90)"
                  fill="#fcf2e2"
                >
                  <path
                    d="M 31.25 27 L 1.75000011920929 27 L 16.5 1.969697237014771 L 31.25 27 Z"
                    stroke="none"
                  />
                  <path
                    d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z"
                    stroke="none"
                    fill="#fcf2e2"
                  />
                </g>
              </g>
              <AnimatePresence initial={false}>
                {mouseover && (
                  <motion.g
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 }
                  }}
                  transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <g
                      id="Rectangle_11"
                      data-name="Rectangle 11"
                      transform="translate(1063 1259)"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={302} height={58} stroke="none" />
                      <rect x={1} y={1} width={300} height={56} fill="none" />
                    </g>
                    <g
                      id="Rectangle_56"
                      data-name="Rectangle 56"
                      transform="translate(1063 1315)"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={302} height={58} stroke="none" />
                      <rect x={1} y={1} width={300} height={56} fill="none" />
                    </g>
                    <g
                      id="Rectangle_57"
                      data-name="Rectangle 57"
                      transform="translate(1063 1371)"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                      onClick={() => setShow68(true)}
                      style={{cursor: 'pointer'}}
                    >
                      <rect width={302} height={58} stroke="none" />
                      <rect x={1} y={1} width={300} height={56} fill="none" />
                    </g>
                    <text
                      id="Užmigdyti"
                      transform="translate(1214 1410)"
                      fill="#392d2f"
                      fontSize={37}
                      fontFamily="Helvetica"
                      onClick={() => setShow68(true)}
                      style={{cursor: 'pointer'}}
                    >
                      <tspan x="-81.208" y={0}>
                        Užmigdyti
                      </tspan>
                    </text>
                    <text
                      id="Perkrauti_"
                      data-name="Perkrauti…"
                      transform="translate(1214 1356)"
                      fill="#392d2f"
                      fontSize={37}
                      fontFamily="Helvetica"
                      opacity="0.2"
                    >
                      <tspan x="-92.527" y={0}>
                        Perkrauti…
                      </tspan>
                    </text>
                    <text
                      id="Išjungti_"
                      data-name="Išjungti…"
                      transform="translate(1214 1300)"
                      fill="#392d2f"
                      fontSize={37}
                      fontFamily="Helvetica"
                      opacity="0.2"
                    >
                      <tspan x="-77.116" y={0}>
                        Išjungti…
                      </tspan>
                    </text>
                  </motion.g>
                )}
              </AnimatePresence>
            </motion.g>
            {false && <motion.g
              onHoverStart={() => setMouseOver(true)}
              onHoverEnd={() => setMouseOver(false)}
              style={{cursor: 'pointer'}}>
              {mouseover && (
                <React.Fragment>
                <g onClick={() => setShow68(true)}>
                  <g
                    id="Rectangle_1-3"
                    data-name="Rectangle 1"
                    transform="translate(818 1259)"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={210} height={58} stroke="none" />
                    <rect x={1} y={1} width={208} height={56} fill="none" />
                  </g>
                  <g
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    transform="translate(1027 1259)"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={302} height={58} stroke="none" />
                    <rect x={1} y={1} width={300} height={56} fill="none" />
                  </g>
                  <text
                    id="System_"
                    data-name="System"
                    transform="translate(910 1300)"
                    fill="#fcf2e2"
                    fontSize={37}
                    fontFamily="Helvetica"
                  >
                    <tspan x="-50.387" y="0">Meniu</tspan>
                    <tspan fontFamily="Graphik-Regular, Graphik">
                      <tspan x={0} y={44} />
                    </tspan>
                  </text>
                  <g
                    id="Polygon_1"
                    data-name="Polygon 1"
                    transform="translate(1015.5 1271.5) rotate(90)"
                    fill="none"
                  >
                    <path d="M16.5,0,33,28H0Z" stroke="none" />
                    <path
                      d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z"
                      stroke="none"
                      fill="#fcf2e2"
                    />
                  </g>
                  <text
                    id="Sleep"
                    transform="translate(1178 1300)"
                    fill="#392d2f"
                    fontSize={37}
                    fontFamily="Helvetica"
                  >
                    <tspan x="-47.316" y={0}>
                      Sleep
                    </tspan>
                  </text>
                  </g>
                </React.Fragment>
              )}
              {!mouseover && (
                <React.Fragment>
                  <g
                    id="Rectangle_1-3"
                    data-name="Rectangle 1"
                    transform="translate(818 1259)"
                    fill="#392d2f"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <rect width={210} height={58} stroke="none" />
                    <rect x={1} y={1} width={208} height={56} fill="none" />
                  </g>
                  <text
                    id="System_"
                    data-name="System"
                    transform="translate(910 1300)"
                    fill="#fcf2e2"
                    fontSize={37}
                    fontFamily="Helvetica"
                  >
                    <tspan x="-50.387" y="0">Meniu</tspan>
                    <tspan fontFamily="Graphik-Regular, Graphik">
                      <tspan x={0} y={44} />
                    </tspan>
                  </text>
                  <g id="Polygon_1" data-name="Polygon 1" transform="translate(1015.5 1271.5) rotate(90)" fill="none">
                    <path d="M16.5,0,33,28H0Z" stroke="none"/>
                    <path d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z" stroke="none" fill="#fcf2e2"/>
                  </g>
                </React.Fragment>
              )}
            </motion.g>}
          </g>
        </svg>
      )}
    </React.Fragment>
  )
}
