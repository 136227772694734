import React from "react";
import {motion, transform} from "framer-motion"
import {NextContext} from '../components/Context';

export default ({onNext, ...props}) => {
  const nextContext = React.useContext(NextContext);

  const [showSky, setShowSky] = React.useState(false);
  const [moonClicked, setMoonClicked] = React.useState(false);
  const [size, setSize] = React.useState(2160)
  const [windowStack, setWindowStack] = React.useState([
    '#Group_57',
    '#Group_63',
    '#Group_62'
  ]);

  const setFocusedWindow = (win) => {
    const newStack = windowStack.filter(name => name !== win);
    newStack.push(win);
    setWindowStack(newStack);

    if (!showSky && win === '#Group_57') {
      setShowSky(true)
    }

    if (showSky && !moonClicked && win === '#Group_62') {
      setMoonClicked(true);
    }
  }


  const [showEnd, setShowEnd] = React.useState(false);
  const [ellipse, updateEllipse] = React.useState(1);
  const path = React.useRef();
  const svg = React.useRef();
  const endRef = React.useRef();
  const [dragState, setDragState] = React.useState(false);
  const [sun, setSun] = React.useState({x: 0, y: 0})
  const [moon, setMoon] = React.useState({x: 0, y: 0})
  const [color, setColor] = React.useState(1)
  const [starsOpacity, setStarsOpacity] = React.useState(0.661);


  React.useEffect(
    () => {
      if (showSky && !showEnd) {
        setSize(3240)
      }
    },
    [showEnd, showSky]
  )

  React.useEffect(
    () => {
      if (path.current && showSky) {
        setSun(path.current.getPointAtLength(transform(200, [200, 1730], [3100, 5050])))
        setMoon(path.current.getPointAtLength(transform((1730 + 200) / 2, [200, 1730], [3100, 5050])))
      }
    },
    [path, showSky]
  )

  const handleMouseDown = (body) => () => setDragState(body);
  const handleMouseup = () => setDragState(false);
  const handleMouseMove = (event) => {
    if (!dragState) {
      return;
    }
    const p = svg.current.createSVGPoint();

    p.x = event.pageX;
    p.y = event.pageY;

    const currentScr = p.matrixTransform(svg.current.getScreenCTM().inverse()).x;

    if (dragState === 'moon') {
      if (((1730 + 200) / 2) > currentScr) {
        return;
      }
      setSun(path.current.getPointAtLength(transform(currentScr - (((1730 + 200) / 2) - 200), [200, 1730], [3100, 5200])));
      setMoon(path.current.getPointAtLength(transform(currentScr, [200, 1730], [3100, 5200])));
      setColor(transform(currentScr- (((1730 + 200) / 2) - 200), [200, 1730], [1, 0]));
      setStarsOpacity(transform(currentScr- (((1730 + 200) / 2) - 200), [200, 1730 / 2], [0.661, 0]))
    } else {
      setSun(path.current.getPointAtLength(transform(currentScr, [200, 1730], [3100, 5200])));
      setMoon(path.current.getPointAtLength(transform(currentScr + (((1730 + 200) / 2) - 200), [200, 1730], [3100, 5200])));
      setColor(transform(currentScr, [200, 1730], [1, 0]));
      setStarsOpacity(transform(currentScr, [200, 1730 / 2], [0.661, 0]))
    }
  }

  React.useEffect(
    () => {
      if (!showEnd && sun.x > 1630) {
        setShowEnd(true);
        // setHeight('100%')
      }
    },
    [showEnd, sun]
  )

  React.useEffect(
    () => {
      if (showEnd) {
        setSize(4320)
      }
    },
    [showEnd, showSky]
  )

  React.useEffect(
    () => {
      if (endRef.current && showEnd) {
        return nextContext.onRegister(endRef.current);
      } else if (path.current && showSky) {
        return nextContext.onRegister(path.current);
      }

    },
    [endRef.current, showSky, showEnd] // eslint-disable-line
  )

  const [showAlgoWindow, setShowAlgoWindow] = React.useState(true);

  return (
    <React.Fragment>
      {(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} style={{...props.style, height: undefined}} viewBox={`0 0 1920 ${size}`} onMouseUp={handleMouseup} ref={svg}>
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_9"
                data-name="Rectangle 9"
                width={1186}
                height={723}
                transform="translate(332 214)"
                fill="#fff"
                stroke="#707070"
                strokeWidth={1}
              />
            </clipPath>
            <pattern
              id="pattern"
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              viewBox="0 0 1280 720"
            >
              <image
                width={1280}
                height={720}
                xlinkHref="Web%201920%20-%2059-image20.png"
              />
            </pattern>
            <clipPath id="clip-path-2">
              <rect
                id="Rectangle_51"
                data-name="Rectangle 51"
                width={1602}
                height={954}
                transform="translate(159 2191)"
                fill="#fcf2e2"
                stroke="#392d2f"
                strokeWidth={2}
              />
            </clipPath>
            <pattern
              id="pattern-3"
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              viewBox="0 0 1280 720"
            >
              <image
                width={1280}
                height={720}
                xlinkHref="Web%201920%20-%2059-image3.png"
              />
            </pattern>
            <clipPath id="clip-Web_1920_59">
              <rect width={1920} height={size} />
            </clipPath>
          </defs>
          <g
            id="Web_1920_59"
            data-name="Web 1920 – 59"
            clipPath="url(#clip-Web_1920_59)"
          >
            <rect width={1920} height={size} fill="#fcf2e2" />
            <g id="Group_116" data-name="Group 116" transform="translate(2093 2747)">
              <g id="Group_53-2" data-name="Group 53" transform="translate(-1934 -556)">
                <g id="Group_50-2" data-name="Group 50" transform="translate(0 0)">
                  <g id="Group_42-2" data-name="Group 42">
                    <g
                      id="Rectangle_1-3"
                      data-name="Rectangle 1"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1602} height={952} stroke="none" />
                      <rect x={1} y={1} width={1600} height={950} fill="none" />
                    </g>
                  </g>
                  <g id="Group_43-2" data-name="Group 43">
                    <g
                      id="Rectangle_1-4"
                      data-name="Rectangle 1"
                      fill="#392d2f"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1602} height={45} stroke="none" />
                      <rect x={1} y={1} width={1600} height={43} fill="none" />
                    </g>
                  </g>
                  <circle
                    id="Ellipse_3-2"
                    data-name="Ellipse 3"
                    cx={10}
                    cy={10}
                    r={10}
                    transform="translate(22 14)"
                    fill="#ffd7e0"
                  />
                  <circle
                    id="Ellipse_4-2"
                    data-name="Ellipse 4"
                    cx={10}
                    cy={10}
                    r={10}
                    transform="translate(58 14)"
                    fill="#d1fff1"
                  />
                  <circle
                    id="Ellipse_5-2"
                    data-name="Ellipse 5"
                    cx={10}
                    cy={10}
                    r={10}
                    transform="translate(94 14)"
                    fill="#fcf2e2"
                  />
                </g>
              </g>
            </g>
            {[0, 1, 2].map((i) => {
              if (windowStack.indexOf('#Group_57') === i) {
                return (
                  <g id="Group_57" data-name="Group 57" transform="translate(437 394)" onClick={() => setFocusedWindow('#Group_57')}>
                    <g id="Group_53-5" data-name="Group 53">
                      <g
                        id="Group_50-5"
                        data-name="Group 50"
                        transform="translate(-190.102 -115.615)"
                      >
                        <g
                          id="Group_42-5"
                          data-name="Group 42"
                          transform="translate(295.102 378.615)"
                        >
                          <g
                            id="Rectangle_1-9"
                            data-name="Rectangle 1"
                            fill="#ffd7e0"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <rect width={1208} height={783} stroke="none" />
                            <rect x={1} y={1} width={1206} height={781} fill="none" />
                          </g>
                        </g>
                        <g
                          id="Group_43-5"
                          data-name="Group 43"
                          transform="translate(295.102 378.615)"
                        >
                          <g
                            id="Rectangle_1-10"
                            data-name="Rectangle 1"
                            fill="#392d2f"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <rect width={1208} height={45} stroke="none" />
                            <rect x={1} y={1} width={1206} height={43} fill="none" />
                          </g>
                        </g>
                        <g
                          id="Ellipse_3-5"
                          data-name="Ellipse 3"
                          transform="translate(317.102 392.615)"
                          fill="#ffd7e0"
                          stroke="#392d2f"
                          strokeWidth={2}
                        >
                          <circle cx={10} cy={10} r={10} stroke="none" />
                          <circle cx={10} cy={10} r={9} fill="none" />
                        </g>
                        <g
                          id="Ellipse_4-5"
                          data-name="Ellipse 4"
                          transform="translate(353.102 392.615)"
                          fill="#d1fff1"
                          stroke="#392d2f"
                          strokeWidth={2}
                        >
                          <circle cx={10} cy={10} r={10} stroke="none" />
                          <circle cx={10} cy={10} r={9} fill="none" />
                        </g>
                        <g
                          id="Ellipse_5-5"
                          data-name="Ellipse 5"
                          transform="translate(389.102 392.615)"
                          fill="#fcf2e2"
                          stroke="#392d2f"
                          strokeWidth={2}
                        >
                          <circle cx={10} cy={10} r={10} stroke="none" />
                          <circle cx={10} cy={10} r={9} fill="none" />
                        </g>
                      </g>
                    </g>
                    <text
                      id="_Algoritmas_padarė_du_didžiulius_šviesulius_didesnįjį_šviesulį_dienai_valdyti_ir_mažesnįjį_šviesulį_nakčiai_valdyti."
                      data-name=" Algoritmas padarė du didžiulius šviesulius – didesnįjį šviesulį dienai valdyti ir mažesnįjį šviesulį nakčiai valdyti."
                      transform="translate(149.5 362.5)"
                      fill="#392d2f"
                      fontSize={100}
                      fontFamily="Voyage-Regular, Voyage"
                    >
                      <tspan x="108.65" y={102} xmlSpace="preserve">
                        {" "}
                        Algoritmas padarė du{" "}
                      </tspan>
                      <tspan x="138.55" y={222}>
                        didžiulius šviesulius –{" "}
                      </tspan>
                      <tspan x="102.4" y={342}>
                        didesnįjį šviesulį dienai{" "}
                      </tspan>
                      <tspan x="40.65" y={462}>
                        valdyti ir mažesnįjį šviesulį{" "}
                      </tspan>
                      <tspan x="259.9" y={582}>
                        nakčiai valdyti.
                      </tspan>
                    </text>
                  </g>
                )
              } else if (windowStack.indexOf('#Group_63') === i) {
                return (
                  <g id="Group_63" data-name="Group 63" onClick={() => setFocusedWindow('#Group_63')}>
                    <g id="Group_58" data-name="Group 58" transform="translate(218 -102)">
                      <g id="Group_53-3" data-name="Group 53">
                        <g
                          id="Group_50-3"
                          data-name="Group 50"
                          transform="translate(-190.102 -115.615)"
                        >
                          <g
                            id="Group_42-3"
                            data-name="Group 42"
                            transform="translate(295.102 378.615)"
                          >
                            <g
                              id="Rectangle_1-5"
                              data-name="Rectangle 1"
                              fill="#fcf2e2"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={1208} height={783} stroke="none" />
                              <rect x={1} y={1} width={1206} height={781} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Group_43-3"
                            data-name="Group 43"
                            transform="translate(295.102 378.615)"
                          >
                            <g
                              id="Rectangle_1-6"
                              data-name="Rectangle 1"
                              fill="#392d2f"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={1208} height={45} stroke="none" />
                              <rect x={1} y={1} width={1206} height={43} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Ellipse_3-3"
                            data-name="Ellipse 3"
                            transform="translate(317.102 392.615)"
                            fill="#ffd7e0"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_4-3"
                            data-name="Ellipse 4"
                            transform="translate(353.102 392.615)"
                            fill="#d1fff1"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_5-3"
                            data-name="Ellipse 5"
                            transform="translate(389.102 392.615)"
                            fill="#fcf2e2"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_61" data-name="Group 61" transform="translate(2)">
                      <text
                        id="Saulė"
                        transform="translate(884 194)"
                        fill="#fcf2e2"
                        fontSize={30}
                        fontFamily="Helvetica"
                      >
                        <tspan x="-38.364" y={0}>
                          Saulė
                        </tspan>
                      </text>
                      <g
                        id="Mask_Group_14"
                        data-name="Mask Group 14"
                        clipPath="url(#clip-path)"
                      >
                        <image
                          id="saule_mapped"
                          data-name="saule mapped"
                          width={1473}
                          height={829}
                          transform="translate(93 161)"
                          xlinkHref="Web%201920%20-%2059-image0.png"
                        />
                      </g>
                    </g>
                  </g>
                )
              } else if (windowStack.indexOf('#Group_62') === i) {
                return (
                  <g key="Group_62" data-name="Group 62" onClick={() => setFocusedWindow('#Group_62')}>
                    <g id="Group_59" data-name="Group 59" transform="translate(218 817)">
                      <g id="Group_53-4" data-name="Group 53">
                        <g
                          id="Group_50-4"
                          data-name="Group 50"
                          transform="translate(-190.102 -115.615)"
                        >
                          <g
                            id="Group_42-4"
                            data-name="Group 42"
                            transform="translate(295.102 378.615)"
                          >
                            <g
                              id="Rectangle_1-7"
                              data-name="Rectangle 1"
                              fill="#392d2f"
                              stroke="#fcf2e2"
                              strokeWidth={2}
                            >
                              <rect width={1208} height={783} stroke="none" />
                              <rect x={1} y={1} width={1206} height={781} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Group_43-4"
                            data-name="Group 43"
                            transform="translate(295.102 378.615)"
                          >
                            <g
                              id="Rectangle_1-8"
                              data-name="Rectangle 1"
                              fill="#392d2f"
                              stroke="#fcf2e2"
                              strokeWidth={2}
                            >
                              <rect width={1208} height={45} stroke="none" />
                              <rect x={1} y={1} width={1206} height={43} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Ellipse_3-4"
                            data-name="Ellipse 3"
                            transform="translate(317.102 392.615)"
                            fill="#ffd7e0"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_4-4"
                            data-name="Ellipse 4"
                            transform="translate(353.102 392.615)"
                            fill="#d1fff1"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_5-4"
                            data-name="Ellipse 5"
                            transform="translate(389.102 392.615)"
                            fill="#fcf2e2"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                        </g>
                      </g>
                    </g>
                    <rect
                      id="menulis"
                      width={600}
                      height={338}
                      transform="translate(591 1319)"
                      fill="url(#pattern)"
                    />
                    <text
                      id="Mėnulis"
                      transform="translate(927 1114)"
                      fill="#fcf2e2"
                      fontSize={30}
                      fontFamily="Helvetica"
                    >
                      <tspan x="-51.687" y={0}>
                        Mėnulis
                      </tspan>
                    </text>
                  </g>
                )
              }
            })}
            {showSky && (
              <g
                id="Mask_Group_23"
                data-name="Mask Group 23"
                clipPath="url(#clip-path-2)"
                onMouseMove={handleMouseMove}
                transform="translate(0 -4)"
                onClick={() => setShowAlgoWindow(false)}
              >
                <g id="Group_117" data-name="Group 117" transform="translate(0 -2)">
                  <g
                    id="Group_66"
                    data-name="Group 66"
                    transform="translate(159.218 2242.407)"
                  >
                    <g id="Group_42-6" data-name="Group 42" transform="translate(0)">
                      <g
                        id="Rectangle_1-12"
                        data-name="Rectangle 1"
                        transform="translate(-0.218 -0.407)"
                        fill="#392d2f"
                        stroke="#392d2f"
                        strokeWidth={2}
                        opacity={color}
                      >
                        <rect width={1602} height={910} stroke="none" />
                        <rect x={1} y={1} width={1600} height={899} fill="none" />
                      </g>
                      <g
                        id="Rectangle_1-12"
                        data-name="Rectangle 1"
                        transform="translate(-0.218 -0.407)"
                        fill="#897578"
                        stroke="#897578"
                        strokeWidth={2}
                        opacity={1 - color}
                      >
                        <rect width={1602} height={910} stroke="none" />
                        <rect x={1} y={1} width={1600} height={899} fill="none" />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    transform="translate(90 2540)"
                    fill="none"
                    stroke="#d1fff1"
                    strokeWidth={4}
                  >
                    <circle cx={870} cy={870} r={870} stroke="none" />
                    <circle cx={870} cy={870} r={868} fill="none" ref={path} />
                  </g>
                  <g transform={`translate(${834} ${2469})`}>
                    <g transform="translate(-834 0)">
                    <rect
                      id="menulis-2"
                      data-name="menulis"
                      width={253}
                      height={142}
                      transform={`translate(${moon.x} ${moon.y})`}
                      onMouseDown={handleMouseDown('moon')}
                      style={{cursor: 'pointer'}}
                      fill="url(#pattern)"
                    />
                    </g>
                  </g>
                  <g transform={`translate(${834} ${2469})`}>
                    <g transform="translate(-1300 -200)">
                    <image
                      id="menulis-2"
                      data-name="sun"
                      width={1054}
                      height={593}
                      transform={`translate(${sun.x} ${sun.y})`}
                      onMouseDown={handleMouseDown('sun')}
                      style={{cursor: 'pointer'}}
                      xlinkHref="Web%201920%20-%2031-image.png"
                    />
                    </g>
                  </g>
                  <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M829.143,0c457.923,0,829.143,389.52,829.143,870.017s-371.22,870.017-829.143,870.017S0,1350.513,0,870.017,371.22,0,829.143,0Z"
                    transform="translate(130.857 2739.56)"
                    fill="#dfffd7"
                  />
                  <g
                    id="Group_65"
                    data-name="Group 65"
                    transform="translate(225.115 2291.622)"
                    opacity={starsOpacity}
                  >
                    <rect
                      id="saule_balta"
                      data-name="saule balta"
                      width={105}
                      height={59}
                      transform="translate(-0.115 148.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-2"
                      data-name="saule balta"
                      width={52}
                      height={30}
                      transform="translate(193.885 28.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-3"
                      data-name="saule balta"
                      width={52}
                      height={29}
                      transform="translate(147.885 149.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-4"
                      data-name="saule balta"
                      width={53}
                      height={29}
                      transform="translate(658.885 0.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-5"
                      data-name="saule balta"
                      width={53}
                      height={29}
                      transform="translate(446.885 139.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-6"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(1143.885 45.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-7"
                      data-name="saule balta"
                      width={52}
                      height={29}
                      transform="translate(1013.885 141.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-8"
                      data-name="saule balta"
                      width={53}
                      height={30}
                      transform="translate(1408.885 211.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-9"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(1314.885 303.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-10"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(1278.885 140.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-11"
                      data-name="saule balta"
                      width={29}
                      height={16}
                      transform="translate(1432.885 6.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-12"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(42.885 343.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-13"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(100.885 62.378)"
                      fill="url(#pattern-3)"
                    />
                    <rect
                      id="saule_balta-14"
                      data-name="saule balta"
                      width={28}
                      height={16}
                      transform="translate(912.885 55.378)"
                      fill="url(#pattern-3)"
                    />
                  </g>
                </g>
              </g>
            )}
            {showAlgoWindow && (
              <g id="Group_60" data-name="Group 60" transform="translate(133 2030)">
                <g id="Group_53" data-name="Group 53">
                  <g id="Group_50" data-name="Group 50" transform="translate(105 263)">
                    <g id="Group_42" data-name="Group 42">
                      <g
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        fill="#fcf2e2"
                        stroke="#fcf2e2"
                        strokeWidth={2}
                      >
                        <rect width={1063} height={783} stroke="none" />
                        <rect x={1} y={1} width={1061} height={781} fill="none" />
                      </g>
                    </g>
                    <g id="Group_43" data-name="Group 43">
                      <g
                        id="Rectangle_1-2"
                        data-name="Rectangle 1"
                        fill="#392d2f"
                        stroke="#fcf2e2"
                        strokeWidth={2}
                      >
                        <rect width={1063} height={45} stroke="none" />
                        <rect x={1} y={1} width={1061} height={43} fill="none" />
                      </g>
                    </g>
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx={10}
                      cy={10}
                      r={10}
                      transform="translate(22 14)"
                      fill="#ffd7e0"
                    />
                    <circle
                      id="Ellipse_4"
                      data-name="Ellipse 4"
                      cx={10}
                      cy={10}
                      r={10}
                      transform="translate(58 14)"
                      fill="#d1fff1"
                    />
                    <circle
                      id="Ellipse_5"
                      data-name="Ellipse 5"
                      cx={10}
                      cy={10}
                      r={10}
                      transform="translate(94 14)"
                      fill="#fcf2e2"
                    />
                  </g>
                </g>
                <text
                  id="Algoritmas_sudėjo_juos_į_dangaus_skliautą_šviesti_žemei_valdyti_dienai_bei_nakčiai_ir_atskirti_šviesai_nuo_tamsos."
                  data-name="Algoritmas sudėjo juos į dangaus skliautą šviesti žemei, valdyti dienai bei nakčiai ir atskirti šviesai nuo tamsos."
                  transform="translate(149.5 362.5)"
                  fill="#392d2f"
                  fontSize={100}
                  fontFamily="Voyage-Regular, Voyage"
                >
                  <tspan x="4.7" y={102}>
                    Algoritmas sudėjo juos į{" "}
                  </tspan>
                  <tspan x="17.1" y={222}>
                    dangaus skliautą šviesti{" "}
                  </tspan>
                  <tspan x="69.1" y={342}>
                    žemei, valdyti dienai{" "}
                  </tspan>
                  <tspan x="88.45" y={462}>
                    bei nakčiai ir atskirti{" "}
                  </tspan>
                  <tspan x="94.45" y={582}>
                    šviesai nuo tamsos.
                  </tspan>
                </text>
              </g>
            )}
            {showEnd && (
              <React.Fragment>
                <rect
                  id="Rectangle_55"
                  data-name="Rectangle 55"
                  width={1920}
                  height={1080}
                  transform="translate(0 3240)"
                  fill="#392d2f"
                />
                <text
                  id="Atėjo_vakaras_ir_išaušo_rytas._Ketvirtoji_diena"
                  data-name="Atėjo vakaras ir išaušo rytas.Ketvirtoji diena"
                  transform="translate(238.5 3638.5)"
                  fill="#fcf2e2"
                  fontSize={100}
                  fontFamily="Voyage-Regular, Voyage"
                  ref={endRef}
                >
                  <tspan x="176.45" y={102}>
                    Atėjo vakaras ir išaušo rytas.
                  </tspan>
                  <tspan x="424.55" y={222}>
                    Ketvirtoji diena
                  </tspan>
                </text>
                <g id="day_count" data-name="day count" transform="translate(42 -747)">
                  <circle
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(544 4876)"
                    fill="#fcf2e2"
                  />
                  <circle
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(663 4876)"
                    fill="#fcf2e2"
                  />
                  <circle
                    id="Ellipse_20"
                    data-name="Ellipse 20"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(782 4876)"
                    fill="#fcf2e2"
                  />
                  <circle
                    id="Ellipse_21"
                    data-name="Ellipse 21"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(901 4876)"
                    fill="#fcf2e2"
                  />
                  <g
                    id="Ellipse_23"
                    data-name="Ellipse 23"
                    transform="translate(1138 4876)"
                    fill="none"
                    stroke="#fcf2e2"
                    strokeWidth={2}
                  >
                    <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                    <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                  </g>
                  <g
                    id="Ellipse_24"
                    data-name="Ellipse 24"
                    transform="translate(1257 4876)"
                    fill="none"
                    stroke="#fcf2e2"
                    strokeWidth={2}
                  >
                    <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                    <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                  </g>
                  <motion.g
                    id="Ellipse_19-2"
                    data-name="Ellipse 19"
                    transform="translate(1006 4863)"
                    fill="#392d2f"
                    stroke="#fcf2e2"
                    strokeWidth={2}
                    onHoverStart={() => updateEllipse(2)}
                    onHoverEnd={() => updateEllipse(1)}
                    style={{cursor: 'pointer'}}
                    onClick={onNext}
                  >
                    <circle cx="30.5" cy="30.5" r="30.5" stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
                    <circle cx="30.5" cy="30.5" r="29.5" fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
                  </motion.g>
                </g>
              </React.Fragment>
            )}
          </g>
        </svg>
      )}
    </React.Fragment>
  )
}
