import React from 'react';
import {motion, AnimatePresence} from "framer-motion"
import Scene8 from './scenes/8';
import Scene10 from './scenes/10';
import Scene18 from './scenes/18'
import Scene7 from './scenes/7';
import Scene19 from './scenes/19'
import Scene43 from './scenes/43'
import ScenePenktojiDiena from './scenes/penktojiDiena'
import Scene6DienaAntrasVariantas from './scenes/6DienaAntrasVariantas'
import Scene63 from './scenes/63';
import {Howl} from 'howler';
import {NextContext} from './components/Context';
import isElementInViewport from './utils/isInViewport';


const IntroPage = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [finalScene, setFinalScene] = React.useState(false);

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', height: finalScene ? '200vh' : '100vh', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene43 style={{width: '100vw', height: '100vh'}} onFinalScene={() => setFinalScene(true)} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}


const FirstPage = ({onNext, shouldBeUnmounting, onUnmount, props}) => {

  return (
    <AnimatePresence initial={false} onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          key="first"
          initial={{ y: 0 }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          style={{width: '100vw', minHeight: '100vh', height: 'auto', position: 'absolute'}}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene7 style={{width: '100vw', height: '100%'}} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const ThirdPage = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [finalScene, setFinalScene] = React.useState(false);

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', minHeight: finalScene ? '200vh' : '100vh', height: 'auto', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene8 style={{width: '100vw', height: '100vh'}} onFinalScene={() => setFinalScene(true)} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const FourthPage = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [finalScene, setFinalScene] = React.useState(false);

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', minHeight: '100vh', height: 'auto', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene10 style={{width: '100vw', height: '100%'}} onFinalScene={() => setFinalScene(true)} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const FifthPage = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [height, setHeight] = React.useState('100vh');

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', minHeight: '100%', height: 'auto', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene18 style={{width: '100vw', height: height}} setHeight={setHeight} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Thursday = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [height, setHeight] = React.useState('100vh');

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', minHeight: height, height: 'auto', display: 'flex', flexDirection: 'column'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene19 style={{width: '100vw', height: height}} setHeight={setHeight} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Friday = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [doubleHeight, setFinalScene] = React.useState(false);

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', height: doubleHeight ? '200vh' : '100vh', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <ScenePenktojiDiena style={{width: '100vw', height: doubleHeight ? '200vh' : '100vh'}} doubleHeight={setFinalScene} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Saturday = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [height, setHeight] = React.useState('100vh');

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', minHeight: height, height: 'auto', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene6DienaAntrasVariantas style={{width: '100vw', height}} setHeight={setHeight} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Sunday = ({onNext, shouldBeUnmounting, onUnmount, props}) => {
  const [doubleHeight, setFinalScene] = React.useState(false);

  return (
    <AnimatePresence onExitComplete={onUnmount}>
      {!shouldBeUnmounting && (
        <motion.div
          style={{width: '100vw', height: doubleHeight ? '200vh' : '143vh', position: 'absolute'}}
          initial={{ y: (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}
          animate={{ y: 0 }}
          transition={{
            y: {duration: 0.3, ease: "easeOut"}
          }}
          exit={{ y: -(window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight) }}>
          <Scene63 style={{width: '100vw', height: doubleHeight ? '200vh' : '143vh'}} doubleHeight={setFinalScene} onNext={onNext} {...props} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

function getDocHeight() {
    var D = document;
    return Math.max(
        Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
        Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
        Math.max(D.body.clientHeight, D.documentElement.clientHeight)
    );
}

class App extends React.Component {
  state = {
    loading: [],
    page: 0,
    toBeMounted: [],
    ctx: {
      show: false,
      element: null
    }
  }

  sounds = {};

  componentDidMount () {
    const sound = new Howl({
      src: ['/fonas.mp3'],
      autoplay: true,
      loop: true,
      volume: 0.10
    });

    sound.play();

    [
      ['skliautas', '/Skliautas.mp3'],
      ['tezeldina', '/Tezeldina.mp3'],
      ['sviesuliai', '/Sviesuliai.mp3'],
      ['padarykime_zmogu', '/Padarykime zmogu.mp3'],
      ['tebunie_sviesa', '/Tebunie sviesa.mp3'],
      ['pauksciai_zuvys', '/Pauksciai zuvys.mp3'],
      ['gyvunus', '/Gyvunus.mp3'],
      ['varna', '/varna.mp3'],
      ['gandras', '/gandras.mp3'],
      ['vista', '/vista.mp3'],
      ['balandis', '/balandis.mp3']
    ].forEach(([key, val]) => {
      this.setState((state) => ({...state, loading: [...state.loading, key]}));

      this.sounds[key] = new Howl({
        src: [val],
        volume: 0.5,
        onload: () => this.setState((state) => ({...state, loading: state.loading.filter(s => s !== key)}))
      })
    })

    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (!this.state.ctx.element) {
      return;
    }

    this.setState((state) => {
      if (!state.ctx.element) {
        return state;
      }
      return {
        ...state,
        ctx: {
          ...state.ctx,
          show: !isElementInViewport(state.ctx.element)
        }
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      window.scrollTo(0, 0);
    }
  }

  handleNextClick = () => {
    if (this.state.ctx.toElement) {
      const elementRect = this.state.ctx.element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - (window.innerHeight / 2);

      window.scrollTo({
        top: middle,
        behavior: 'smooth'
      })
    } else {
      window.scrollTo({
        top: getDocHeight(),
        behavior: 'smooth'
      })
    }
  }

  render () {
    const p = {sounds: this.sounds};

    return (
      <NextContext.Provider value={{
        ...this.state.ctx,
        onRegister: (element, toElement = false) => {
          this.setState({
            ctx: {
              ...this.state.ctx,
              element,
              toElement,
              show: !isElementInViewport(element)
            }
          });

          return () => {
            this.setState(({ctx}) => {
              if (ctx.element !== element) {
                return;
              }

              return {ctx: {...ctx, element: null, show: false, toElement: false}}
            })
          }
        }
      }}>
        <NextContext.Consumer>
          {(next) => next.show && (
            <svg xmlns="http://www.w3.org/2000/svg" width="210" height="58" viewBox="0 0 210 58"
              style={{
                position: 'fixed',
                bottom: 10,
                cursor: 'pointer',
                right: 10,
                zIndex: 600,
                height: '40px',
                width: 'auto'
              }}
              onClick={this.handleNextClick}>
              <g id="Group_110" data-name="Group 110" transform="translate(-818 -1259)">
                <g id="Component_35_2" data-name="Component 35 – 2" transform="translate(818 1259)">
                  <g id="Rectangle_1" data-name="Rectangle 1" fill="#fcf2e2" stroke="#392d2f" stroke-width="2">
                    <rect width="210" height="58" stroke="none"/>
                    <rect x="1" y="1" width="208" height="56" fill="none"/>
                  </g>
                  <text id="Toliau" transform="translate(92 41)" fill="#392d2f" font-size="37" font-family="Helvetica"><tspan x="-48.337" y="0">Toliau</tspan></text>
                </g>
                <g id="Polygon_1" data-name="Polygon 1" transform="translate(1010 1302) rotate(180)" fill="none">
                  <path d="M16.5,0,33,28H0Z" stroke="none"/>
                  <path d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z" stroke="none" fill="#392d2f"/>
                </g>
              </g>
            </svg>
          )}
        </NextContext.Consumer>
        {(this.state.page === 0 || this.state.toBeMounted.includes(0)) && (
          <FirstPage
            shouldBeUnmounting={this.state.toBeMounted.includes(0)}
            onNext={() => this.setState({page: 1, toBeMounted: [...this.state.toBeMounted, 0]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 0)})}
            props={p}
          />
        )}
        {(this.state.page === 1 || this.state.toBeMounted.includes(1)) && (
          <ThirdPage
            shouldBeUnmounting={this.state.toBeMounted.includes(1)}
            onNext={() => this.setState({page: 2, toBeMounted: [...this.state.toBeMounted, 1]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 1)})}
            props={p}
          />
        )}
        {(this.state.page === 2 || this.state.toBeMounted.includes(2)) && (
          <FourthPage
            shouldBeUnmounting={this.state.toBeMounted.includes(2)}
            onNext={() => this.setState({page: 3, toBeMounted: [...this.state.toBeMounted, 2]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 2)})}
            props={p}
          />
        )}
        {(this.state.page === 3 || this.state.toBeMounted.includes(3)) && (
          <FifthPage
            shouldBeUnmounting={this.state.toBeMounted.includes(3)}
            onNext={() => this.setState({page: 4, toBeMounted: [...this.state.toBeMounted, 3]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 3)})}
            props={p}
          />
        )}
        {(this.state.page === 4 || this.state.toBeMounted.includes(4)) && (
          <Thursday
            shouldBeUnmounting={this.state.toBeMounted.includes(4)}
            onNext={() => this.setState({page: 5, toBeMounted: [...this.state.toBeMounted, 4]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 4)})}
            props={p}
          />
        )}
        {(this.state.page === 5 || this.state.toBeMounted.includes(5)) && (
          <Friday
            shouldBeUnmounting={this.state.toBeMounted.includes(5)}
            onNext={() => this.setState({page: 6, toBeMounted: [...this.state.toBeMounted, 5]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 5)})}
            props={p}
          />
        )}
        {(this.state.page === 6 || this.state.toBeMounted.includes(6)) && (
          <Saturday
            shouldBeUnmounting={this.state.toBeMounted.includes(6)}
            onNext={() => this.setState({page: 7, toBeMounted: [...this.state.toBeMounted, 6]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 6)})}
            props={p}
          />
        )}
        {(this.state.page === 7 || this.state.toBeMounted.includes(7)) && (
          <Sunday
            shouldBeUnmounting={this.state.toBeMounted.includes(7)}
            onNext={() => this.setState({page: 0, toBeMounted: [...this.state.toBeMounted, 7]})}
            onUnmount={() => this.setState({toBeMounted: this.state.toBeMounted.filter(i => i !== 7)})}
            props={p}
          />
        )}
      </NextContext.Provider>
    )
  }
}

export default App;
