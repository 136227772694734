import React from "react";
import {motion, transform} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';
import {NextContext} from '../components/Context';

const WebComponent = ({ svgRef, onFinalScene, onNext, ...props }) => {
  const [hue, setHue] = React.useState(0);
  const nextContext = React.useContext(NextContext);
  const [brightness, setBrightness] = React.useState(false);
  const [showBrightness, setShowBrightness] = React.useState(false);
  const [showFinalScene, setShowFinalScene] = React.useState(false);
  const [ellipse, updateEllipse] = React.useState(1);

  React.useEffect(
    () => {
      if (hue >= 860) {
        setShowBrightness(true);
      }
    },
    [hue]
  )

  React.useEffect(
    () => {
      if (brightness >= 860) {
        setShowFinalScene(true);
        onFinalScene();
      }
    },
    [brightness, onFinalScene]
  )

  const [finalScene, setFinalScene] = React.useState(null);
  React.useEffect(() => {
    if (finalScene) {
      return nextContext.onRegister(finalScene);
    }
  }, [finalScene]); // eslint-disable-line

  // hsl(347, 100%, 92%)
  // hsl(350, 12%, 20%)
  //
  // hsl(0, 0%, 100%)
  // hsl(347, 100%, 92%)
  const color = brightness === false
    ? `hsl(${transform(hue, [0, 860], [0, 347])}, ${transform(hue, [0, 860], [100, 100])}%, ${transform(hue, [0, 860], [100, 92])}%)`
    : `hsl(${transform(brightness, [0, 860], [347, 350])}, ${transform(brightness, [0, 860], [100, 12])}%, ${transform(brightness, [0, 860], [92, 20])}%)`;

  return (
    <React.Fragment>
    <BackgroundColor color={showFinalScene ? '#392d2f' : color} />

    <svg viewBox="0 0 1920 1080" ref={svgRef} {...props}>
      <defs>
        <style>
          {
            ".a2,.n2{fill:#fff;}.a2,.b2,.e2,.g2{stroke:#000;stroke-width:2px;}.b2,.h2,.l2{fill:none;}.c2{clip-path:url(#j2);}.d2,.e2{fill:#ffd7e0;}.f2{isolation:isolate;clip-path:url(#a2);}.g2{fill:url(#b2);}.i2{clip-path:url(#h2);}.j2{fill:#0f0f0f;font-size:100px;font-family:Helvetica;}.k2,.m2{stroke:none;}"
          }
        </style>
        <clipPath id="a2">
          <path
            className="a2"
            d="M14.733,0H891.7c8.137,0,14.733,8.382,14.733,18.721s-6.6,18.721-14.733,18.721H14.733C6.6,37.443,0,29.061,0,18.721S6.6,0,14.733,0Z"
            transform="translate(4547 -215)"
          />
        </clipPath>
        <pattern
          id="b2"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox="0 0 979 629"
        >
          <image width={979} height={629} xlinkHref="Web%201920%20-%2011-image.png" />
        </pattern>
        <clipPath id="h2">
          <path
            className="b2"
            d="M14.733,0H891.7c8.137,0,14.733,8.382,14.733,18.721s-6.6,18.721-14.733,18.721H14.733C6.6,37.443,0,29.061,0,18.721S6.6,0,14.733,0Z"
            transform="translate(518.564 704)"
          />
        </clipPath>
        <clipPath id="j2">
          <rect width={1920} height={1080} />
        </clipPath>
      </defs>
      <g id="i2" className="c2">
        <rect width={1920} height={1080} fill={color} />
        <g transform="translate(213.484 120.219)">
          <g transform="translate(282.516 389.781)">
            <g transform="translate(0 0)">
              <g transform="translate(0)">
                <g className="e2">
                  <rect className="k2" width={957} height={80} fill={color} />
                  <rect className="l2" x={1} y={1} width={955} height={78} />
                </g>
              </g>
            </g>
          </g>
        </g>
        {showBrightness && (
          <g transform="translate(213.484 293.219)">
            <g transform="translate(282.516 389.781)">
              <g transform="translate(0 0)">
                <g transform="translate(0)">
                  <g className="e2">
                    <rect className="k2" width={957} height={80} fill={color} />
                    <rect className="l2" x={1} y={1} width={955} height={78} />
                  </g>
                </g>
              </g>
            </g>
          </g>
        )}
        <g className="f2" transform="translate(-4028.436 746)">
          <g className="g2" transform="translate(4503.436 -789)">
            <rect className="k2" width={1104} height={710} />
            <rect className="l2" x={1} y={1} width={1102} height={708} />
          </g>
        </g>
        <g className="h2" transform="translate(518.564 531)">
          <path
            className="k2"
            d="M14.733,0H891.7c8.137,0,14.733,8.382,14.733,18.721s-6.6,18.721-14.733,18.721H14.733C6.6,37.443,0,29.061,0,18.721S6.6,0,14.733,0Z"
          />
          <path
            className="m2"
            d="M 14.73309326171875 1.999992370605469 C 11.42401123046875 1.999992370605469 8.2823486328125 3.676322937011719 5.88690185546875 6.720212936401367 C 4.677001953125 8.257658004760742 3.72430419921875 10.05621337890625 3.055419921875 12.06587982177734 C 2.3551025390625 14.16987991333008 2 16.40910148620605 2 18.721435546875 C 2 21.03376960754395 2.3551025390625 23.27299118041992 3.055419921875 25.37699127197266 C 3.72430419921875 27.38665771484375 4.677001953125 29.18521308898926 5.88690185546875 30.72265815734863 C 8.2823486328125 33.76654815673828 11.42401123046875 35.44287872314453 14.73309326171875 35.44287872314453 L 891.7024536132812 35.44287872314453 C 895.0115356445312 35.44287872314453 898.1531982421875 33.76654815673828 900.5486450195312 30.72265815734863 C 901.758544921875 29.18521308898926 902.7112426757812 27.38665771484375 903.380126953125 25.37699127197266 C 904.0804443359375 23.27299118041992 904.435546875 21.03376960754395 904.435546875 18.721435546875 C 904.435546875 16.40910148620605 904.0804443359375 14.16987991333008 903.380126953125 12.06587982177734 C 902.7112426757812 10.05621337890625 901.758544921875 8.257658004760742 900.5486450195312 6.720212936401367 C 898.1531982421875 3.676322937011719 895.0115356445312 1.999992370605469 891.7024536132812 1.999992370605469 L 593.989013671875 1.999992370605469 L 540.2095947265625 1.999992370605469 L 14.73309326171875 1.999992370605469 M 14.73309326171875 -7.62939453125e-06 L 540.2095947265625 -7.62939453125e-06 L 593.989013671875 -7.62939453125e-06 L 891.7024536132812 -7.62939453125e-06 C 899.83935546875 -7.62939453125e-06 906.435546875 8.381879806518555 906.435546875 18.721435546875 C 906.435546875 29.06099128723145 899.83935546875 37.44287872314453 891.7024536132812 37.44287872314453 L 14.73309326171875 37.44287872314453 C 6.59619140625 37.44287872314453 0 29.06099128723145 0 18.721435546875 C 0 8.381879806518555 6.59619140625 -7.62939453125e-06 14.73309326171875 -7.62939453125e-06 Z"
          />
        </g>
        <g className="i2">
        {showBrightness && (
          <image
            width={1052}
            height={676}
            transform="translate(495 692)"
            xlinkHref="Web%201920%20-%2011-image2.png"
          />
        )}
        </g>
        <g className="h2" transform="translate(518.564 704)">
          <path
            className="k2"
            d="M14.733,0H891.7c8.137,0,14.733,8.382,14.733,18.721s-6.6,18.721-14.733,18.721H14.733C6.6,37.443,0,29.061,0,18.721S6.6,0,14.733,0Z"
          />
          <path
            className="m2"
            d="M 14.73309326171875 1.999992370605469 C 11.42401123046875 1.999992370605469 8.2823486328125 3.676322937011719 5.88690185546875 6.720212936401367 C 4.677001953125 8.257658004760742 3.72430419921875 10.05621337890625 3.055419921875 12.06587982177734 C 2.3551025390625 14.16987991333008 2 16.40910148620605 2 18.721435546875 C 2 21.03376960754395 2.3551025390625 23.27299118041992 3.055419921875 25.37699127197266 C 3.72430419921875 27.38665771484375 4.677001953125 29.18521308898926 5.88690185546875 30.72265815734863 C 8.2823486328125 33.76654815673828 11.42401123046875 35.44287872314453 14.73309326171875 35.44287872314453 L 891.7024536132812 35.44287872314453 C 895.0115356445312 35.44287872314453 898.1531982421875 33.76654815673828 900.5486450195312 30.72265815734863 C 901.758544921875 29.18521308898926 902.7112426757812 27.38665771484375 903.380126953125 25.37699127197266 C 904.0804443359375 23.27299118041992 904.435546875 21.03376960754395 904.435546875 18.721435546875 C 904.435546875 16.40910148620605 904.0804443359375 14.16987991333008 903.380126953125 12.06587982177734 C 902.7112426757812 10.05621337890625 901.758544921875 8.257658004760742 900.5486450195312 6.720212936401367 C 898.1531982421875 3.676322937011719 895.0115356445312 1.999992370605469 891.7024536132812 1.999992370605469 L 593.989013671875 1.999992370605469 L 540.2095947265625 1.999992370605469 L 14.73309326171875 1.999992370605469 M 14.73309326171875 -7.62939453125e-06 L 540.2095947265625 -7.62939453125e-06 L 593.989013671875 -7.62939453125e-06 L 891.7024536132812 -7.62939453125e-06 C 899.83935546875 -7.62939453125e-06 906.435546875 8.381879806518555 906.435546875 18.721435546875 C 906.435546875 29.06099128723145 899.83935546875 37.44287872314453 891.7024536132812 37.44287872314453 L 14.73309326171875 37.44287872314453 C 6.59619140625 37.44287872314453 0 29.06099128723145 0 18.721435546875 C 0 8.381879806518555 6.59619140625 -7.62939453125e-06 14.73309326171875 -7.62939453125e-06 Z"
          />
        </g>
        {showBrightness && !showFinalScene && (
          <text
            id="Algoritmas_atskyrė_šviesą_nuo_tamsos."
            data-name="Algoritmas atskyrė šviesą nuo tamsos."
            transform="translate(261.5 175.5)"
            fill="#0f0f0f"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
          >
            <tspan x="133.5" y={102}>
              Algoritmas atskyrė šviesą nuo{" "}
            </tspan>
            <tspan x="568.45" y={222}>
              tamsos.
            </tspan>
          </text>
        )}
        {!showBrightness && <text id="Ir_šviesa_pasirodė." data-name="Ir šviesa pasirodė." transform="translate(261.5 313.5)" fill="#0f0f0f" fontSize="100" fontFamily="Voyage-Regular, Voyage"><tspan x="366.05" y="102">Ir šviesa pasirodė.</tspan></text>}
        {showFinalScene && (
          <text
            id="Algoritmas_pavadino_šviesą_Diena_o_tamsą_Naktimi."
            data-name="Algoritmas pavadino šviesą Diena, o tamsą Naktimi."
            transform="translate(261.5 175.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
          >
            <tspan x="44.8" y={102}>
              Algoritmas pavadino šviesą Diena,{" "}
            </tspan>
            <tspan x="397.5" y={222}>
              o tamsą Naktimi.
            </tspan>
          </text>
        )}
        <motion.g
          drag={!showBrightness ? 'x' : false}
          style={{cursor: 'pointer'}}
          dragConstraints={{
            left: 0,
            right: 860
          }}
          dragElastic={0}
          onDrag={(e, info) => !showBrightness && setHue(info.point.x)}
        >
          <g className="a2" transform="translate(519 521)">
            <circle className="k2" cx={29} cy={29} r={29} />
            <circle className="l2" cx={29} cy={29} r={28} />
          </g>
        </motion.g>
        {showBrightness && (
          <motion.g
            drag={!showFinalScene ? 'x' : false}
            style={{cursor: 'pointer'}}
            dragConstraints={{
              left: 0,
              right: 860
            }}
            dragElastic={0}
            onDrag={(e, info) => !showFinalScene && setBrightness(info.point.x)}
          >
            <g className="a2" transform="translate(519 694)">
              <circle className="k2" cx={29} cy={29} r={29} />
              <circle className="l2" cx={29} cy={29} r={28} />
            </g>
          </motion.g>
        )}
      </g>
    </svg>
    {showFinalScene && (
      <svg
      viewBox="0 0 1920 1080"
      style={{
        width: '100vw',
        height: '100vh',
        background: '#392D2F'
      }}>
        <g>
          <g>
          <text
            id="Atėjo_vakaras_ir_išaušo_rytas._Pirmoji_diena"
            data-name="Atėjo vakaras ir išaušo rytas. Pirmoji diena"
            transform="translate(261.5 398.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
            ref={setFinalScene}
          >
            <tspan x="176.45" y={77}>
              Atėjo vakaras ir išaušo rytas.
            </tspan>
            <tspan x="458.4" y={197}>
              Pirmoji diena
            </tspan>
          </text>
          </g>

          <g id="day_count" data-name="day count" transform="translate(42 -3987)">
            <circle id="Ellipse_18" data-name="Ellipse 18" cx="17.5" cy="17.5" r="17.5" transform="translate(544 4876)" fill="#fcf2e2"/>

            <motion.g
              key="circle"
              onHoverStart={() => updateEllipse(2)}
              onHoverEnd={() => updateEllipse(1)}
              style={{cursor: 'pointer'}}
              onClick={onNext}
              id="Ellipse_19" data-name="Ellipse 19" transform="translate(663 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle key="circle2" cx="17.5" cy="17.5" r={2 * 17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
              <circle key="circle3" cx="17.5" cy="17.5" r={2 * 16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            </motion.g>
            <g id="Ellipse_20" data-name="Ellipse 20" transform="translate(782 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(901 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(1019 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_23" data-name="Ellipse 23" transform="translate(1138 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_24" data-name="Ellipse 24" transform="translate(1257 4876)" fill="none" stroke="#fcf2e2" strokeWidth="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
          </g>
        </g>
      </svg>
    )}
    </React.Fragment>
  )
};

export default WebComponent;
