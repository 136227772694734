import React from "react";
import {motion} from "framer-motion"
import ModelModal from '../components/modelModal';
import BackgroundColor from '../components/backgroundColor';
import {NextContext} from '../components/Context';

const models = {
  lilly: {
    title: {
      native: 'Lelija',
      latin: '(Lilium candidum)'
    },
    model: {
      path: '/lelija.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/Web%201920%20-%2022-image.png',
    zoom: 0.5
  },
  palm: {
    title: {
      native: 'Palmė',
      latin: '(Arecaceae)'
    },
    model: {
      path: '/palme.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/palme.png',
    zoom: 0.4
  },
  rose: {
    title: {
      native: 'Rožė',
      latin: '(Rosa)'
    },
    model: {
      path: '/roze.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/roze-image-adjusted.png',
    zoom: 0.5
  },
  lime: {
    title: {
      native: 'Liepa',
      latin: '(Tilla)'
    },
    model: {
      path: '/medis.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/roze - 2-image copy.png',
    zoom: 0.3
  },
  fern: {
    title: {
      native: 'Papartis',
      latin: '(Dryopteris)'
    },
    model: {
      path: '/papartis.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/roze - 5-image copy.png',
    zoom: 0.7
  },
  cypress: {
    title: {
      native: 'Kiparisas',
      latin: '(Cupressus)'
    },
    model: {
      path: '/kiparisas.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/kiparisas.png',
    zoom: 0.25
  },
  cherry: {
    title: {
      native: 'Vyšnia',
      latin: '(Cerasus)'
    },
    model: {
      path: '/migdolas.gltf',
      position: {x: 0, y: 0, z: 0}
    },
    background: '/roze - 3-image copy.png',
    zoom: 1
  },
};

export default ({onNext, setHeight, ...props}) => {
  const [focusedWindow, setFocusedWindow] = React.useState('#Group_57');
  const [showEnd, setShowEnd] = React.useState(false);
  const [closeWindow] = React.useState(false);
  const [closeWindowAnother, setCloseWindowAnother] = React.useState(false);
  const [ellipse, updateEllipse] = React.useState(1);
  const [shouldShowModal, setShouldShowModal] = React.useState(false);

  React.useEffect(
    () => {
      if (showEnd) {
        setHeight('100%');
      }
    },
    [showEnd, setHeight]
  )

  const style = {...props.style, height: 'auto', overflow: 'visible'}

  const openModal = (type) => {
    setShowEnd(true);
    setShouldShowModal(models[type]);
  }

  const closeModal = () => {
    setShouldShowModal(false);
    setShowEnd(true);
  }

  React.useEffect(
    () => {
      const listener = () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
}
      window.addEventListener('scroll', listener);
      if (shouldShowModal) {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = window.document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
      } else {
        const body = window.document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }

      return () => window.removeEventListener('scroll', listener);
    },
    [shouldShowModal]
  )

  const nextContext = React.useContext(NextContext);
  const finalScene = React.useRef();
  React.useEffect(() => {
    if (finalScene.current && !shouldShowModal) {
      return nextContext.onRegister(finalScene.current);
    }
  }, [finalScene.current, shouldShowModal]); // eslint-disable-line

  return (
    <React.Fragment>
    {shouldShowModal && (
      <ModelModal
        onClose={() => closeModal()}
        title={shouldShowModal.title}
        model={shouldShowModal.model}
        background={shouldShowModal.background}
        zoom={shouldShowModal.zoom}
      />
    )}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      style={style}
      viewBox={`0 0 1920 ${showEnd ? 2097 + 1080 : 2097}`}
    >
      <BackgroundColor color={showEnd ? '#392d2f' : '#fcf2e2'} />
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width={1071}
            height={2574}
            fill="#fff"
            stroke="#707070"
            strokeWidth={1}
          />
        </clipPath>
        <clipPath id="clip-Web_1920_26">
          <rect width={1920} height={2097} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_26"
        data-name="Web 1920 – 26"
        clipPath="url(#clip-Web_1920_26)"
      >
        <rect width={1920} height={2097} fill="#fcf2e2" />
        {[0, 1].map((index) => {
          if (
            (index === 0 && focusedWindow !== '#Group_55') ||
            (index === 1 && focusedWindow === '#Group_55')
          ) {

            if (closeWindow) {
              return null;
            }

            return (
              <g id="Group_55" key="Group_55" data-name="Group 55" transform="translate(533 -92)">
                <g id="Group_44" data-name="Group 44" transform="translate(195 137)">
                  <g id="Group_42" data-name="Group 42">
                    <g
                      id="Rectangle_1"
                      data-name="Rectangle 1"
                      fill="#dfffd7"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1071} height={1917} stroke="none" />
                      <rect x={1} y={1} width={1069} height={1915} fill="none" />
                    </g>
                  </g>
                  <g id="Group_43" data-name="Group 43" onClick={() => setFocusedWindow('#Group_55')} style={{cursor: 'pointer'}}>
                    <g
                      id="Rectangle_1-2"
                      data-name="Rectangle 1"
                      fill="#392d2f"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1071} height={45} stroke="none" />
                      <rect x={1} y={1} width={1069} height={43} fill="none" />
                    </g>
                  </g>
                  <g
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    transform="translate(22 14)"
                    fill="#ffd7e0"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    transform="translate(58 14)"
                    fill="#d1fff1"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    transform="translate(94 14)"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                </g>
                <g
                  id="Mask_Group_13"
                  data-name="Mask Group 13"
                  transform="translate(195 166)"
                  clipPath="url(#clip-path)"
                  onClick={() => setFocusedWindow('#Group_55')}
                >
                  <image
                    id="Untitled_Artwork_32"
                    data-name="Untitled_Artwork 32"
                    width={1326}
                    height={1926}
                    transform="translate(-62 -39)"
                    xlinkHref="Web%201920%20-%2026-image.png" />
                  {/* Lilly */}
                  <foreignObject x="325" y="240" width="210" height="270">
                    <div onClick={() => openModal('lilly')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Palm */}
                  <foreignObject x="800" y="360" width="140" height="200">
                    <div onClick={() => openModal('palm')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Rose */}
                  <foreignObject x="145" y="620" width="160" height="220">
                    <div onClick={() => openModal('rose')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Lime tree */}
                  <foreignObject x="550" y="880" width="170" height="290">
                    <div onClick={() => openModal('lime')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Fern */}
                  <foreignObject x="690" y="1205" width="290" height="130">
                    <div onClick={() => openModal('fern')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Cypress */}
                  <foreignObject x="65" y="1210" width="80" height="200">
                    <div onClick={() => openModal('cypress')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                  {/* Cherry */}
                  <foreignObject x="430" y="1580" width="220" height="200">
                    <div onClick={() => openModal('cherry')} style={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}></div>
                  </foreignObject>
                </g>
              </g>
            )
          }

          if (closeWindowAnother) {
            return null;
          }

          return (
            <g id="Group_57" key="Group_57" data-name="Group 57" transform="translate(19 -107)" onClick={() => setFocusedWindow('#Group_57')}>
              <g id="Group_53" data-name="Group 53">
                <g
                  id="Group_50"
                  data-name="Group 50"
                  transform="translate(-190.102 -115.615)"
                >
                  <g
                    id="Group_42-2"
                    data-name="Group 42"
                    transform="translate(295.102 378.615)"
                  >
                    <g
                      id="Rectangle_1-3"
                      data-name="Rectangle 1"
                      fill="#fcf2e2"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1208} height={783} stroke="none" />
                      <rect x={1} y={1} width={1206} height={781} fill="none" />
                    </g>
                  </g>
                  <g
                    id="Group_43-2"
                    data-name="Group 43"
                    transform="translate(295.102 378.615)"
                    onClick={() => setFocusedWindow('#Group_57')}
                    style={{cursor: 'pointer'}}
                  >
                    <g
                      id="Rectangle_1-4"
                      data-name="Rectangle 1"
                      fill="#392d2f"
                      stroke="#392d2f"
                      strokeWidth={2}
                    >
                      <rect width={1208} height={45} stroke="none" />
                      <rect x={1} y={1} width={1206} height={43} fill="none" />
                    </g>
                  </g>
                  <g
                    id="Ellipse_3-2"
                    data-name="Ellipse 3"
                    transform="translate(317.102 392.615)"
                    fill="#ffd7e0"
                    stroke="#392d2f"
                    strokeWidth={2}
                    onClick={(e) => {e.stopPropagation();setCloseWindowAnother(true)}}
                    style={{cursor: 'pointer'}}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_4-2"
                    data-name="Ellipse 4"
                    transform="translate(353.102 392.615)"
                    fill="#d1fff1"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                  <g
                    id="Ellipse_5-2"
                    data-name="Ellipse 5"
                    transform="translate(389.102 392.615)"
                    fill="#fcf2e2"
                    stroke="#392d2f"
                    strokeWidth={2}
                  >
                    <circle cx={10} cy={10} r={10} stroke="none" />
                    <circle cx={10} cy={10} r={9} fill="none" />
                  </g>
                </g>
              </g>
              <text
                id="Žemė_išželdino_augmeniją:_augalus_duodančius_visų_rūšių_sėklą_ir_visų_rūšių_medžius_vedančius_vaisius_su_sėklomis."
                data-name="Žemė išželdino augmeniją: augalus, duodančius visų rūšių sėklą, ir visų rūšių medžius, vedančius vaisius su sėklomis."
                transform="translate(149.5 362.5)"
                fill="#392d2f"
                fontSize={100}
                fontFamily="Voyage-Regular, Voyage"
              >
                <tspan x="19.8" y={102}>
                  Žemė išželdino augmeniją:{" "}
                </tspan>
                <tspan x="49.6" y={222}>
                  augalus, duodančius visų{" "}
                </tspan>
                <tspan x="102.35" y={342}>
                  rūšių sėklą, ir visų rūšių{" "}
                </tspan>
                <tspan x="13.8" y={462}>
                  medžius, vedančius vaisius{" "}
                </tspan>
                <tspan x="312.05" y={582}>
                  su sėklomis.
                </tspan>
              </text>
            </g>
          )
        })}
      </g>
      {showEnd && (
        <g transform='translate(0 2097)' >
        <defs>
          <clipPath id="clip-Web_1920_16">
            <rect width={1920} height={1080} />
          </clipPath>
        </defs>
        <g id="Web_1920_16" data-name="Web 1920 – 16" clipPath="url(#clip-Web_1920_16)">
          <rect width={1920} height={1080} fill="#392d2f" />
          <text id="Atėjo_vakaras_ir_išaušo_rytas._Antroji_diena" ref={finalScene} data-name="Atėjo vakaras ir išaušo rytas.Antroji diena" transform="translate(247.5 398.5)" fill="#fcf2e2" fontSize={100} fontFamily="Voyage"><tspan x="176.45" y="102">Atėjo vakaras ir išaušo rytas.</tspan><tspan x="440.65" y="222">Trečioji diena</tspan></text>
          <g id="day_count" data-name="day count" transform="translate(42 -3987)">
            <circle id="Ellipse_18" data-name="Ellipse 18" cx="17.5" cy="17.5" r="17.5" transform="translate(544 4876)" fill="#fcf2e2"/>
            <circle id="Ellipse_19" data-name="Ellipse 19" cx="17.5" cy="17.5" r="17.5" transform="translate(663 4876)" fill="#fcf2e2"/>
            <circle id="Ellipse_20" data-name="Ellipse 20" cx="17.5" cy="17.5" r="17.5" transform="translate(782 4876)" fill="#fcf2e2"/>
            <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(1019 4876)" fill="none" stroke="#fcf2e2" stroke-width="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_23" data-name="Ellipse 23" transform="translate(1138 4876)" fill="none" stroke="#fcf2e2" stroke-width="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g id="Ellipse_24" data-name="Ellipse 24" transform="translate(1257 4876)" fill="none" stroke="#fcf2e2" stroke-width="2">
              <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
              <circle cx="17.5" cy="17.5" r="16.5" fill="none"/>
            </g>
            <g  fill="none" stroke="#fcf2e2" stroke-width="2">
              <circle cx="30.5" cy="30.5" r="30.5" stroke="none"/>
              <circle cx="30.5" cy="30.5" r="29.5" fill="none"/>
            </g>
            <motion.g
              key="circle"
              onHoverStart={() => updateEllipse(2)}
              onHoverEnd={() => updateEllipse(1)}
              style={{cursor: 'pointer'}}
              onClick={onNext} id="Ellipse_19-2" data-name="Ellipse 19" transform="translate(888 4863)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
              <circle key="circle2" cx="30.5" cy="30.5" r={2 *17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
              <circle key="circle3" cx="30.5" cy="30.5" r={2 *16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            </motion.g>
          </g>
        </g>
        </g>
      )}
    </svg>
    </React.Fragment>
  )
}
