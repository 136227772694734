import React from "react";
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';
import {NextContext} from '../components/Context';

export default ({onNext, ...props}) => {
  const nextContext = React.useContext(NextContext);

  const [closedWindow, setClosedWindow] = React.useState(false);
  const [closedWindow123, setClosedWindow123] = React.useState(false);
  const [size, setSize] = React.useState(1622);
  const [showEnd, setShowEnd] = React.useState(false);
  const [windowStack, setWindowStack] = React.useState([
    'Group_104',
    'Group_105',
    'Group_103'
  ]);
  const [ellipse, updateEllipse] = React.useState(1);

  const endRef = React.useRef();

  React.useEffect(
    () => {
      if (!endRef.current) {
        return;
      }
      return nextContext.onRegister(endRef.current);
    },
    [endRef.current] // eslint-disable-line
  )

  const setFocusedWindow = (win) => {
    const newStack = windowStack.filter(name => name !== win);
    newStack.push(win);
    setWindowStack(newStack);

    if (!showEnd && win === 'Group_104') {
      setShowEnd(true);
    }
  }

  React.useEffect(
    () => {
      if (showEnd) {
        setSize(2705)
      }
    },
    [showEnd]
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox={`0 0 1920 ${size}`}
    >
      <BackgroundColor color="#fcf2e2" />
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_25"
            data-name="Rectangle 25"
            width={351}
            height={669}
            transform="translate(3128 10976)"
            fill="#fff"
            stroke="#707070"
            strokeWidth={1}
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_24"
            data-name="Rectangle 24"
            width={360}
            height={611}
            transform="translate(3098 12141)"
            fill="#fff"
            stroke="#707070"
            strokeWidth={1}
          />
        </clipPath>
        <clipPath id="clip-Web_1920_50">
          <rect width={1920} height={size} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_50"
        data-name="Web 1920 – 50"
        clipPath="url(#clip-Web_1920_50)"
      >
      <g id="Group_44" data-name="Group 44" transform="translate(-295.102 1246.602)">
        <g id="Group_42" data-name="Group 42" transform="translate(295.102 378.398)">
          <g id="Rectangle_1" data-name="Rectangle 1" fill="#392d2f" stroke="#392d2f" stroke-width="2">
            <rect width="1920" height="1080" stroke="none"/>
            <rect x="1" y="1" width="1918" height="1078" fill="none"/>
          </g>
        </g>
      </g>
        <rect width={1920} height={1607} fill="#fcf2e2" />
        {[0, 1, 2].map((i) => {
          if (windowStack.indexOf('Group_103') === i) {
            if (closedWindow) {
              return null;
            }
            return (
              <React.Fragment>
                <g id="Group_103" data-name="Group 103" transform="translate(565 -109.554)" onClick={() => setFocusedWindow('Group_103')}>
                  <g
                    id="Group_79-2"
                    data-name="Group 79"
                    transform="translate(323 160.554)"
                  >
                    <g id="Group_58-2" data-name="Group 58" transform="translate(0 0)">
                      <g id="Group_53-2" data-name="Group 53">
                        <g id="Group_50-2" data-name="Group 50">
                          <g id="Group_42-2" data-name="Group 42">
                            <g
                              id="Rectangle_1-2"
                              data-name="Rectangle 1"
                              fill="#dfffd7"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={923} height={989} stroke="none" />
                              <rect x={1} y={1} width={921} height={987} fill="none" />
                            </g>
                          </g>
                          <g id="Group_43" data-name="Group 43">
                            <g
                              id="Rectangle_1-3"
                              data-name="Rectangle 1"
                              fill="#392d2f"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={923} height={45} stroke="none" />
                              <rect x={1} y={1} width={921} height={43} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Ellipse_3"
                            data-name="Ellipse 3"
                            transform="translate(22 14)"
                            fill="#ffd7e0"
                            stroke="#392d2f"
                            strokeWidth={2}
                            style={{cursor: 'pointer'}}
                            onClick={() => setClosedWindow(true)}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_4"
                            data-name="Ellipse 4"
                            transform="translate(58 14)"
                            fill="#d1fff1"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_5"
                            data-name="Ellipse 5"
                            transform="translate(94 14)"
                            fill="#fcf2e2"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <text
                  onClick={() => setFocusedWindow('Group_103')}
                  id="Algoritmas_sukūrė_žmogų_pagal_savo_paveikslą_pagal_savo_paveikslą_sukūrė_jį_vyrą_ir_moterį_sukūrė_juos."
                  data-name="Algoritmas sukūrė žmogų pagal savo paveikslą, pagal savo paveikslą sukūrė jį;vyrą ir moterį; sukūrė juos."
                  transform="translate(869.5 131.5)"
                  fill="#392d2f"
                  fontSize={100}
                  fontFamily="Voyage-Regular, Voyage"
                >
                  <tspan x="130.9" y={102}>
                    Algoritmas sukūrė{" "}
                  </tspan>
                  <tspan x="118.3" y={222}>
                    žmogų pagal savo{" "}
                  </tspan>
                  <tspan x="291.55" y={342}>
                    paveikslą,
                  </tspan>
                  <tspan x="77.1" y={462}>
                    pagal savo paveikslą{" "}
                  </tspan>
                  <tspan x="313.65" y={582}>
                    sukūrė jį;
                  </tspan>
                  <tspan x="72.7" y={702}>
                    vyrą ir moterį; sukūrė{" "}
                  </tspan>
                  <tspan x="392.85" y={822}>
                    juos.
                  </tspan>
                </text>
              </React.Fragment>
            )
          } else if (windowStack.indexOf('Group_105') === i) {
            if (closedWindow123) {
              return null;
            }
            return (
              <React.Fragment>
                <g id="Group_123" data-name="Group 123" transform="translate(-4977 5165)" onClick={() => setFocusedWindow('Group_105')}>
                  <g
                    id="Group_122"
                    data-name="Group 122"
                    transform="translate(4813.978 -4681.554)"
                  >
                    <g
                      id="Group_79"
                      data-name="Group 79"
                      transform="translate(323.022 160.554)"
                    >
                      <g id="Group_58" data-name="Group 58" transform="translate(0 0)">
                        <g id="Group_53" data-name="Group 53">
                          <g id="Group_50" data-name="Group 50">
                            <g id="Group_42" data-name="Group 42">
                              <g
                                id="Rectangle_1"
                                data-name="Rectangle 1"
                                fill="#fcf2e2"
                                stroke="#392d2f"
                                strokeWidth={2}
                              >
                                <rect width={1365} height={855} stroke="none" />
                                <rect x={1} y={1} width={1363} height={853} fill="none" />
                              </g>
                            </g>
                            <g id="Group_43" data-name="Group 43">
                              <g
                                id="Rectangle_1-2"
                                data-name="Rectangle 1"
                                fill="#392d2f"
                                stroke="#392d2f"
                                strokeWidth={2}
                              >
                                <rect width={1365} height={45} stroke="none" />
                                <rect x={1} y={1} width={1363} height={43} fill="none" />
                              </g>
                            </g>
                            <g
                              id="Ellipse_3"
                              data-name="Ellipse 3"
                              transform="translate(22 14)"
                              fill="#ffd7e0"
                              stroke="#392d2f"
                              strokeWidth={2}
                              style={{cursor: 'pointer'}}
                              onClick={() => setClosedWindow123(true)}
                            >
                              <circle cx={10} cy={10} r={10} stroke="none" />
                              <circle cx={10} cy={10} r={9} fill="none" />
                            </g>
                            <g
                              id="Ellipse_4"
                              data-name="Ellipse 4"
                              transform="translate(58 14)"
                              fill="#d1fff1"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <circle cx={10} cy={10} r={10} stroke="none" />
                              <circle cx={10} cy={10} r={9} fill="none" />
                            </g>
                            <g
                              id="Ellipse_5"
                              data-name="Ellipse 5"
                              transform="translate(94 14)"
                              fill="#fcf2e2"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <circle cx={10} cy={10} r={10} stroke="none" />
                              <circle cx={10} cy={10} r={9} fill="none" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <text
                    id="_Būkite_vaisingi_ir_dauginkitės_pripildykite_žemę_ir_valdykite_ją_Viešpataukite_ir_jūros_žuvims_ir_padangių_paukščiams_ir_visiems_žemėje_judantiems_gyvūnams."
                    data-name="„Būkite vaisingi ir dauginkitės, pripildykite žemę ir valdykite ją! Viešpataukite ir jūros žuvims, ir padangių paukščiams, ir visiems žemėje judantiems gyvūnams."
                    transform="translate(5197.5 -4451.5)"
                    fill="#392d2f"
                    fontSize={100}
                    fontFamily="Voyage-Regular, Voyage"
                  >
                    <tspan x="45.4" y={102}>
                      „Būkite vaisingi ir dauginkitės,{" "}
                    </tspan>
                    <tspan x="0.95" y={222}>
                      pripildykite žemę ir valdykite ją!{" "}
                    </tspan>
                    <tspan x="23.95" y={342}>
                      Viešpataukite ir jūros žuvims, ir{" "}
                    </tspan>
                    <tspan x="172.5" y={462}>
                      padangių paukščiams,{" "}
                    </tspan>
                    <tspan x="46.65" y={582}>
                      ir visiems žemėje judantiems{" "}
                    </tspan>
                    <tspan x="395.35" y={702}>
                      gyvūnams.
                    </tspan>
                  </text>
                </g>
              </React.Fragment>
            )
          }

          return (
            <g
              onClick={() => setFocusedWindow('Group_104')}
              style={{cursor: windowStack.indexOf('Group_104') === 0 ? 'pointer' : 'default'}}>
              <g id="Group_104" data-name="Group 104">
                <image
                  id="adamandeveDurer"
                  width={966}
                  height={1269}
                  transform="translate(470 167)"
                  xlinkHref="Web%201920%20-%2050-image.jpg"
                />
                <g id="Group_80" data-name="Group 80" transform="translate(153 -2.554)">
                  <g
                    id="Group_79-3"
                    data-name="Group 79"
                    transform="translate(323 160.554)"
                  >
                    <g id="Group_58-3" data-name="Group 58" transform="translate(0 0)">
                      <g id="Group_53-3" data-name="Group 53">
                        <g id="Group_50-3" data-name="Group 50">
                          <g id="Group_42-3" data-name="Group 42">
                            <g
                              id="Rectangle_1-5"
                              data-name="Rectangle 1"
                              fill="none"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={960} height={1287} stroke="none" />
                              <rect x={1} y={1} width={958} height={1285} fill="none" />
                            </g>
                          </g>
                          <g id="Group_43-3" data-name="Group 43">
                            <g
                              id="Rectangle_1-6"
                              data-name="Rectangle 1"
                              fill="#392d2f"
                              stroke="#392d2f"
                              strokeWidth={2}
                            >
                              <rect width={960} height={45} stroke="none" />
                              <rect x={1} y={1} width={958} height={43} fill="none" />
                            </g>
                          </g>
                          <g
                            id="Ellipse_3-3"
                            data-name="Ellipse 3"
                            transform="translate(22 14)"
                            fill="#ffd7e0"
                            stroke="#392d2f"
                            strokeWidth={2}
                            style={{cursor: 'pointer'}}
                            onClick={onNext}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_4-3"
                            data-name="Ellipse 4"
                            transform="translate(58 14)"
                            fill="#d1fff1"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                          <g
                            id="Ellipse_5-3"
                            data-name="Ellipse 5"
                            transform="translate(94 14)"
                            fill="#fcf2e2"
                            stroke="#392d2f"
                            strokeWidth={2}
                          >
                            <circle cx={10} cy={10} r={10} stroke="none" />
                            <circle cx={10} cy={10} r={9} fill="none" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g
                  id="Rectangle_34"
                  data-name="Rectangle 34"
                  transform="translate(476 203)"
                  fill="#392d2f"
                  stroke="#707070"
                  strokeWidth={1}
                  opacity="0.417"
                >
                  <rect width={960} height={1242} stroke="none" />
                  <rect x="0.5" y="0.5" width={959} height={1241} fill="none" />
                </g>
              </g>
              <g
                id="Mask_Group_17"
                data-name="Mask Group 17"
                transform="translate(-2506 -10651)"
                clipPath="url(#clip-path)"
              >
                <image
                  id="VYRAS"
                  width={1280}
                  height={720}
                  transform="translate(2638 10925)"
                  xlinkHref="Web%201920%20-%2050-image2.png"
                />
              </g>
              <g
                id="Mask_Group_16"
                data-name="Mask Group 16"
                transform="translate(-2047 -11685)"
                clipPath="url(#clip-path-2)"
                style={{cursor: 'pointer'}}
              >
                <image
                  id="MOTERIS"
                  width={1154}
                  height={649}
                  transform="translate(2683 12103)"
                  xlinkHref="Web%201920%20-%2050-image3.png"
                />
              </g>
              <motion.g opacity={0} whileHover={{opacity: 1}}>
                <g
                  id="Rectangle_35"
                  data-name="Rectangle 35"
                  transform="translate(477 203)"
                  fill="#392d2f"
                  stroke="#707070"
                  strokeWidth={1}
                  opacity="0.852"
                >
                  <rect width={957} height={1242} stroke="none" />
                  <rect x="0.5" y="0.5" width={956} height={1241} fill="none" />
                </g>
                <text
                  id="Algoritmas_tarė:_Štai_aš_jums_daviau_visus_visoje_žemėje_sėklą_teikiančius_augalus_ir_visus_medžius_kurie_veda_vaisius_su_sėklomis_jie_bus_jums_maistas._O_visiems_laukų_gyvuliams_visiems_padangių_paukščiams_ir_visiems_žeme_rėpliojantiems_gyvūnams_tur"
                  data-name="Algoritmas tarė: „Štai aš jums daviau visus, visoje žemėje sėklą teikiančius augalus ir visus medžius, kurie veda vaisius su sėklomis; jie bus jums maistas. O visiems laukų gyvuliams, visiems padangių paukščiams ir visiems žeme rėpliojantiems gyvūnams, tur"
                  transform="translate(621.5 606.773)"
                  fill="#fcf2e2"
                  fontSize={40}
                  fontFamily="Voyage-Regular, Voyage"
                >
                  <tspan x="17.485" y={41}>
                    Algoritmas tarė: „Štai aš jums daviau visus,{" "}
                  </tspan>
                  <tspan x="19.785" y={89}>
                    visoje žemėje sėklą teikiančius augalus ir{" "}
                  </tspan>
                  <tspan x="61.465" y={137}>
                    visus medžius, kurie veda vaisius su{" "}
                  </tspan>
                  <tspan x="25.165" y={185}>
                    sėklomis; jie bus jums maistas. O visiems{" "}
                  </tspan>
                  <tspan x="68.285" y={233}>
                    laukų gyvuliams, visiems padangių{" "}
                  </tspan>
                  <tspan x="6.465" y={281}>
                    paukščiams ir visiems žeme rėpliojantiems{" "}
                  </tspan>
                  <tspan x="23.245" y={329}>
                    gyvūnams, turintiems gyvybės alsavimą,{" "}
                  </tspan>
                  <tspan x="23.705" y={377}>
                    {"{"}daviau{"}"} maistui visus žaliuosius augalus“.
                  </tspan>
                </text>
              </motion.g>
            </g>
          )
        })}
        {showEnd && (
          <g>
            <text
              id="Atėjo_vakaras_ir_išaušo_rytas._Šeštoji_diena"
              data-name="Atėjo vakaras ir išaušo rytas. Šeštoji diena"
              transform="translate(247 2022.5)"
              fill="#fcf2e2"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
              ref={endRef}
            >
              <tspan x="176.45" y={102}>
                Atėjo vakaras ir išaušo rytas.
              </tspan>
              <tspan x="468.1" y={222}>
                Šeštoji diena
              </tspan>
            </text>
            <g id="day_count" data-name="day count" transform="translate(36 -2363)">
              <circle
                id="Ellipse_18"
                data-name="Ellipse 18"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(544 4876)"
                fill="#fcf2e2"
              />
              <circle
                id="Ellipse_19"
                data-name="Ellipse 19"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(663 4876)"
                fill="#fcf2e2"
              />
              <circle
                id="Ellipse_20"
                data-name="Ellipse 20"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(782 4876)"
                fill="#fcf2e2"
              />
              <circle
                id="Ellipse_22"
                data-name="Ellipse 22"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(1019 4876)"
                fill="#fcf2e2"
              />
              <circle
                id="Ellipse_23"
                data-name="Ellipse 23"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(1138 4876)"
                fill="#fcf2e2"
              />
              <motion.g
                id="Ellipse_19-2"
                data-name="Ellipse 19"
                transform="translate(1244 4863)"
                fill="none"
                stroke="#fcf2e2"
                strokeWidth={2}
                onHoverStart={() => updateEllipse(2)}
                onHoverEnd={() => updateEllipse(1)}
                style={{cursor: 'pointer'}}
                onClick={onNext}
              >
                <circle cx="30.5" cy="30.5" r="30.5" stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
                <circle cx="30.5" cy="30.5" r="29.5" fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
              </motion.g>
              <circle
                id="Ellipse_26"
                data-name="Ellipse 26"
                cx="17.5"
                cy="17.5"
                r="17.5"
                transform="translate(901 4876)"
                fill="#fcf2e2"
              />
            </g>
          </g>
        )}
      </g>
    </svg>
  )
}
