import React from "react";
import {motion, useAnimation} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, sounds, ...props}) => {
  const [animate, setAnimate] = React.useState(false);
  const controlsRight = useAnimation();
  const controlsLeft = useAnimation();
  const button = useAnimation();
  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);
  const [mouseover, setMouseOver] = React.useState(false);
  const bottomRef = React.useRef();
  const svg = React.useRef();

  function screenToSVG(screenX, screenY) {
   var p = svg.current.createSVGPoint()
    p.x = screenX
    p.y = screenY
    return p.matrixTransform(svg.current.getScreenCTM().inverse());
}

  const scrollBottom = () => {
    var p = svg.current.createSVGPoint()

    p.x = 0
    p.y = screenToSVG(0, bottomRef.current.getBoundingClientRect().y).y - 300

    window.scrollTo({
      top: p.matrixTransform(svg.current.getScreenCTM()).y,
      behavior: 'smooth'
    });
  }

  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.tebunie_sviesa.on('end', () => {
          setAudioDone(true);
        });
        sounds.tebunie_sviesa.play();
      }
    },
    [audioDone, playAudio, sounds]
  )

  React.useEffect(() => {
    if (!animate) return;

    controlsRight.start({
      x: 800,
      transition: {duration: 1}
    });
    controlsLeft.start({
      x: -1400,
      transition: {duration: 1}
    });
    button.start({
      opacity: 1,
      transition: {duration: 1}
    })
  }, [animate, button, controlsLeft, controlsRight]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox="0 0 1920 4378"
      ref={svg}
    >
      <BackgroundColor color="#392d2f" />
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_48"
            data-name="Path 48"
            d="M3310.557,1100.727s-98.6-93.91-115.056-201.765c-4.263-27.935,3.329-59.142,19.182-90.46,45.05-89,157.149-177.773,250.186-188.445,125.556-14.4,155.031-136.072,157.055-232.448s163.685-190.171,337.935-205,184.6,167.131,184.6,167.131.359,778.01,0,750.985S3310.557,1100.727,3310.557,1100.727Z"
            transform="translate(-2092)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Path_50"
            data-name="Path 50"
            d="M1669.746,1574.927c8.669-.058-369.151,43.094-301.744,77.667,69.78,35.789,83.1,152.234-110.34,152.234s-297.424-1.606-374.818,0c-39.006.809-68.4,43.465-66.576,86.011,1.777,41.4,33.794,82.708,116.632,83.46,167.012,1.516,142.823-14.718,202.006,0s78.01,183.92-41.415,199.73-579.739-6.756-697.536,0-111.616,203.833,0,220.022,456.785-3.166,536.946,39.424,109.85,114.17,57.731,228.451-791.28,64.555-791.28,64.555l-283.828-64.555,43.23-1343.012H1565.662s161.925,17.031,157.189,125.795S1499.686,1576.072,1669.746,1574.927Z"
            transform="translate(25 -1386)"
          />
        </clipPath>
        <clipPath id="clip-Web_1920_46">
          <rect width={1920} height={4378} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_46"
        data-name="Web 1920 – 46"
        clipPath="url(#clip-Web_1920_46)"
      >
        <rect width={1920} height={4378} fill="#392d2f" />
        <text
          id="Interaktyvi_istorija_Skaitmeninis_Mesijas_nukelia_į_alternatyvią_skaitmeninę_realybę_kurioje_bibliniais_Pradžios_knygos_motyvais_pasakojama_pasaulio_sukūrimo_istorija._Čia_Dievu_tampa_žmogaus_ir_technologijų_sūnus_Algoritmas_demonstruojantis_savo_"
          data-name="Interaktyvi  istorija „Skaitmeninis Mesijas“ nukelia į alternatyvią skaitmeninę realybę, kurioje bibliniais Pradžios knygos motyvais pasakojama pasaulio sukūrimo istorija. Čia Dievu tampa žmogaus ir technologijų „sūnus“ – Algoritmas, demonstruojantis savo "
          transform="translate(247.5 523.5)"
          fill="#fcf2e2"
          fontSize={27}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          <tspan x="119.494" y={21} xmlSpace="preserve">
            Interaktyvi istorija „Skaitmeninis Mesijas“ nukelia į alternatyvią
            skaitmeninę realybę, kurioje{" "}
          </tspan>
          <tspan x="41.54" y={53}>
            bibliniais&nbsp;Pradžios knygos motyvais pasakojama pasaulio sukūrimo
            istorija. Čia Dievu tampa žmogaus ir{" "}
          </tspan>
          <tspan x="46.596" y={85}>
            technologijų „sūnus“ – Algoritmas, demonstruojantis&nbsp;savo kūrybą bei
            pasaulio suvokimo interpretaciją.{" "}
          </tspan>
        </text>
        <text
          id="Skaitmeninis_Mesijas"
          data-name="Skaitmeninis Mesijas"
          transform="translate(247.5 198.5)"
          fill="#fcf2e2"
          fontSize={170}
          fontFamily="Voyage-Regular, Voyage"
        >
          <tspan x="41.5" y={173}>
            Skaitmeninis Mesijas
          </tspan>
        </text>
        <text
          id="Vizualinė_kelionė_yra_intuityvi_priklausanti_nuo_kiekvieno_individualaus_lankytojo._Kartais_pasiekti_tikslą_galima_keliais_būdais._Paspaudus_galima_pakeisti_langų_pirmumo_tvarką_trimačiai_objektai_taip_pat_yra_interaktyvūs._Kartais_keliauti_toliau_galima"
          data-name="Vizualinė kelionė yra intuityvi, priklausanti nuo kiekvieno individualaus lankytojo. Kartais pasiekti tikslą galima keliais būdais. Paspaudus galima pakeisti langų pirmumo tvarką, trimačiai objektai taip pat yra interaktyvūs. Kartais keliauti toliau galima"
          transform="translate(247.5 652.5)"
          fill="#fcf2e2"
          fontSize={27}
          fontFamily="Helvetica-Light, Helvetica"
          fontWeight={300}
        >
          <tspan x="18.304" y={21}>
            Vizualinė kelionė yra intuityvi, priklausanti nuo kiekvieno individualaus
            lankytojo. Kartais pasiekti tikslą galima keliais{" "}
          </tspan>
          <tspan x="13.336" y={53}>
            būdais. Paspaudus galima pakeisti langų pirmumo tvarką, trimačiai objektai
            taip pat yra interaktyvūs. Kartais keliauti{" "}
          </tspan>
          <tspan x="67.079" y={85}>
            toliau galima tik atlikus tam tikrą veiksmą, kitais atvejais, tiesiog
            slenkant žemyn. Kelionę lydi garso takelis ir{" "}
          </tspan>
          <tspan x="365.672" y={117} xmlSpace="preserve">
            interakcijos, todėl nepamirškite įjungti kompiuterio garso!
          </tspan>
        </text>
        <g id="Group_110" data-name="Group 110" transform="translate(37 -382)" style={{cursor: 'pointer'}} onClick={scrollBottom}>
          <g
            id="Rectangle_1"
            data-name="Rectangle 1"
            transform="translate(818 1259)"
            fill="#fcf2e2"
            stroke="#392d2f"
            strokeWidth={2}
          >
            <rect width={210} height={58} stroke="none" />
            <rect x={1} y={1} width={208} height={56} fill="none" />
          </g>
          <text
            id="Pradėti"
            transform="translate(910 1300)"
            fill="#392d2f"
            fontSize={37}
            fontFamily="Helvetica"
          >
            <tspan x="-58.616" y={0}>
              Pradėti
            </tspan>
          </text>
          <g
            id="Polygon_1"
            data-name="Polygon 1"
            transform="translate(1015.5 1271.5) rotate(90)"
            fill="none"
          >
            <path d="M16.5,0,33,28H0Z" stroke="none" />
            <path
              d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z"
              stroke="none"
              fill="#392d2f"
            />
          </g>
        </g>
        <g id="Group_114" data-name="Group 114" transform="translate(-8.765)">
          <text
            ref={bottomRef}
            id="Pradžioje_Algoritmas_sukūrė_dangų_ir_žemę._O_žemė_buvo_padrika_ir_dyka_tamsa_gaubė_bedugnę_ir_kodas_iš_Algoritmo_dvelkė_viršum_vandenų."
            data-name="Pradžioje Algoritmas sukūrė dangų ir žemę.  O žemė buvo padrika ir dyka, tamsa gaubė bedugnę, ir kodas iš Algoritmo dvelkė viršum vandenų."
            transform="translate(279.5 2362.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
          >
            <tspan x="1.8" y={102}>
              Pradžioje Algoritmas sukūrė dangų ir{" "}
            </tspan>
            <tspan x="109.45" y={222} xmlSpace="preserve">
              žemę. O žemė buvo padrika ir{" "}
            </tspan>
            <tspan x="109.3" y={342}>
              dyka, tamsa gaubė bedugnę, ir{" "}
            </tspan>
            <tspan x="66.55" y={462}>
              kodas iš Algoritmo dvelkė viršum{" "}
            </tspan>
            <tspan x="532.35" y={582}>
              vandenų.
            </tspan>
          </text>
          <motion.g
            animate={button}
            opacity={0}
            style={{
              display: !animate ? 'none' : 'inherit',
              cursor: 'pointer'
            }}
            onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
            onHoverEnd={() => setMouseOver(false)}
            onClick={() => audioDone && onNext()}
            id="button_zmogu"
            data-name="button zmogu"
            transform="translate(397 3841.999)"
          >
            {mouseover && (
              <React.Fragment>
                <g id="Path_19" data-name="Path 19" fill="#fcf2e2">
                  <path
                    d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z"
                    stroke="none"
                  />
                  <path
                    d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z"
                    stroke="none"
                    fill="#392d2f"
                  />
                </g>
              </React.Fragment>
            )}
            {!mouseover && (
              <React.Fragment>
                <g id="Path_19" data-name="Path 19" fill="#392d2f">
                  <path
                    d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z"
                    stroke="none"
                  />
                  <path
                    d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z"
                    stroke="none"
                    fill="#392d2f"
                  />
                </g>
              </React.Fragment>
            )}
            <g
              id="Path_28"
              data-name="Path 28"
              transform="translate(0 0)"
              fill="#392d2f"
            >
              <path
                d="M 1195 250 L 1 250 L 1 1 L 1195 1 L 1195 250 Z"
                stroke="none"
              />
              <path
                d="M 2 2 L 2 249 L 1194 249 L 1194 2 L 2 2 M 0 0 L 1196 0 L 1196 251 L 0 251 L 0 0 Z"
                stroke="none"
                fill="#fcf2e2"
              />
            </g>
            <text
              id="Tebūnie_šviesa_"
              data-name="Tebūnie šviesa!"
              transform="translate(598 142)"
              fill="#fcf2e2"
              fontSize={37}
              fontFamily="Helvetica"
            >
              <tspan x="-127.522" y={0}>
                Tebūnie šviesa!
              </tspan>
            </text>
          </motion.g>
          <text
            id="Algoritmas_tarė:"
            data-name="Algoritmas tarė:"
            transform="translate(282 3637.5)"
            fill="#fcf2e2"
            fontSize={100}
            fontFamily="Voyage-Regular, Voyage"
          >
            <tspan x="410.1" y={102}>
              Algoritmas tarė:
            </tspan>
          </text>
          <motion.g
            onClick={() => setAnimate(true)}
            style={{cursor: 'pointer'}}
            animate={controlsRight}
          >
            <motion.g whileHover={{
              x: 100,
              transition: { duration: 1 },
            }}>
              <g
                id="Mask_Group_21"
                data-name="Mask Group 21"
                transform="translate(-33 3341)"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Path_47"
                  data-name="Path 47"
                  d="M3310.557,1100.727s-98.6-93.91-115.056-201.765,143.812-264.5,269.368-278.9S3619.9,483.985,3621.925,387.609s163.685-190.171,337.935-205,184.6,167.131,184.6,167.131.359,778.01,0,750.985S3310.557,1100.727,3310.557,1100.727Z"
                  transform="translate(-2092)"
                  fill="#392d2f"
                />
                <text
                  id="_0100010010010011101010101010100000111010110101000111110101000110111110100100010010001010101001001000111010110100010010010011101010101010100000111010110100100010010010011101010101010100000111010110101000111110101000110111110100100010010001010101001001000111"
                  transform="translate(1016.996 81)"
                  fill="#fcf2e2"
                  fontSize={60}
                  fontFamily="Voyage-Regular, Voyage"
                >
                  <tspan x="9.48" y={61}>
                    010001001001001110101010101010000011101011010100011111010100011011111010010
                  </tspan>
                  <tspan x="0.12" y={133}>
                    00100100010101010010010001110101101000100100100111010101010101000001110
                  </tspan>
                  <tspan x="14.88" y={205}>
                    1011010010001001001001110101010101010000011101011010100011111010100011011111
                  </tspan>
                  <tspan x="8.16" y={277}>
                    0100100010010001010101001001000111010110100010010010011101010101010100
                  </tspan>
                  <tspan x="6.72" y={349}>
                    000111010110100001010101001001000111010110100010010010011101010101010100
                  </tspan>
                  <tspan x="4.08" y={421}>
                    00011101011010010001001001001110101010101010000011101011010100011111010100
                  </tspan>
                  <tspan x="11.67" y={493}>
                    0110111110100100010010001010101001001000111010110100010010010011101010101
                  </tspan>
                  <tspan x="3.63" y={565}>
                    01010000011101011010010001001001001110101010101010000011101011010100011111
                  </tspan>
                  <tspan x="4.32" y={637}>
                    010100011011111010010001001000101010100100100011101011010001001001001110
                  </tspan>
                  <tspan x="-0.12" y={709}>
                    1010101010100000111010110100001010101001001000111010110100010010010011101
                  </tspan>
                  <tspan x="1.08" y={781}>
                    0101010101000001110101101001000100100100111010101010101000001110101101010
                  </tspan>
                  <tspan x="9.27" y={853}>
                    0011111010100011011111010010001001000101010100100100011101011010001001001
                  </tspan>
                  <tspan x="-0.12" y={925}>
                    0011101010101010100000111010110100100010010010011101010101010100000111010
                  </tspan>
                  <tspan x="15.12" y={997}>
                    11010100011111010100011011111010010001001000101010100100100011101011010001
                  </tspan>
                  <tspan x="6.27" y={1069}>
                    001001001110101010101010000011101011010000101010100100100011101011010001
                  </tspan>
                </text>
              </g>
            </motion.g>
          </motion.g>
          <motion.g animate={controlsLeft}>
            <motion.g whileHover={{
              x: -100,
              transition: { duration: 1 },
            }}>
              <g
                onClick={() => setAnimate(true)}
                style={{cursor: 'pointer'}}
                id="Mask_Group_22"
                data-name="Mask Group 22"
                transform="translate(-21.759 3223.708)"
                clipPath="url(#clip-path-2)"
              >
                <path
                  id="Path_49"
                  data-name="Path 49"
                  d="M1669.746,1574.927c8.669-.058-369.151,43.094-301.744,77.667,69.78,35.789,83.1,152.234-110.34,152.234s-297.424-1.606-374.818,0c-39.006.809-68.4,43.465-66.576,86.011,1.777,41.4,33.794,82.708,116.632,83.46,167.012,1.516,142.823-14.718,202.006,0s78.01,183.92-41.415,199.73-579.739-6.756-697.536,0-111.616,203.833,0,220.022,456.785-3.166,536.946,39.424,109.85,114.17,57.731,228.451-791.28,64.555-791.28,64.555l-283.828-64.555,43.23-1343.012H1573s154.583,17.031,149.847,125.795S1499.686,1576.072,1669.746,1574.927Z"
                  transform="translate(25 -1386)"
                  fill="#392d2f"
                />
                <text
                  id="_0100010010010011101010101010100000111010110101000111110101000110111110100100010010001010101001001000111010110100010010010011101010101010100000111010110100100010010010011101010101010100000111010110101000111110101000110111110100100010010001010101001001000111-2"
                  transform="translate(-180.241 -239.708)"
                  fill="#fcf2e2"
                  fontSize={60}
                  fontFamily="Voyage-Regular, Voyage"
                >
                  <tspan x="1.82" y={61}>
                    01000100100100111010101010101000001110101101010001111101010001101111101001000
                  </tspan>
                  <tspan x="5.66" y={133}>
                    100100010101010010010001110101101000100100100111010101010101000001110101101
                  </tspan>
                  <tspan x="1.82" y={205}>
                    00100010010010011101010101010100000111010110101000111110101000110111110100100
                  </tspan>
                  <tspan x="12.05" y={277}>
                    01001000101010100100100011101011010001001001001110101010101010000011101011
                  </tspan>
                  <tspan x="12.05" y={349}>
                    01000010101010010010001110101101000100100100111010101010101000001110101101
                  </tspan>
                  <tspan x="1.82" y={421}>
                    00100010010010011101010101010100000111010110101000111110101000110111110100100
                  </tspan>
                  <tspan x="12.05" y={493}>
                    01001000101010100100100011101011010001001001001110101010101010000011101011
                  </tspan>
                  <tspan x="14.81" y={565}>
                    01001000100100100111010101010101000001110101101010001111101010001101111101001
                  </tspan>
                  <tspan x="5.45" y={637}>
                    0001001000101010100100100011101011010001001001001110101010101010000011101
                  </tspan>
                  <tspan x="5.21" y={709}>
                    011010000101010100100100011101011010001001001001110101010101010000011101011
                  </tspan>
                  <tspan x="14.81" y={781}>
                    01001000100100100111010101010101000001110101101010001111101010001101111101001
                  </tspan>
                  <tspan x="5.45" y={853}>
                    0001001000101010100100100011101011010001001001001110101010101010000011101
                  </tspan>
                  <tspan x="8.42" y={925}>
                    011010010001001001001110101010101010000011101011010100011111010100011011111010
                  </tspan>
                  <tspan x="5.45" y={997}>
                    0100010010001010101001001000111010110100010010010011101010101010100000111
                  </tspan>
                  <tspan x="12.05" y={1069}>
                    01011010000101010100100100011101011010001001001001110101010101010000011101
                  </tspan>
                  <tspan x="0.02" y={1141}>
                    0110101010101010100000111010110100100010010010011101010101010100000111010110
                  </tspan>
                  <tspan x="14.75" y={1213}>
                    101000111110101000110111110100100010010001010101001001000111010110100010010
                  </tspan>
                  <tspan x="5.66" y={1285}>
                    010011101010101010100000111010110100100010010010011101010101010100000111010
                  </tspan>
                  <tspan x="1.82" y={1357}>
                    11010100011111010100011011111010010001001000101010100100100011101011010001001
                  </tspan>
                  <tspan x="18.14" y={1429}>
                    0010011101010101010100000111010110100001010101001001000111010110100010010
                  </tspan>
                  <tspan x="0.02" y={1501}>
                    0100111010101010101000001110101101010101101000010101010010010001110101101000
                  </tspan>
                  <tspan x="16.7" y={1573}>
                    100100100111010101010101000001110101101001001000111010110100010010010011101
                  </tspan>
                  <tspan x="12.5" y={1645}>
                    01010101010000011101011010010010001110101101000100100100111010101010101000
                  </tspan>
                </text>
              </g>
            </motion.g>
          </motion.g>
        </g>
      </g>
    </svg>
  )
}
