import React from "react";
import {NextContext} from '../components/Context';

export default ({onNext, ...props}) => {
  const [ellipse, updateEllipse] = React.useState(1);

  const scrollRef = React.useRef();

  const nextContext = React.useContext(NextContext);
  React.useEffect(
    () => {
      if (!scrollRef.current) {
        return;
      }
      return nextContext.onRegister(scrollRef.current);
    },
    [scrollRef.current] // eslint-disable-line
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 3410"
    >
      <defs>
        <clipPath id="clip-Web_1920_68">
          <rect width={1920} height={3410} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_68"
        data-name="Web 1920 – 68"
        clipPath="url(#clip-Web_1920_68)"
      >
        <rect width={1920} height={3410} fill="#392d2f" />
        <text
          id="Atėjo_vakaras_ir_išaušo_rytas._Septintoji_diena"
          data-name="Atėjo vakaras ir išaušo rytas.Septintoji diena"
          transform="translate(247.5 400.5)"
          fill="#fcf2e2"
          fontSize={100}
          fontFamily="Voyage-Regular, Voyage"
          ref={scrollRef}
        >
          <tspan x="176.45" y={102}>
            Atėjo vakaras ir išaušo rytas.
          </tspan>
          <tspan x="413.7" y={222}>
            Septintoji diena
          </tspan>
        </text>
        <g
          id="Rectangle_20"
          data-name="Rectangle 20"
          transform="translate(1885 255)"
          fill="#392d2f"
          stroke="#392d2f"
          strokeWidth={2}
        >
          <rect width={16} height={175} stroke="none" />
          <rect x={1} y={1} width={14} height={173} fill="none" />
        </g>
        <g id="day_count" data-name="day count" transform="translate(34 -3985)">
          <circle
            id="Ellipse_18"
            data-name="Ellipse 18"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(544 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(663 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_20"
            data-name="Ellipse 20"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(782 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_22"
            data-name="Ellipse 22"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(1019 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_23"
            data-name="Ellipse 23"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(1138 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_28"
            data-name="Ellipse 28"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(1257 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_26"
            data-name="Ellipse 26"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(901 4876)"
            fill="#fcf2e2"
          />
        </g>
        <text
          id="_Žiūrėkite_kad_jūsų_kas_nesuklaidintų._Daug_kas_ateis_prisidengęs_mano_vardu_ir_sakys_:_aš_Mesijas_ir_daugelį_suklaidins_Atsiras_netikrų_mesijų_ir_netikrų_pranašų_ir_jie_darys_didelių_ženklų_bei_stebuklų_mėgindami_suklaidinti_jei_tai_įmanoma_net_"
          data-name="„Žiūrėkite, kad jūsų kas nesuklaidintų. Daug kas ateis prisidengęs mano vardu ir sakys : „aš Mesijas“ – ir daugelį suklaidins… Atsiras netikrų mesijų ir netikrų pranašų, ir jie darys didelių ženklų bei stebuklų, mėgindami suklaidinti, jei tai įmanoma, net "
          transform="translate(290 1651)"
          fill="#fcf2e2"
          fontSize={60}
          fontFamily="Helvetica"
        >
          <tspan x="39.183" y={46}>
            „Žiūrėkite, kad jūsų kas nesuklaidintų. Daug kas{" "}
          </tspan>
          <tspan x="105.921" y={118}>
            ateis prisidengęs mano vardu ir sakys : „aš{" "}
          </tspan>
          <tspan x="40.896" y={190}>
            Mesijas“ – ir daugelį suklaidins… Atsiras netikrų{" "}
          </tspan>
          <tspan x="4.202" y={262}>
            mesijų ir netikrų pranašų, ir jie darys didelių ženklų{" "}
          </tspan>
          <tspan x="124.217" y={334}>
            bei stebuklų, mėgindami suklaidinti, jei tai{" "}
          </tspan>
          <tspan x="309.358" y={406}>
            įmanoma, net išrinktuosius“
          </tspan>
          <tspan x="674.5" y={478} />
          <tspan x="571.155" y={550}>
            (Mt. 24)
          </tspan>
        </text>
        <g
          id="Rectangle_11"
          data-name="Rectangle 11"
          transform="translate(965 2834)"
          fill="none"
          stroke="#fcf2e2"
          strokeWidth={2}
          style={{cursor: 'pointer'}}
          onClick={onNext}
        >
          <rect width={302} height={58} stroke="none" />
          <rect x={1} y={1} width={300} height={56} fill="#392d2f" />
        </g>
        <text
          id="Pabaiga"
          transform="translate(1111 2875)"
          fill="#fcf2e2"
          fontSize={37}
          fontFamily="Helvetica"
          style={{cursor: 'pointer'}}
          onClick={onNext}
        >
          <tspan x="-67.894" y={0}>
            Pabaiga
          </tspan>
        </text>
        <g style={{cursor: 'pointer'}} onClick={onNext}
          id="Rectangle_41"
          data-name="Rectangle 41"
          transform="translate(663 2834)"
          fill="#fcf2e2"
          stroke="#fcf2e2"
          strokeWidth={2}
        >
          <rect width={302} height={58} stroke="none" />
          <rect x={1} y={1} width={300} height={56} fill="none" />
        </g>
        <text
          id="Pradžia"
          transform="translate(823 2875)"
          fill="#392d2f"
          fontSize={37}
          fontFamily="Helvetica"
          style={{cursor: 'pointer'}} onClick={onNext}
        >
          <tspan x="-62.727" y={0}>
            Pradžia
          </tspan>
        </text>
        <g
          id="Polygon_1"
          data-name="Polygon 1"
          transform="translate(1257.5 2846.5) rotate(90)"
          fill="none"
        >
          <path d="M16.5,0,33,28H0Z" stroke="none" />
          <path
            d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z"
            stroke="none"
            fill="#fcf2e2"
          />
        </g>
        <g
          id="Polygon_2"
          data-name="Polygon 2"
          transform="translate(677.5 2879.5) rotate(-90)"
          fill="none"
          style={{cursor: 'pointer'}} onClick={onNext}
        >
          <path d="M16.5,0,33,28H0Z" stroke="none" />
          <path
            d="M 16.5 3.939393997192383 L 3.5 26 L 29.5 26 L 16.5 3.939393997192383 M 16.5 0 L 33 28 L 0 28 L 16.5 0 Z"
            stroke="none"
            fill="#392d2f"
          />
        </g>
      </g>
    </svg>
  )
}
