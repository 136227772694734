import React from "react";
import Scene42 from './42';
import Scene45 from './45';
import Scene50 from './50';
import Scene52 from './52';
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, sounds, setHeight, ...props}) => {

  const [showBrowser, setShowBrowser] = React.useState(false);
  const [show45, setShow45] = React.useState(false);
  const [show50, setShow50] = React.useState(false);
  const [show52, setShow52] = React.useState(false);
  const [mouseover, setMouseOver] = React.useState(false);

  const [audioDone, setAudioDone] = React.useState(false);
  const [playAudio, setPlayAudio] = React.useState(false);

  React.useEffect(
    () => {
      if (!audioDone && playAudio) {
        sounds.gyvunus.on('end', () => {
          setAudioDone(true);
        });
        sounds.gyvunus.play();
      }
    },
    [audioDone, playAudio, sounds.gyvunus]
  )

  React.useEffect(() => {
    if (showBrowser) {
      setHeight('auto')
    } else {
      setHeight('100vh')
    }
  }, [setHeight, showBrowser])

  React.useEffect(
    () => {
      if (show50) {
        window.scrollTo(0, 0);
      }
    },
    [show50]
  )


  return (
    <React.Fragment>
      {show52 && (
        <Scene52 {...props}  onNext={onNext} />
      )}
      {show50 && (
        <Scene50 onNext={onNext} />
      )}
      {show45 && (
        <Scene45 {...props} sounds={sounds} onNext={() => {
          setShow45(false);
          setShow50(true)
        }} />
      )}
      {showBrowser && (
        <Scene42 {...props} sounds={sounds} onNext={() => {
          setShow50(true)
          setShowBrowser(false)
        }} />
      )}
      {!showBrowser && !show45 &&!show52 && !show50 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          {...props}
          viewBox="0 0 1920 1080"
        >
          <BackgroundColor color="#ffd7e0" />
          <defs>
            <clipPath id="clip-_6_diena_antras_variantas">
              <rect width={1920} height={1080} />
            </clipPath>
          </defs>
          <g
            id="_6_diena_antras_variantas"
            data-name="6 diena antras variantas"
            clipPath="url(#clip-_6_diena_antras_variantas)"
          >
            <rect width={1920} height={1080} fill="#ffd7e0" />
            <motion.g
              id="button_zmogu"
              data-name="button zmogu"
              transform="translate(362 543.999)"
              onClick={() => audioDone && setShowBrowser(true)}
              style={{cursor: 'pointer'}}
              onHoverStart={() => {setPlayAudio(true);setMouseOver(true)}}
              onHoverEnd={() => setMouseOver(false)}
            >
              <g id="Path_19" data-name="Path 19" fill="#392d2f">
                {mouseover && (
                  <React.Fragment>
                    <path d="M 1205.774047851562 261.2617797851562 L 0.9999470114707947 261.2617797851562 L 0.9999470114707947 0.9999362826347351 L 1205.774047851562 0.9999362826347351 L 1205.774047851562 261.2617797851562 Z" stroke="none"/>
                    <path d="M 2 1.99993896484375 L 2 260.2617797851562 L 1204.774047851562 260.2617797851562 L 1204.774047851562 1.99993896484375 L 2 1.99993896484375 M 0 -6.103515625e-05 L 1206.774047851562 -6.103515625e-05 L 1206.774047851562 262.2617797851562 L 0 262.2617797851562 L 0 -6.103515625e-05 Z" stroke="none" fill="#392d2f"/>
                  </React.Fragment>
                )}
                {!mouseover && (
                  <React.Fragment>
                    <path
                      d="M 1184.292602539062 250 L 1.000086426734924 250 L 1.000086426734924 1 L 1184.292602539062 1 L 1184.292602539062 250 Z"
                      stroke="none"
                    />
                    <path
                      d="M 1.9998779296875 2 L 1.9998779296875 249 L 1183.292358398438 249 L 1183.292358398438 2 L 1.9998779296875 2 M -0.0001220703125 0 L 1185.292358398438 0 L 1185.292358398438 251 L -0.0001220703125 251 L -0.0001220703125 0 Z"
                      stroke="none"
                      fill="#392d2f"
                    />
                  </React.Fragment>
                )}
              </g>
              <g
                id="Rectangle_1"
                data-name="Rectangle 1"
                transform="translate(0 0)"
                fill="#ffd7e0"
                stroke="#392d2f"
                strokeWidth={2}
              >
                <rect width={1196} height={251} stroke="none" />
                <rect x={1} y={1} width={1194} height={249} fill="none" />
              </g>
              <text
                id="Tepagimdo_žemė_visų_rūšių_gyvūnus:_galvijus_roplius_ir_visų_rūšių_laukinius_gyvulius_"
                data-name="Tepagimdo žemė visų rūšių gyvūnus: galvijus, roplius ir visų rūšių laukinius gyvulius!"
                transform="translate(598 117)"
                fill="#392d2f"
                fontSize={37}
                fontFamily="Helvetica"
              >
                <tspan x="-380.46" y={0}>
                  Tepagimdo žemė visų rūšių gyvūnus: galvijus,{" "}
                </tspan>
                <tspan x="-307.427" y={44}>
                  roplius ir visų rūšių laukinius gyvulius!
                </tspan>
              </text>
            </motion.g>
            <text
              id="Algoritmas_tarė:"
              data-name="Algoritmas tarė:"
              transform="translate(247 339.5)"
              fill="#392d2f"
              fontSize={100}
              fontFamily="Voyage-Regular, Voyage"
            >
              <tspan x="410.1" y={102}>
                Algoritmas tarė:
              </tspan>
            </text>
          </g>
        </svg>
      )}
    </React.Fragment>
  )
}
