import React from 'react';

export default function BackgroundColor ({
  color
}) {
  return (
    <style>{`
        body {
          background-color: ${color};
        }
    `}</style>
  )
}
