import React from "react";
import ModelViewer from './modelViewer';

export default ({
  onClose,
  title,
  model,
  background,
  zoom,
  ...props
}) => {
  const [screen, setScreen] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [rotation, setRotation] = React.useState({x: 0, y: 0, z: 0});

  const moveLeft = () => setRotation({...rotation, y: rotation.y + -0.45})
  const moveRight = () =>setRotation({...rotation, y: rotation.y + 0.45})

  React.useEffect(() => {
    const resize = () => setScreen({
      width: window.innerWidth,
      height: window.innerHeight
    });
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div style={{
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      backgroundColor: 'rgba(57, 45, 47, 0.9)'
    }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox="0 0 1920 1080"
    >
      <defs>
        <clipPath id="clip-Web_1920_22">
          <rect width={1920} height={1080} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_22"
        data-name="Web 1920 – 22"
        clipPath="url(#clip-Web_1920_22)"
      >
        <rect
          id="Rectangle_6"
          data-name="Rectangle 6"
          width={248}
          height={357}
          transform="translate(614 26)"
          fill="none"
        />
        <path
          id="Union_1"
          data-name="Union 1"
          d="M-7276.978-7637l-1.022,1,1.022-1-1.022-1,1.022,1,43.978-43-43.978,43,43.978,43Z"
          transform="translate(7449.5 8191.5)"
          fill="none"
          stroke="#fcf2e2"
          strokeWidth={4}
          style={{cursor: 'pointer'}}
          onClick={moveLeft}
        />
        <path
          id="Union_2"
          data-name="Union 2"
          d="M1.022,43,0,42l1.022,1L0,44l1.022-1L45,86,1.022,43,45,0Z"
          transform="translate(1708.5 597.5) rotate(180)"
          fill="none"
          stroke="#fcf2e2"
          strokeWidth={4}
          style={{cursor: 'pointer'}}
          onClick={moveRight}
        />
        <text
          id="Lelija_Lilium_candidum_"
          transform="translate(163 76)"
          fill="#fcf2e2"
          fontSize={30}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {title.native}
          </tspan>
          <tspan fontFamily="Helvetica" fontWeight={400}>
            <tspan x={0} y={36}>
              {title.latin}
            </tspan>
          </tspan>
        </text>
        <g id="Component_30_3" data-name="Component 30 – 3" transform="translate(153 473)" onClick={moveLeft} style={{cursor: 'pointer'}}>
          <path id="Union_1" data-name="Union 1" d="M-7276.978-7637l-1.022,1,1.022-1-1.022-1,1.022,1,43.978-43-43.978,43,43.978,43Z" transform="translate(7296.5 7718.5)" fill="none" stroke="#fcf2e2" stroke-width="4"/>
          <rect id="Rectangle_42" data-name="Rectangle 42" width="99" height="164" fill="none"/>
        </g>
        <foreignObject x="153" y="473" width="99" height="164">
          <div onClick={moveLeft} style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}></div>
        </foreignObject>
        <g id="Component_29_3" data-name="Component 29 – 3" transform="translate(1637 473)" onClick={moveRight} style={{cursor: 'pointer'}}>
          <path id="Union_2" data-name="Union 2" d="M1.022,43,0,42l1.022,1L0,44l1.022-1L45,86,1.022,43,45,0Z" transform="translate(71.5 124.5) rotate(180)" fill="none" stroke="#fcf2e2" stroke-width="4"/>
          <rect id="Rectangle_43" data-name="Rectangle 43" width="99" height="164" fill="none"/>
        </g>
        <foreignObject x="1637" y="473" width="99" height="164">
          <div onClick={moveRight} style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}></div>
        </foreignObject>
        <g id="Component_31_3" data-name="Component 31 – 3" transform="translate(52 51)" onClick={onClose} style={{cursor: 'pointer'}}>
          <g id="Group_111" data-name="Group 111" transform="translate(-203 -246)">
            <g id="Group_48-2" data-name="Group 48" transform="translate(211 253)">
              <line id="Line_1-2" data-name="Line 1" y1="50" x2="45" fill="none" stroke="#fcf2e2" stroke-width="4"/>
              <line id="Line_2-2" data-name="Line 2" x1="45" y1="50" fill="none" stroke="#fcf2e2" stroke-width="4"/>
            </g>
          </g>
          <rect id="Rectangle_44" data-name="Rectangle 44" width="60" height="66" fill="none"/>
        </g>
        <foreignObject x="52" y="51" width="60" height="66">
          <div onClick={onClose} style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}></div>
        </foreignObject>
      </g>
    </svg>
    <img
      alt="foo"
      src={background}
      style={{
        position: 'absolute',
        width: 'auto',
        height: screen.height * 0.85,
        left: screen.width / 2,
        top: (screen.height - (screen.height * 0.85)) / 2,
        transform: 'translate(-50%, 0)',
        opacity: 0.5
      }}
    />
    <ModelViewer
      width={screen.width * 0.65}
      height={screen.height * 0.85}
      model={model}
      rotation={rotation}
      zoom={zoom}
      enableRotate={false}
      style={{
        position: 'absolute',
        left: (screen.width - (screen.width * 0.65)) / 2,
        top: (screen.height - (screen.height * 0.85)) / 2
      }}
    />
    </div>
  )
}
