import React from 'react';
import {GLTFModel, Tick} from 'react-3d-viewer'

const Models = {
  lelija: {
    path: '/lelija.gltf',
    position: {x: 0, y: -0.1, z: -0}
  }
}

export default function ModelViewer ({
  width,
  height,
  style,
  model = Models.lelija,
  rotation = {x: 0, y: 0, z: 0},
  zoom = 1,
  enableRotate = true,
  enableZoom = true
}) {
  const ref = React.useRef();

  React.useEffect(
    () => {
      ref.current.renderer.setClearColor(0xffffff, 0);
      ref.current.renderer.setPixelRatio( window.devicePixelRatio )
    },
    [ref]
  )

  React.useEffect(
    () => {
      ref.current.camera.zoom = zoom
      ref.current.camera.updateProjectionMatrix();
    },
    [ref, zoom]
  )

  return (
    <div style={style}>
      <GLTFModel
        ref={ref}
        src={model.path}
        width={width}
        height={height}
        rendererOptions={{alpha: true }}
        background={'transparent'}
        enableZoom={enableZoom}
        position={model.position}
        rotation={rotation}
        enableRotate={enableRotate}
      />
    </div>
  )
}
