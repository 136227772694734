import React from "react";
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';
import {NextContext} from '../components/Context';

export default ({onNext, ...props}) => {
  const [showFirstWindow, setShowFirstWindow] = React.useState(true);
  const [ellipse, updateEllipse] = React.useState(1);

  const [windowStack, setWindowStack] = React.useState([
    'first',
    'second'
  ]);
  const [showEnd, setShowEnd] = React.useState(false);
  const endRef = React.useRef();
  const nextContext = React.useContext(NextContext);

  const setFocusedWindow = (win) => {
    const newStack = windowStack.filter(name => name !== win);
    newStack.push(win);
    setWindowStack(newStack);

    if (!showEnd && win === 'first') {
      setShowEnd(true)
    }
  }

  React.useEffect(
    () => {
      if (!endRef.current || !showEnd) {
        return;
      }
      return nextContext.onRegister(endRef.current);
    },
    [endRef.current, showEnd] // eslint-disable-line
  )


  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox={`0 0 1920 ${showEnd ? 1080 * 2 : 1080}`}>
        <BackgroundColor color={showEnd ? '#392d2f' : "#fcf2e2"} />
        <defs>
          <clipPath id="clip-Web_1920_13">
            <rect width={1920} height={showEnd ? 1080 * 2 : 1080} />
          </clipPath>
        </defs>
        <g id="Web_1920_13" data-name="Web 1920 – 13" clipPath="url(#clip-Web_1920_13)">
          <rect width={1920} height={1080} fill="#fcf2e2" />
          {[0, 1].map(
            (i) => {
              if (windowStack.indexOf('second') === i) {
                if (!showFirstWindow) {
                  return null;
                }
                return (
                  <React.Fragment>
                  <g id="Group_44-2" data-name="Group 44" transform="translate(-111 -144)">
                    <g id="Group_42" data-name="Group 42" transform="translate(295 378)">
                      <g id="Rectangle_1-3" data-name="Rectangle 1" fill="#fcf2e2" stroke="#392d2f" strokeWidth={2}>
                        <rect width={1600} height={613} stroke="none" />
                        <rect x={1} y={1} width={1598} height={611} fill="none" />
                      </g>
                    </g>
                    <g id="Group_43-2" data-name="Group 43" transform="translate(295 378)">
                      <g id="Rectangle_1-4" data-name="Rectangle 1" fill="#392d2f" stroke="#392d2f" strokeWidth={2}
                      onClick={() => setFocusedWindow('second')}
                      style={{cursor: 'pointer'}}>
                        <rect width={1600} height={45} stroke="none" />
                        <rect x={1} y={1} width={1598} height={43} fill="none" />
                      </g>
                    </g>
                    <g id="Ellipse_3-2" data-name="Ellipse 3" transform="translate(317 392)" fill="#ffd7e0" stroke="#392d2f" strokeWidth={2} style={{cursor: 'pointer'}} onClick={() => setShowFirstWindow(false)}>
                      <circle cx={10} cy={10} r={10} stroke="none" />
                      <circle cx={10} cy={10} r={9} fill="none" />
                    </g>
                    <g id="Ellipse_4-2" data-name="Ellipse 4" transform="translate(353 392)" fill="#d1fff1" stroke="#392d2f" strokeWidth={2}>
                      <circle cx={10} cy={10} r={10} stroke="none" />
                      <circle cx={10} cy={10} r={9} fill="none" />
                    </g>
                    <g id="Ellipse_5-2" data-name="Ellipse 5" transform="translate(389 392)" fill="#fcf2e2" stroke="#392d2f" strokeWidth={2}>
                      <circle cx={10} cy={10} r={10} stroke="none" />
                      <circle cx={10} cy={10} r={9} fill="none" />
                    </g>
                  </g>
                  <text
                    id="Algoritmas_padarė_skliautą_ir_atskyrė_vandenis_buvusius_po_skliautu_nuo_vandenų_buvusių_viršum_skliauto."
                    data-name="Algoritmas padarė skliautą ir atskyrė vandenis, buvusius po skliautu, nuo vandenų, buvusių viršum skliauto."
                    transform="translate(235.5 352.5)"
                    fill="#392d2f"
                    fontSize={100}
                    fontFamily="Voyage-Regular, Voyage"
                  >
                    <tspan x="23.4" y={102}>
                      Algoritmas padarė skliautą ir atskyrė{" "}
                    </tspan>
                    <tspan x="40.3" y={222}>
                      vandenis buvusius po skliautu, nuo{" "}
                    </tspan>
                    <tspan x="74.75" y={342}>
                      vandenų, buvusių viršum skliauto.
                    </tspan>
                  </text>
                  </React.Fragment>
                )
              }

              return (
                <React.Fragment>
                  <g id="Group_45" data-name="Group 45" transform="translate(-17)">
                    <g id="Group_44" data-name="Group 44" transform="translate(-59 -202)">
                      <g id="Group_43" data-name="Group 43" transform="translate(295 378)">
                        <g id="Rectangle_1" data-name="Rectangle 1" fill="#d1fff1" stroke="#392d2f" strokeWidth={2}>
                          <rect width={1600} height={613} stroke="none" />
                          <rect x={1} y={1} width={1598} height={611} fill="none" />
                        </g>
                        <g id="Rectangle_1-2" data-name="Rectangle 1" fill="#392d2f" stroke="#392d2f" strokeWidth={2}
                          onClick={() => setFocusedWindow('first')}
                          style={{cursor: 'pointer'}}>
                          <rect width={1600} height={45} stroke="none" />
                          <rect x={1} y={1} width={1598} height={43} fill="none" />
                        </g>
                      </g>
                      <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(317 392)" fill="#ffd7e0" stroke="#392d2f" strokeWidth={2} style={{cursor: 'pointer'}}>
                        <circle cx={10} cy={10} r={10} stroke="none" />
                        <circle cx={10} cy={10} r={9} fill="none" />
                      </g>
                      <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(353 392)" fill="#d1fff1" stroke="#392d2f" strokeWidth={2}>
                        <circle cx={10} cy={10} r={10} stroke="none" />
                        <circle cx={10} cy={10} r={9} fill="none" />
                      </g>
                      <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(389 392)" fill="#fcf2e2" stroke="#392d2f" strokeWidth={2}>
                        <circle cx={10} cy={10} r={10} stroke="none" />
                        <circle cx={10} cy={10} r={9} fill="none" />
                      </g>
                    </g>
                  </g>
                  <text
                    id="Algoritmas_pavadino_skliautą_Dangumi."
                    data-name="Algoritmas pavadino skliautą Dangumi."
                    transform="translate(294.5 372.5)"
                    fill="#392d2f"
                    fontSize={100}
                    fontFamily="Voyage-Regular, Voyage"
                  >
                    <tspan x="168.05" y={102}>
                      Algoritmas pavadino skliautą{" "}
                    </tspan>
                    <tspan x="539.75" y={222}>
                      Dangumi.
                    </tspan>
                  </text>
                  <text
                    id="Dangus"
                    transform="translate(996 209)"
                    fill="#fcf2e2"
                    fontSize={30}
                    fontFamily="Helvetica"
                    onClick={() => setFocusedWindow('first')}
                    style={{cursor: 'pointer'}}
                  >
                    <tspan x="-51.702" y={0}>
                      Dangus
                    </tspan>
                  </text>
                </React.Fragment>
              )
            }
          )}
          {showEnd && (
            <React.Fragment>
              <rect
                id="Rectangle_45"
                data-name="Rectangle 45"
                width={1920}
                height={1080}
                transform="translate(0 1080)"
                fill="#392d2f"
              />
              <text
                id="Atėjo_vakaras_ir_išaušo_rytas._Antroji_diena"
                data-name="Atėjo vakaras ir išaušo rytas. Antroji diena"
                transform="translate(247.5 1478.5)"
                fill="#fcf2e2"
                fontSize={100}
                fontFamily="Voyage-Regular, Voyage"
                ref={endRef}
              >
                <tspan x="176.45" y={102}>
                  Atėjo vakaras ir išaušo rytas.
                </tspan>
                <tspan x="465.65" y={222}>
                  Antroji diena
                </tspan>
              </text>
              <g id="day_count" data-name="day count" transform="translate(42 -2907)">
                <circle
                  id="Ellipse_18"
                  data-name="Ellipse 18"
                  cx="17.5"
                  cy="17.5"
                  r="17.5"
                  transform="translate(544 4876)"
                  fill="#fcf2e2"
                />
                <motion.g
                  id="Ellipse_19"
                  data-name="Ellipse 19"
                  transform="translate(769 4863)"
                  fill="#392d2f"
                  stroke="#fcf2e2"
                  strokeWidth={2}
                  onClick={onNext}
                  onHoverStart={() => updateEllipse(2)}
                  onHoverEnd={() => updateEllipse(1)}
                  style={{cursor: 'pointer'}}
                >
                  <circle cx="30.5" cy="30.5" r="30.5" stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
                  <circle cx="30.5" cy="30.5" r="29.5" fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
                </motion.g>
                <g
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  transform="translate(901 4876)"
                  fill="none"
                  stroke="#fcf2e2"
                  strokeWidth={2}
                >
                  <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                  <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                </g>
                <g
                  id="Ellipse_22"
                  data-name="Ellipse 22"
                  transform="translate(1019 4876)"
                  fill="none"
                  stroke="#fcf2e2"
                  strokeWidth={2}
                >
                  <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                  <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                </g>
                <g
                  id="Ellipse_23"
                  data-name="Ellipse 23"
                  transform="translate(1138 4876)"
                  fill="none"
                  stroke="#fcf2e2"
                  strokeWidth={2}
                >
                  <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                  <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                </g>
                <g
                  id="Ellipse_24"
                  data-name="Ellipse 24"
                  transform="translate(1257 4876)"
                  fill="none"
                  stroke="#fcf2e2"
                  strokeWidth={2}
                >
                  <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
                  <circle cx="17.5" cy="17.5" r="16.5" fill="none" />
                </g>
                <circle
                  id="Ellipse_25"
                  data-name="Ellipse 25"
                  cx="17.5"
                  cy="17.5"
                  r="17.5"
                  transform="translate(663 4876)"
                  fill="#fcf2e2"
                />
              </g>
            </React.Fragment>
          )}
        </g>
      </svg>
  )
}
