export default function isElementInViewport (el) {
    var box = el.getBoundingClientRect();
    var rect = {
      top: box.top,
      left: box.left,
      bottom: box.bottom,
      right: box.right
    };

    if (rect.bottom < 0) {
      return true
    }

    return rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
}
