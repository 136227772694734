import React from "react";
import {motion} from "framer-motion"
import BackgroundColor from '../components/backgroundColor';

export default ({onNext, ...props}) => {
  const [ellipse, updateEllipse] = React.useState(1);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox="0 0 1920 1083"
    >
      <BackgroundColor color="#392d2f" />
      <defs>
        <clipPath id="clip-Web_1920_52">
          <rect width={1920} height={1083} />
        </clipPath>
      </defs>
      <g
        id="Web_1920_52"
        data-name="Web 1920 – 52"
        clipPath="url(#clip-Web_1920_52)"
      >
        <rect width={1920} height={1083} fill="#392d2f" />
        <text
          id="Atėjo_vakaras_ir_išaušo_rytas._Šeštoji_diena"
          data-name="Atėjo vakaras ir išaušo rytas. Šeštoji diena"
          transform="translate(247.5 400.5)"
          fill="#fcf2e2"
          fontSize={100}
          fontFamily="Voyage-Regular, Voyage"
        >
          <tspan x="176.45" y={102}>
            Atėjo vakaras ir išaušo rytas.
          </tspan>
          <tspan x="468.1" y={222}>
            Šeštoji diena
          </tspan>
        </text>
        <g
          id="Rectangle_20"
          data-name="Rectangle 20"
          transform="translate(1885 255)"
          fill="#392d2f"
          stroke="#392d2f"
          strokeWidth={2}
        >
          <rect width={16} height={175} stroke="none" />
          <rect x={1} y={1} width={14} height={173} fill="none" />
        </g>
        <g id="day_count" data-name="day count" transform="translate(34 -3985)">
          <circle
            id="Ellipse_18"
            data-name="Ellipse 18"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(544 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_19"
            data-name="Ellipse 19"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(663 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_20"
            data-name="Ellipse 20"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(782 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_22"
            data-name="Ellipse 22"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(1019 4876)"
            fill="#fcf2e2"
          />
          <circle
            id="Ellipse_23"
            data-name="Ellipse 23"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(1138 4876)"
            fill="#fcf2e2"
          />
          <motion.g
            key="circle"
            onHoverStart={() => updateEllipse(2)}
            onHoverEnd={() => updateEllipse(1)}
            style={{cursor: 'pointer'}}
            onClick={onNext} id="Ellipse_19-2" data-name="Ellipse 19" transform="translate(1244 4863)" fill="none" stroke="#fcf2e2" strokeWidth={2}>
            <circle key="circle2" cx="30.5" cy="30.5" r={2 *17.5} stroke={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
            <circle key="circle3" cx="30.5" cy="30.5" r={2 *16.5} fill={ellipse === 2 ? '#fcf2e2' : "#392d2f"}/>
          </motion.g>
          <circle
            id="Ellipse_26"
            data-name="Ellipse 26"
            cx="17.5"
            cy="17.5"
            r="17.5"
            transform="translate(901 4876)"
            fill="#fcf2e2"
          />
        </g>
      </g>
    </svg>
  )
}
